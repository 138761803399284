import { useState } from 'react';
import Loader from 'components/loaderComponent';
import { useMyAppointmentsLocale } from '../pages/MyAppointments';
import ButtonComponent from 'components/button/buttonComponent';
import {
  AppointmentTypeEnum,
  useGetHistoricAppointmentCountQuery,
} from 'graphql/generated/remote-schema-hasura';
import { useModalParams } from 'components/modal/useModalManager';
import { MyAppointmentsModal } from './MyAppointmentsModal';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';
import { MY_APPOINTMENTS_SCHEDULE_AN_APPOINTMENT } from 'utilities/routes';
import { AcuityModal } from 'app/my-appointments/components/AcuityModal';
import { useSearchParams } from 'react-router-dom';
import MyVisitSummaryModalPatient from 'app/appointments/components/MyVisitSummaryModalPatient';
import { PARAM_MODALS_IDENTIFIERS } from 'utilities/constants';
import { UpcomingAppointmentsProvider } from 'app/my-appointments/components/UpcomingAppointments';
import { AppointmentHistoryProvider } from 'app/my-appointments/components/AppointmentHistory';
import { CancelAppointmentModal } from 'components/cancelAppointmentModal/CancelAppointmentModal';
import { EditAppointmentModal } from 'app/my-appointments/components/EditAppointmentModal';
import { TermsOfUseModal } from 'app/my-appointments/components/termsOfUseModal/TermsOfUseModal';
import AppointmentDetailModalPatient from 'components/appointmentDetailModal/AppointmentDetailModalPatient';
import UpcomingAppointmentsWrapper from './upcoming-appointments/UpcomingAppointmentsWrapper';
import PastAppointmentWrapper from './past-appointments/PastAppointmentWrapper';

export const PatientAppointmentsMainPage = () => {
  const { isOpen: isAppointmentVideoModal } = useModalParams(
    PARAM_MODALS_IDENTIFIERS.VIDEO_APPOINTMENT_MODAL,
  );
  const { isOpen: isAppointmentChatModal } = useModalParams(
    PARAM_MODALS_IDENTIFIERS.CHAT_APPOINTMENT_MODAL,
  );
  const [searchParams] = useSearchParams();
  const appointmentId = searchParams.get('appointment-id');
  const { isOpen: isAcuityModal } = useModalParams('acuity-modal');
  const { loading, locale } = useMyAppointmentsLocale();

  const navigate = useNavigate();
  const { isOpen: isMyVisitSummaryModalOpen } = useModalParams(
    PARAM_MODALS_IDENTIFIERS.MY_VISIT_SUMMARY_MODAL_PATIENT_MODAL_ID,
  );
  const { isOpen: isCancelAppointmentOpen } = useModalParams(
    PARAM_MODALS_IDENTIFIERS.CANCEL_APPOINTMENT_MODAL,
  );
  const { isOpen: isViewAppointmentDetailsModal } = useModalParams(
    PARAM_MODALS_IDENTIFIERS.VIEW_APPOINTMENT_DETAILS_MODAL_PATIENT,
  );
  const { isOpen: isTermsOfUseModal } = useModalParams('accept_terms_of_use');
  const { isOpen: isEditAppointmentModal } = useModalParams('edit_appointment');

  const location = useLocation();

  const [hasHistoryAppointments, setHasAppointments] = useState<boolean>(true);

  const handleScheduleAnAppointment = () => {
    navigate(MY_APPOINTMENTS_SCHEDULE_AN_APPOINTMENT);
  };

  const handleMyVisitSummaryClick = (appointmentId: string) => {
    navigate(
      {
        search: createSearchParams({
          [PARAM_MODALS_IDENTIFIERS.MY_VISIT_SUMMARY_MODAL_PATIENT_MODAL_ID]:
            'true',
        }).toString(),
      },
      { state: { appointmentId } },
    );
  };

  const { loading: patientCountLoading } = useGetHistoricAppointmentCountQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setHasAppointments(
        !!(data.getFHIRAppointmentByRequestPatientCodexId.total ?? 0),
      );
    },
  });

  if (location.state?.appointmentComplete) {
    return (
      <MyAppointmentsModal
        appointmentType={AppointmentTypeEnum.Chat}
        appointmentId={appointmentId}
        isPatient
        appointmentComplete
      />
    );
  }

  if (isAppointmentChatModal) {
    return (
      <MyAppointmentsModal
        appointmentType={AppointmentTypeEnum.Chat}
        appointmentId={appointmentId}
        isPatient
      />
    );
  }

  if (isAppointmentVideoModal) {
    return (
      <MyAppointmentsModal
        appointmentType={AppointmentTypeEnum.Video}
        appointmentId={appointmentId}
        isPatient
      />
    );
  }

  if (isAcuityModal) {
    return <AcuityModal />;
  }

  if (!locale || loading || patientCountLoading) {
    return <Loader />;
  }

  return (
    <>
      {isMyVisitSummaryModalOpen && <MyVisitSummaryModalPatient />}
      {isViewAppointmentDetailsModal && <AppointmentDetailModalPatient />}
      {isCancelAppointmentOpen && <CancelAppointmentModal />}
      {isEditAppointmentModal && <EditAppointmentModal />}
      {isTermsOfUseModal && <TermsOfUseModal />}
      <div
        className="px-7 pt-[30px] desktop:pt-0"
        data-testid="my-appointsmets-page"
      >
        <div className="flex flex-col gap-[30px]">
          <div className="flex desktop:flex-row w-full justify-between items-start p-0 gap-2.5 desktop:gap-[30px] desktop:mb-[34px]">
            {hasHistoryAppointments && (
              <div className="flex mobile:flex-col desktop:flex-row justify-between items-start w-full">
                <div className="desktop:w-3/5">
                  <p className="text-h2 text-dark-gray font-medium desktop:text-h1">
                    {locale.myAppointmentsTabName}
                  </p>
                </div>
                <div className="flex flex-1 mobile:w-full mobile:mt-4 justify-end desktop:w-2/5">
                  <ButtonComponent
                    className="max-h-[45px]"
                    onClick={handleScheduleAnAppointment}
                    testID="schedule-appointment-button"
                  >
                    {locale.scheduleAnAppointment}
                  </ButtonComponent>
                </div>
              </div>
            )}
          </div>
          <>
            <UpcomingAppointmentsProvider>
              <UpcomingAppointmentsWrapper />
            </UpcomingAppointmentsProvider>
            <AppointmentHistoryProvider
              handleMyVisitSummaryClick={handleMyVisitSummaryClick}
            >
              <PastAppointmentWrapper />
            </AppointmentHistoryProvider>
          </>
        </div>
      </div>
    </>
  );
};
