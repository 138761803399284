export function luhn(no: string): boolean {
  let c = 0;
  let sum = 0;
  let second = false;
  const length = no.length;

  for (let i = length - 1; i >= 0; i--) {
    c = parseInt(no[i], 10);

    if (second) {
      c *= 2;
    }

    sum += Math.floor(c / 10);
    sum += c % 10;
    second = !second;
  }

  return sum % 10 === 0;
}
