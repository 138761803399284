import React, { useRef } from 'react';
import { twMerge } from 'tailwind-merge';

import { ReactComponent as Description } from 'assets/icons/body-description.svg';
import { Dot, ImageObject } from '../interfaces';
import CustomTooltip from 'components/CustomTooltip';

interface ICheckboxList {
  dots: Dot[];
  medias: Map<string, string | ImageObject>;
  onCheckboxChange: (id: number) => void;
  onBodyPartClick?: (id: number) => void;
}

interface CheckboxInput {
  displayCheckboxChildrenInput?: boolean;
  checked?: boolean | undefined;
  checkboxLabel?: string;
  name?: string;
  checkboxInputLabel?: string;
  checkboxInputTestId?: string;
  bottomMargin?: boolean;
  enableVersatileClick?: boolean;
  isAlternativeCheckboxColor?: boolean;
  checkboxLabelClassName?: string;
  onCheckboxChange: (checked: boolean) => void;
  onMouseOver?: () => void;
  onMouseLeave?: () => void;
  onCheckboxChildrenInputChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => void;
}

const Checkbox: React.FC<CheckboxInput> = ({
  checked,
  checkboxLabel,
  checkboxLabelClassName,
  name,
  onCheckboxChange,
  onMouseOver,
  onMouseLeave,
}) => {
  const checkboxRef = useRef<HTMLInputElement>(null);
  const baseClasses =
    'flex shrink-0 h-7 w-7 text-clc-blue rounded-5 border bg-checkbox-add-image input-border__gray appearance-none';
  const checkClass = checked
    ? 'bg-center bg-no-repeat !bg-checkbox-alternative'
    : '';

  const handleCheckboxChange = () => {
    onCheckboxChange(true);
  };

  return (
    <>
      <div
        className="flex group cursor-pointer"
        onMouseOver={onMouseOver}
        onMouseLeave={onMouseLeave}
      >
        <input
          ref={checkboxRef}
          id={name}
          name={name}
          type="checkbox"
          className={twMerge(
            baseClasses,
            checkClass,
            'cursor-pointer bg-center border-gray-opacity-15 bg-no-repeat group-hover:border-transparent group-hover:bg-checkbox-hover',
          )}
          onChange={handleCheckboxChange}
          data-testid={checkboxLabel}
        />

        {checkboxLabel && (
          <label
            htmlFor={name}
            className={twMerge(
              'flex font-semibold items-center group-hover:text-clc-blue group-hover:underline group-hover:font-bold ml-2.5 text-med-gray cursor-pointer',
              checkboxLabelClassName,
              checked && 'text-charcoal-gray',
            )}
          >
            <span className="group-hover:hidden">{checkboxLabel}</span>
            <span className="hidden uppercase group-hover:block">
              Add photo
            </span>
          </label>
        )}
      </div>
    </>
  );
};

const DescriptionTooltip = ({
  description,
  id,
}: {
  description: string;
  id: string;
}) => {
  return (
    <>
      <span data-tooltip-id={id}>
        <Description className="ml-2" />
      </span>
      <CustomTooltip
        noArrow
        place="left-start"
        content={description}
        id={id}
        className="p-2 !text-med-gray !bg-white !font-semibold border max-w-[440px] !opacity-100 z-10"
      />
    </>
  );
};

const CheckboxList: React.FC<ICheckboxList> = ({
  dots,
  medias,
  onCheckboxChange,
  onBodyPartClick = () => ({}),
}) => {
  const onMouseOver = (key: string) => {
    const dot = document.getElementById(key);
    if (!dot) return;
    dot.classList.add('bg-blue-body-dot-opacity-35');
  };

  const onMouseLeave = (key: string) => {
    const dot = document.getElementById(key);
    if (!dot) return;
    dot.classList.remove('bg-blue-body-dot-opacity-35');
  };

  return (
    <>
      {dots.map((dot) => {
        const { description } =
          (medias.get(String(dot.location)) as ImageObject) || {};

        return (
          <div className="flex items-center" key={dot.id}>
            {dot.selected ? (
              <div
                className="flex items-center cursor-pointer group"
                onClick={() => onBodyPartClick(dot.id)}
                onMouseOver={() => onMouseOver(dot.strapiKey)}
                onMouseLeave={() => onMouseLeave(dot.strapiKey)}
              >
                <img
                  src={(medias.get(String(dot.id)) as ImageObject)?.image}
                  alt={`photo for ${dot.location}`}
                  className="w-7 h-7 mr-2 rounded object-cover"
                />
                <span className="text-neutral-800 font-semibold text-sm self-center group-hover:text-clc-blue group-hover:underline group-hover:font-bold">
                  {dot.location}
                </span>
              </div>
            ) : (
              <Checkbox
                name={dot.id.toString()}
                checked={dot.selected}
                checkboxLabelClassName="ml-2 text-med-gray text-sm self-center"
                checkboxLabel={dot.location}
                onCheckboxChange={() => onCheckboxChange(dot.id)}
                onMouseOver={() => onMouseOver(dot.strapiKey)}
                onMouseLeave={() => onMouseLeave(dot.strapiKey)}
              />
            )}

            {description && (
              <DescriptionTooltip
                description={description}
                id={String(dot.id)}
              />
            )}
          </div>
        );
      })}
    </>
  );
};

export default CheckboxList;
