import {
  useGetAppointmentByCodexAppointmentIdLazyQuery,
  useGetFhirProviderByCodexIdLazyQuery,
} from 'graphql/generated/remote-schema-hasura';
import { useCallback } from 'react';
import { GetAppointmentOutput } from 'graphql/generated/hasura';

export interface AppointmentDetails extends GetAppointmentOutput {
  calendarId?: string | null;
  appointmentTypeId?: string | null;
}

export const useGetAppointments = (appointmentId: string | null) => {
  const [getProvider] = useGetFhirProviderByCodexIdLazyQuery();

  const [getAppointment] = useGetAppointmentByCodexAppointmentIdLazyQuery();

  return useCallback(async (): Promise<AppointmentDetails> => {
    const appointmentData = await getAppointment({
      variables: { appointmentCodexId: String(appointmentId) },
    });
    const appointment = appointmentData.data?.getFHIRAppointmentByCodexId
      ?.appointment as GetAppointmentOutput;

    const providerData = await getProvider({
      variables: { codexProviderId: String(appointment.codexProviderId) },
    });
    const provider =
      providerData.data?.getFHIRProviderbyCodexIdArgument.providers[0]
        .providers;

    return {
      ...appointment,
      calendarId: provider?.acuityCalendarId,
      providerFirstName: provider?.SENSITIVE_firstname,
      providerLastName: provider?.SENSITIVE_lastname,
    };
  }, [appointmentId, getAppointment, getProvider]);
};
