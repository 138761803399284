import { useGetPage } from 'hooks/useGetPage';
import { componentIds, genericActionsIds, pageIds } from 'utilities/constants';

import ButtonComponent from 'components/button/buttonComponent';
import { ReactComponent as ArrowLoadMoreIcon } from 'assets/icons/arrowLoadMore.svg';
import { ReactComponent as ArrowUp } from 'assets/icons/arrowUp.svg';
import React, { useContext, useRef, useState } from 'react';
import { useGetGenericActions } from 'hooks/useGetGenericActions';
import Loader from 'components/loaderComponent';
import { ProviderSideBarFilter } from '../../../../providers-view-selection/components/ProviderSideBarFilter';
import { FindAProviderSelectionFilterType } from '../../../../providers-view-selection/components/ProviderSideBarFilterInterfaces';
import { AuthContext } from '../../../../../auth/context/AuthContext';
import useProfileDataLoader from '../../../../../hooks/usePatientDataLoader';
import { useGetComponent } from '../../../../../hooks/useGetComponent';
import SelectStateProvider from '../../modalContent/SelectStateProvider';
import SortDropdown from '../../select-a-provider/SortDropdown';
import ProviderStack from '../../select-a-provider/ProviderStack';
import { SelectAProviderTabProp } from '../../../pages/SelectAProvider';
import { twMerge } from 'tailwind-merge';
import NoProviders from './components/NoProviders';
import SearchProvider from './components/SearchProvider';
import { useGetProviders } from './hooks/useGetProviders';

export type FindAProviderFilterType = Record<
  string,
  string | number | string[] | undefined
>;

const findAProviderBatchSize = 10;
const initialFilter = {
  limit: findAProviderBatchSize,
  offset: 0,
};

const SelectProvider: React.FC<SelectAProviderTabProp> = ({
  onProviderSelect,
}) => {
  const { user } = useContext(AuthContext);
  const { profileData } = useProfileDataLoader(user);

  const { data: localeComponent } = useGetComponent({
    componentId: componentIds.APPOINTMENT_PREWORK,
    locale: 'en',
  });

  const { data: locale, loading: localeLoading } = useGetPage({
    locale: 'en',
    pageId: pageIds.PROVIDERS_SELECTION,
  });
  const { data: genericActions, loading: actionsLoading } =
    useGetGenericActions({
      locale: 'en',
      genericActionId: [genericActionsIds.SEE_MORE, genericActionsIds.VIEW_ALL],
    });
  const { loading: componentLocaleLoading } = useGetComponent({
    locale: 'en',
    componentId: componentIds.AVAILABLE_SPECIALTIES_AND_LANGUAGES,
  });
  const isFirstLoad = useRef(true);
  const [filter, setFilter] = useState<FindAProviderFilterType>(initialFilter);
  const [state, setState] = useState<string | undefined>(
    () => profileData?.addressInformation.state,
  );

  const {
    providers,
    total,
    loading: providersLoading,
  } = useGetProviders(filter);

  const onSeeMoreClick = () => {
    setFilter((prev) => ({
      ...prev,
      limit: ((prev.limit as number) || 0) + findAProviderBatchSize,
    }));
  };

  const handleToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };

  if (
    localeLoading ||
    actionsLoading ||
    componentLocaleLoading ||
    (providersLoading && isFirstLoad.current)
  )
    return <Loader />;
  const canSeeMore = total > providers.length;
  const noProviderFound = providers.length === 0 && !providersLoading;
  isFirstLoad.current = false;

  return (
    <div
      className={twMerge(
        'flex flex-col w-full px-5 pt-5 pb-[30px] desktop:p-[30px] gap-8 bg-white rounded-10 relative',
        providersLoading ? 'pointer-events-none' : '',
      )}
    >
      {providersLoading && (
        <div className="absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center">
          <Loader />
        </div>
      )}
      <div className="flex flex-row w-full desktop:w-auto justify-between items-center">
        <h4 className="text-left text-h4 text-charcoal-gray font-medium">
          {localeComponent?.selectAProviderPage}
        </h4>
      </div>
      <div
        className={twMerge(
          'flex flex-col desktop:flex-row w-full gap-5 items-center justify-between',
          providersLoading ? 'opacity-50' : '',
        )}
      >
        <SearchProvider setFilter={setFilter} initialFilter={initialFilter} />
      </div>
      <hr
        className={twMerge(
          'flex flex-row w-full items-center h-px bg-black-blur',
          providersLoading ? 'opacity-50' : '',
        )}
      />
      <div
        className={twMerge(
          'flex flex-col desktop:flex-row w-full gap-[30px]',
          providersLoading ? 'opacity-50' : '',
        )}
      >
        <div className="flex w-full desktop:w-1/4">
          <ProviderSideBarFilter
            selectedFilters={filter as FindAProviderSelectionFilterType}
            setSelectedFilters={
              setFilter as React.Dispatch<
                React.SetStateAction<FindAProviderSelectionFilterType>
              >
            }
          />
        </div>
        <div className="flex flex-col w-full desktop:w-3/4 gap-4">
          <div className="flex items-center justify-between">
            <div>
              <span className="text-neutral-800 text-h4 font-semibold mr-2">
                {locale?.findAProvider?.filters?.location}
              </span>
              <SelectStateProvider
                patientLocation={profileData?.addressInformation.state}
                defaultState={state || profileData?.addressInformation.state}
                setDefaultState={setState}
              />
            </div>
            <div className="flex items-center gap-4 justify-end">
              <p className="text-dark-gray font-semibold text-h6">
                {locale?.findAProvider?.filters?.sort}
              </p>
              <SortDropdown handleChange={() => ({})} />
            </div>
          </div>
          {noProviderFound ? (
            <NoProviders />
          ) : (
            <div className="flex flex-col w-full border-t border-gray-opacity-15">
              {providers.map((provider) => (
                <ProviderStack
                  provider={provider}
                  key={provider.id}
                  isFirstItem
                  onSelect={onProviderSelect}
                />
              ))}
            </div>
          )}
          <div className="flex items-center justify-center gap-10 mx-auto mt-auto">
            <ButtonComponent
              type="outlined-transparent"
              className={twMerge(
                'mx-auto text-sm stroke-white font-bold !border',
                noProviderFound && 'hidden',
              )}
              onClick={handleToTop}
              Icon={ArrowUp}
              iconPosition="right"
              testID="to-top"
              hoverFill="hover:stroke-white"
            >
              {locale?.findAProvider?.backToTop}
            </ButtonComponent>
            {canSeeMore && !noProviderFound && (
              <div className="flex mx-auto items-center">
                <ButtonComponent
                  className="text-sm font-bold"
                  type="underline"
                  iconPosition="right"
                  Icon={ArrowLoadMoreIcon}
                  onClick={onSeeMoreClick}
                  testID="see-more"
                >
                  {genericActions?.[genericActionsIds.SEE_MORE].seeMore}
                </ButtonComponent>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectProvider;
