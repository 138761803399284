import React from 'react';
import CustomTooltip from '../../../../components/CustomTooltip';
import { twMerge } from 'tailwind-merge';

interface IRow {
  text?: string;
  tooltipId?: string;
  icon: React.ReactNode;
}

const IconRow: React.FC<IRow> = ({ icon, text, tooltipId }) => {
  return (
    <div className="flex items-center gap-4">
      {icon}
      <span
        data-tooltip-id={tooltipId}
        className={twMerge(
          'text-base font-medium text-neutral-800 truncate',
          tooltipId && 'cursor-pointer',
        )}
      >
        {text}
      </span>
      <CustomTooltip
        id={tooltipId}
        place="bottom"
        content={text}
        className="max-w-xs break-all"
      />
    </div>
  );
};

export default IconRow;
