import React, { useMemo, useState } from 'react';
import { BlockCollapseProps, RateProps } from '../../interface';
import { useGetPage } from 'hooks/useGetPage';
import { componentIds, pageIds } from 'utilities/constants';
import Collapse from '../../components/Collapse';
import InputComponent from 'components/inputComponent';
import { useGetComponent } from 'hooks/useGetComponent';

interface IRateBlockProps extends BlockCollapseProps {
  initialState?: RateProps;
}

const RateBlock: React.FC<IRateBlockProps> = ({
  id,
  handleContinue,
  handleEdit,
  currentCollapsesOpen,
  initialState,
}) => {
  const [severity, setSeverity] = useState<string | undefined>(
    initialState?.severity,
  );
  const [anythingElseQuestion, setAnythingElseQuestion] = useState<
    string | undefined
  >(initialState?.anythingElseQuestion);

  const { data: locale } = useGetPage({
    pageId: pageIds.APPOINTMENT_PREWORK,
    locale: 'en',
  });

  const { data: localeComponents } = useGetComponent({
    componentId: componentIds.APPOINTMENT_PREWORK,
    locale: 'en',
  });

  const severityItems = useMemo<string[]>(
    () =>
      (Object.values(locale?.severity || {}) as string[]).sort((a, b) =>
        a.localeCompare(b),
      ) as string[],
    [locale],
  );

  const onRadioClick = (selected: string) => () => {
    setSeverity(selected);
  };

  return (
    <Collapse
      savedData={severity}
      title={locale?.severityQuestion}
      currentCollapsesOpen={currentCollapsesOpen}
      id={id}
      handleContinue={(id) =>
        handleContinue(id, { severity, anythingElseQuestion })
      }
      handleEdit={handleEdit}
      disabled={!severity}
    >
      <div className="flex flex-col">
        {severityItems.map((item) => (
          <InputComponent
            key={item}
            type="radio"
            testID={`severity-${item}`}
            radioInputProps={{
              radioLabelTextWeight: 'font-semibold text-med-gray-3 text-sm',
              radioError: null,
              radioInputValue: item,
              radioInputLabel: item,
              radioInputCheckedValue: severity,
              onRadioClick: onRadioClick(item),
            }}
          />
        ))}
      </div>

      <p className="font-semibold text-base text-neutral-800 mt-2">
        {localeComponents?.symptoms?.additionInformationTitle}
      </p>
      <div className="w-full mt-4 desktop:mt-0">
        <InputComponent
          value={anythingElseQuestion}
          type="text-area"
          testID="anything-else-input"
          maxLengthValue={500}
          placeholder={
            localeComponents?.symptoms?.additionInformationPlaceholder
          }
          textAreaProps={{
            height: 'h-32',
            textAreaTitle: locale?.descriptionLabel,
            onTextAreaChange: (e) => setAnythingElseQuestion(e.target.value),
            containerHeight: 'h-full',
            name: 'appointmentNotes',
          }}
        />
      </div>
    </Collapse>
  );
};

export default RateBlock;
