import {
  AppointmentTypeEnum,
  InputMaybe,
  Order_By,
} from 'graphql/generated/hasura';
import { ReactComponent as Doc } from 'assets/icons/file.svg';
import {
  CustomClassNamesProps,
  KeyMapProps,
} from 'components/codexTable/interfaces/keymap.interfaces';
import { Record_Order_By } from 'components/codexTable/interfaces/record.interfaces';
import { appointmentRenderInfo } from '../../interfaces';
import { getStatusClassName } from '../upcoming-appointments/helper/getStatusClassName';
import { FHIR_APPOINTMENT_STATUS } from 'utilities/interfaces';
import { twMerge } from 'tailwind-merge';
import React from 'react';
import ButtonComponent from 'components/button/buttonComponent';

export const columns = [
  'date',
  'name',
  'type',
  'logs',
  'status',
  'actions',
] as const;
type Historic_Appointments_Order_By = Partial<
  Record<(typeof columns)[number], InputMaybe<Order_By>>
>;
type Historic_Appointments_Entry = Partial<
  Record<'id' | (typeof columns)[number], unknown>
>;

type ActionType = {
  onClick: (id: string) => void;
};

export const keyMap: KeyMapProps = {
  date: {
    column: 'date',
    isActive: (orderBy: Record_Order_By) => !!orderBy.date,
    direction: (orderBy: Historic_Appointments_Order_By) =>
      orderBy.date || Order_By.Desc,
  },
  name: {
    column: 'name',
    isActive: (orderBy: Record_Order_By) => !!orderBy.name,
    direction: (orderBy: Historic_Appointments_Order_By) =>
      orderBy.name || Order_By.Desc,
    FormatValue: ({ value }) => {
      return <p>{value}</p>;
    },
  },
  type: {
    column: 'type',
    isActive: (orderBy: Record_Order_By) => !!orderBy.type,
    direction: (orderBy: Historic_Appointments_Order_By) =>
      orderBy.type || Order_By.Desc,
    FormatValue: ({ value, locale }) => {
      const local = locale as Record<string, string>;
      const Icon = appointmentRenderInfo[value as AppointmentTypeEnum].icon;
      const text = appointmentRenderInfo[value as AppointmentTypeEnum].text;
      return (
        <div className="flex items-center">
          <Icon className="inline" />
          <span className="ml-2 capitalize">{String(local[text])}</span>
        </div>
      );
    },
  },
  logs: {
    column: 'logs',
    isActive: (orderBy: Record_Order_By) => !!orderBy.logs,
    direction: (orderBy: Historic_Appointments_Order_By) =>
      orderBy.logs || Order_By.Desc,
    FormatValue: ({ value }) => {
      if (!Array.isArray(value)) return <>{value}</>;
      return <p>{value.join(', ')}</p>;
    },
  },
  status: {
    column: 'status',
    isActive: (orderBy: Record_Order_By) => !!orderBy.status,
    direction: (orderBy: Historic_Appointments_Order_By) =>
      orderBy.status || Order_By.Desc,
    FormatValue: ({ value }) => {
      const statusClassName = getStatusClassName(
        value as FHIR_APPOINTMENT_STATUS,
      );
      return (
        <span className={twMerge('capitalize inline-block', statusClassName)}>
          {value}
        </span>
      );
    },
  },
  actions: {
    column: 'actions',
    isActive: () => false,
    direction: () => Order_By.Desc,
    FormatValue: ({ value, entry }) => {
      if (!Array.isArray(value)) return null;
      const { status, id } = entry as Historic_Appointments_Entry;
      const { onClick } = value[0] as unknown as ActionType;
      if (status === FHIR_APPOINTMENT_STATUS.PROPOSED) {
        return (
          <ButtonComponent
            type="underline"
            className="!text-xs !font-bold"
            containerClassName="!gap-1"
            onClick={() => onClick(id as string)}
            Icon={Doc}
            iconPosition="left"
            iconWidth="w-[1.25rem] !stroke-transparent"
          >
            Summary
          </ButtonComponent>
        );
      }
      return null;
    },
  },
};

export const customClassesDesktop: CustomClassNamesProps = {
  date: {
    customRowClassName:
      'pl-2 text-left min-w-[200px] align-middle font-medium text-med-gray-3',
    spanClass: 'pl-2',
  },
  name: {
    customRowClassName:
      'text-left min-w-[200px] align-middle font-medium text-med-gray-3',
    spanClass: 'pl-0',
  },
  type: {
    customRowClassName:
      'text-left align-middle min-w-[200px] font-medium text-med-gray-3',
    spanClass: 'pl-0',
    flexContainerClass: 'justify-start',
  },
  logs: {
    customRowClassName: 'text-left align-middle font-medium text-med-gray-3',
    spanClass: 'pl-0',
  },
  actions: {
    customRowClassName: 'px-0 align-middle min-w-[100px]',
    spanClass: 'pl-0',
  },
  status: {
    customRowClassName: 'px-3 min-w-[6rem] align-middle',
  },
};

export const excludeSort = ['actions', 'logs'];
export const FILTER_BLOCK_ID = 'past-appointments';
