import React, { useMemo, useState } from 'react';
import { BlockCollapseProps } from '../../interface';
import { useGetPage } from 'hooks/useGetPage';
import { pageIds } from 'utilities/constants';
import Collapse from '../../components/Collapse';
import InputComponent from 'components/inputComponent';

interface ITimeBlockProps extends BlockCollapseProps {
  initialState?: string;
}

const TimeSymptoms: React.FC<ITimeBlockProps> = ({
  id,
  handleContinue,
  handleEdit,
  currentCollapsesOpen,
  initialState,
}) => {
  const [howLong, setHowLong] = useState<string | undefined>(initialState);

  const { data: locale } = useGetPage({
    pageId: pageIds.APPOINTMENT_PREWORK,
    locale: 'en',
  });

  const howLongItems = useMemo<string[]>(
    () => Object.values(locale?.howLong || {}),
    [locale],
  );

  const onRadioClick = (selected: string) => () => {
    setHowLong(selected);
  };

  return (
    <Collapse
      savedData={howLong}
      title={locale?.howLongTitle}
      currentCollapsesOpen={currentCollapsesOpen}
      id={id}
      handleContinue={(id) => handleContinue(id, howLong)}
      handleEdit={handleEdit}
      disabled={!howLong}
    >
      <div className="flex flex-col">
        {howLongItems.map((item) => (
          <InputComponent
            key={item}
            type="radio"
            radioInputProps={{
              radioLabelTextWeight: 'font-semibold text-med-gray-3 text-sm',
              radioError: null,
              radioInputValue: item,
              radioInputLabel: item,
              radioInputCheckedValue: howLong,
              onRadioClick: onRadioClick(item),
            }}
            testID={`appointment-time-radio-${item}`}
          />
        ))}
      </div>
    </Collapse>
  );
};

export default TimeSymptoms;
