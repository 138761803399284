import React, { ReactNode, useCallback } from 'react';
import {
  AppointmentHistoryRowProps,
  appointmentRenderInfo,
  AppointmentStatus,
} from '../../interfaces';
import { ReactComponent as Doc } from 'assets/icons/file.svg';
import { getStatusClassName } from '../upcoming-appointments/helper/getStatusClassName';
import { FHIR_APPOINTMENT_STATUS } from '../../../../utilities/interfaces';
import { twMerge } from 'tailwind-merge';
import ButtonComponent from '../../../../components/button/buttonComponent';

interface IItemRow {
  label?: string;
  children: ReactNode;
}

const ItemRow: React.FC<IItemRow> = ({ label, children }) => {
  return (
    <div className="flex items-center gap-2 justify-between text-sm text-med-gray-3">
      <div className="text-charcoal-gray font-semibold">{label}</div>
      <div className="text-right">{children}</div>
    </div>
  );
};

const PastAppointmentMobileView: React.FC<AppointmentHistoryRowProps> = ({
  date,
  name: provider,
  type,
  logs: logsRow,
  locale: localeProp,
  status,
  actions,
  id,
}) => {
  const Icon = appointmentRenderInfo[type].icon;
  const text = appointmentRenderInfo[type].text;
  const statusClassName = status
    ? getStatusClassName(status as unknown as FHIR_APPOINTMENT_STATUS)
    : undefined;

  const Summury = useCallback(() => {
    if (!Array.isArray(actions)) return null;
    const { onClick } = actions[0];
    if (status === AppointmentStatus.PROPOSED) {
      return (
        <ButtonComponent
          type="underline"
          className="!text-xs !font-bold w-auto mx-auto"
          containerClassName="!gap-1"
          onClick={() => onClick(id)}
          Icon={Doc}
          iconPosition="left"
          iconWidth="w-[1.25rem] !stroke-transparent"
        >
          Summary
        </ButtonComponent>
      );
    }
    return null;
  }, [actions, id, status]);

  return (
    <div className="flex flex-col gap-2 border border-transparent border-b-gray-opacity-15 pb-4">
      <ItemRow label={localeProp?.date}>{date}</ItemRow>
      <ItemRow label={localeProp?.name}>{provider}</ItemRow>
      <ItemRow label={localeProp?.type}>
        <div className="flex items-center">
          <Icon className="inline" />
          <span className="ml-2 capitalize">
            {String(localeProp && localeProp[text])}
          </span>
        </div>
      </ItemRow>
      <ItemRow label={localeProp?.logs}>{logsRow.join(', ')}</ItemRow>
      <ItemRow label={localeProp?.status}>
        <span className={twMerge('capitalize', statusClassName)}>{status}</span>
      </ItemRow>

      <Summury />
    </div>
  );
};

export default PastAppointmentMobileView;
