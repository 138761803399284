import React from 'react';
import { Route, Routes } from 'react-router-dom';
import {
  APPOINTMENT_SUBMIT_PHOTO,
  APPOINTMENT_SUBMIT_PHOTO_SUCCESS,
  APPOINTMENT_UPLOAD_PHOTO,
} from 'utilities/routes';
import { PhotoSubmission } from './PhotoSubmission';
import MobileUploadPhoto from './MobileUploadPhoto';
import UploadPhotoSuccess from './UploadPhotoSuccess';

const AppointmentFacePhotoRoutes = () => {
  return (
    <Routes>
      <Route path={APPOINTMENT_UPLOAD_PHOTO} element={<MobileUploadPhoto />} />
      <Route path={APPOINTMENT_SUBMIT_PHOTO} element={<PhotoSubmission />} />
      <Route
        path={APPOINTMENT_SUBMIT_PHOTO_SUCCESS}
        element={<UploadPhotoSuccess />}
      />
    </Routes>
  );
};

export default AppointmentFacePhotoRoutes;
