import React from 'react';
import { ISymptomsProps } from '../../interface';
import { useGetComponent } from '../../../../../../hooks/useGetComponent';
import { componentIds } from '../../../../../../utilities/constants';
import { SYMPTOMS } from '../../routePath';
import SavedContentCard from '../SavedContentCard';
import CustomTooltip from '../../../../../../components/CustomTooltip';

interface ISymptomsCard {
  selectedSymptoms?: ISymptomsProps;
}

const SymptomsCard: React.FC<ISymptomsCard> = ({ selectedSymptoms }) => {
  const { reason, symptoms = [] } = selectedSymptoms || {};
  const { data: locale } = useGetComponent({
    componentId: componentIds.APPOINTMENT_PREWORK,
    locale: 'en',
  });

  if (!locale || !selectedSymptoms) return null;

  return (
    <SavedContentCard title={locale.symptomsPage} link={SYMPTOMS}>
      <div className="text-med-gray-3 font-medium text-base">
        <p className="truncate w-[200px]" data-tooltip-id="reason">
          {reason}
        </p>
        <p className="truncate w-[200px]" data-tooltip-id="symptoms">
          {symptoms.join(', ')}
        </p>
        <CustomTooltip
          id="reason"
          place="bottom"
          className="max-w-[300px] z-40"
          content={String(reason)}
        />
        <CustomTooltip
          id="symptoms"
          place="bottom"
          className="max-w-[300px] z-40"
          content={symptoms.join(', ')}
        />
      </div>
    </SavedContentCard>
  );
};

export default SymptomsCard;
