import React, { useMemo } from 'react';
import { ReactComponent as Arrow } from 'assets/icons/blue-arrow-left.svg';
import SelectDay from './SelectDay';
import { twMerge } from 'tailwind-merge';

interface ICarouselSelectDays {
  days: Date[];
  onSelect: (day: Date) => void;
  selectedDay: Date | null;
  onMonthChange: (date: Date | null) => Promise<Date | null>;
}

const today = new Date();
const dayNextYear = new Date(
  today.getFullYear() + 1,
  today.getMonth(),
  today.getDate(),
);

const CarouselSelectDays: React.FC<ICarouselSelectDays> = ({
  days,
  onSelect,
  selectedDay,
  onMonthChange,
}) => {
  const onNextClick = () => {
    const currentDay = new Date(days[days.length - 1]);
    const nextMonth = new Date(
      currentDay.getFullYear(),
      currentDay.getMonth() + 1,
      1,
    );
    return onMonthChange(nextMonth);
  };

  const onPrevClick = () => {
    const currentDay = new Date(days[days.length - 1]);
    const nextMonth = new Date(
      currentDay.getFullYear(),
      currentDay.getMonth() - 1,
      1,
    );
    return onMonthChange(nextMonth);
  };

  const isDisabledPrev = useMemo(() => days[0] <= today, [days]);
  const isDisabledNext = useMemo(
    () => days[days.length - 1] > dayNextYear,
    [days],
  );

  return (
    <div className="flex flex-row gap-2 h-full">
      <div
        data-testid="prev-month"
        className={twMerge(
          'max-desktop:hidden p-3 py-14 h-full rounded-lg border border-gray-opacity-15 flex items-center bg-white cursor-pointer',
          isDisabledPrev && 'opacity-50 cursor-not-allowed',
        )}
        onClick={() => !isDisabledPrev && onPrevClick()}
      >
        <Arrow className="h-3 fill-neutral-800" />
      </div>

      <div className="w-full overflow-auto max-desktop:flex-wrap flex flex-row gap-2.5 items-center">
        {days.map((day) => (
          <SelectDay
            key={day.toISOString()}
            date={day}
            onSelect={onSelect}
            isSelected={
              !!selectedDay && selectedDay.getDate() === day.getDate()
            }
          />
        ))}
      </div>
      <div
        data-testid="next-month"
        className={twMerge(
          'max-desktop:hidden p-3 py-14 h-full rounded-lg border border-gray-opacity-15 flex items-center bg-white cursor-pointer',
          isDisabledNext && 'opacity-50 cursor-not-allowed',
        )}
        onClick={() => !isDisabledNext && onNextClick()}
      >
        <Arrow className="h-3 fill-neutral-800 rotate-180" />
      </div>
    </div>
  );
};

export default CarouselSelectDays;
