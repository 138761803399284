import React from 'react';
import { ReactComponent as Arrow } from 'assets/icons/upArrow.svg';
import { ReactComponent as Edit } from 'assets/icons/edit.svg';
import { twMerge } from 'tailwind-merge';
import ButtonComponent from 'components/button/buttonComponent';
import { BlockCollapseProps } from '../interface';
import { useGetGenericActions } from 'hooks/useGetGenericActions';
import { genericActionsIds } from 'utilities/constants';
import Loader from 'components/loaderComponent';
import CustomTooltip from '../../../../../components/CustomTooltip';

interface ICollapseProps extends BlockCollapseProps {
  children: React.ReactNode;
  savedData: React.ReactNode | null;
  title: string;
  lineClassName?: string;
  btnTitle?: string;
  disabled?: boolean;
}

const showTooltipMinChars = 30;

const Collapse: React.FC<ICollapseProps> = ({
  title,
  children,
  savedData,
  currentCollapsesOpen,
  id,
  disabled = false,
  lineClassName = 'my-4',
  handleContinue,
  handleEdit,
  btnTitle,
}) => {
  const { data: genericActions } = useGetGenericActions({
    locale: 'en',
    genericActionId: [genericActionsIds.CONTINUE],
  });

  if (!genericActions) {
    return <Loader />;
  }

  return (
    <div className="bg-neutral-100 p-[1.875rem] py-5 rounded-lg">
      <div className="flex items-center justify-between">
        <span className="text-title font-semibold text-neutral-800">
          {title}
          {currentCollapsesOpen === id && (
            <span className="text-sm font-semibold text-alert-negative">*</span>
          )}
        </span>
        {savedData && currentCollapsesOpen !== id ? (
          <div className="flex items-center gap-3">
            <span
              id={`tooltip-${id}`}
              className="text-med-gray-3 text-right font-medium text-base truncate w-[17rem]"
            >
              {savedData}
            </span>
            {String(savedData).length > showTooltipMinChars && (
              <CustomTooltip
                anchorSelect={`#tooltip-${id}`}
                place="bottom"
                className="max-w-[300px] z-40"
                content={String(savedData)}
              />
            )}
            <ButtonComponent
              className="!p-0 !bg-transparent !h-auto !w-auto"
              containerClassName="!gap-0"
              type="icon"
              Icon={Edit}
              hoverFill="fill-clc-blue"
              testID={`edit-${id}`}
              onClick={() => handleEdit(id)}
            />
          </div>
        ) : (
          <Arrow
            className={twMerge(
              'w-3 h-1.5 fill-med-gray-3',
              currentCollapsesOpen === id ? '' : 'rotate-180',
            )}
          />
        )}
      </div>

      <div
        className={twMerge(
          'hidden',
          currentCollapsesOpen === id && 'flex flex-col',
        )}
        onClick={(event) => event.stopPropagation()}
      >
        <hr className={lineClassName} />
        {children}
        <ButtonComponent
          disabled={disabled}
          onClick={() => handleContinue(id)}
          testID={`continue-${id}`}
          className="!w-max mt-5 mb-[10px] !text-sm font-bold !rounded-[5px]"
        >
          {btnTitle || genericActions[genericActionsIds.CONTINUE].continue}
        </ButtonComponent>
      </div>
    </div>
  );
};

export default Collapse;
