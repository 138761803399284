import React, { useCallback, useMemo } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import LocationTabs from './components/LocationTabs';
import { ITab } from './components/LocationTab';
import { APPOINTMENT_STORAGE, FIND_A_PROVIDER, PAYMENTS } from './routePath';
import StorageService from 'utilities/storageService';
import { twMerge } from 'tailwind-merge';
import ProviderCard from './components/edit-cards/ProviderCard';
import AppointmentDetailsCard from './components/edit-cards/AppointmentDetailsCard';
import SymptomsCard from './components/edit-cards/SymptomsCard';
import { useGetComponent } from 'hooks/useGetComponent';
import { componentIds } from 'utilities/constants';
import PlacementCard from './components/edit-cards/PlacementCard';
import { IScheduledAnAppointmentFormProps } from './interface';
import Alert from 'components/alertComponent';
import ButtonComponent from 'components/button/buttonComponent';
import NavigateBlockModal from './components/NavigateBlockModal';

interface ILayoutProps {
  handleStartLocation: () => void;
  tabs: ITab[];
  startLocation: string;
}

const appointmentStorage = new StorageService(
  APPOINTMENT_STORAGE,
  sessionStorage,
);

const providerAlert = {
  placeholder:
    'If you don`t know which provider to choose, you can first choose a convenient time, then the available provider',
  button: 'first select a date and time',
};

const appointmentAlert = {
  placeholder:
    'If you don`t know which date and time to choose, you can first choose a provider, then the available date and time',
  button: 'first select a provider',
};

const Layout: React.FC<ILayoutProps> = ({
  tabs,
  handleStartLocation,
  startLocation,
}) => {
  const form = appointmentStorage.getData() as IScheduledAnAppointmentFormProps;
  const { pathname } = useLocation();
  const { data: locale } = useGetComponent({
    componentId: componentIds.APPOINTMENT_PREWORK,
    locale: 'en',
  });

  const alert = useMemo(
    () =>
      startLocation === FIND_A_PROVIDER ? providerAlert : appointmentAlert,
    [startLocation],
  );

  const getCurrentLocation = useCallback(() => {
    const path = pathname.split('/');
    const loc = path[path.length - 1];
    const currentIndex = tabs.findIndex((item) => item.location === loc);
    return tabs[currentIndex]?.location;
  }, [pathname, tabs]);

  return (
    <>
      {getCurrentLocation() === startLocation && (
        <Alert
          type="info-new"
          isNotification
          hideCloseButton
          extraActions={
            <ButtonComponent onClick={handleStartLocation} type="underline">
              {alert.button}
            </ButtonComponent>
          }
          text={alert.placeholder}
        />
      )}
      <div className="px-7 py-[30px] desktop:pt-0">
        <div className="flex flex-col gap-4 desktop:gap-0">
          <p
            className="text-h2 text-charcoal-gray font-medium desktop:text-h1"
            data-testid="appointment-schedule-page"
          >
            {locale?.title}
          </p>
        </div>
      </div>

      <LocationTabs tabs={tabs} />

      <div className={twMerge('grid grid-cols-4 gap-8')}>
        <div
          className={twMerge(
            'col-span-3 max-desktop:col-span-4',
            !form && 'col-span-4',
          )}
        >
          <Outlet />
        </div>
        {!!form && (
          <div className="hidden col-span-1 desktop:flex flex-col gap-4">
            <ProviderCard selectedProvider={form.selectedProvider} />
            <AppointmentDetailsCard
              selectedAppointment={form.selectedAppointment}
            />
            <SymptomsCard selectedSymptoms={form.selectedSymptoms} />
            <PlacementCard selectedPlacement={form.selectedPlacement} />
          </div>
        )}
      </div>

      <NavigateBlockModal isBlock={getCurrentLocation() !== PAYMENTS} />
    </>
  );
};

export default Layout;
