import React from 'react';

interface IBodySectionHeaderProps {
  children?: React.ReactNode;
}

const BodySectionHeader: React.FC<IBodySectionHeaderProps> = ({ children }) => {
  return (
    <h3 className="font-semibold text-neutral-800 text-title mt-8 mb-4">
      {children}
    </h3>
  );
};

export default BodySectionHeader;
