import React, { useMemo } from 'react';
import { Dot, DotBodyGroup, ImageObject, SvgImageTag } from '../interfaces';
import CheckboxList from './CheckboxList';
import DotPlacer from '../DotPlacer';
import { useGetComponent } from 'hooks/useGetComponent';
import { componentIds } from 'utilities/constants';
import BodySectionHeader from '../BodySectionHeader';

interface IBodySymptomsSections {
  frontDots: Dot[];
  backDots: Dot[];
  handleBodyParsing: (
    direction: 'front' | 'back',
    id: number,
    isDetails?: boolean,
  ) => void;
  mediaPerBodyLocation: Map<string, string | ImageObject>;
}

export const getDirections = (frontDots: Dot[], id: number) => {
  return frontDots.map((d) => d.id).includes(id) ? 'front' : 'back';
};

const headDotsFilter = (dots: Dot[]) =>
  dots.filter(({ group }) => group === DotBodyGroup.HEAD);

const HeadBodySection: React.FC<IBodySymptomsSections> = ({
  backDots,
  frontDots,
  handleBodyParsing,
  mediaPerBodyLocation,
}) => {
  const { data: locale, loading } = useGetComponent({
    locale: 'en',
    componentId: componentIds.APPOINTMENT_PREWORK,
  });
  const dots = useMemo(
    () =>
      [...backDots, ...frontDots]
        .filter(({ group }) => group === DotBodyGroup.HEAD)
        .sort((a, b) => String(a.location).localeCompare(String(b.location))),
    [backDots, frontDots],
  );
  const chunk1 = dots.slice(0, dots.length / 2);
  const chunk2 = dots.slice(dots.length / 2, dots.length);

  const onCheckboxChange = (id: number, isDetails = false) => {
    handleBodyParsing(getDirections(frontDots, id), id, isDetails);
  };

  const frontHeadDots = useMemo(() => headDotsFilter(frontDots), [frontDots]);
  const backHeadDots = useMemo(() => headDotsFilter(backDots), [backDots]);

  if (loading || !locale) return null;
  return (
    <>
      <BodySectionHeader>{locale?.headTitle}</BodySectionHeader>
      <section className="grid desktop:grid-cols-4 grid-cols-1 pb-8 border border-transparent border-b-gray-opacity-15">
        <div className="grid grid-cols-1 gap-4 mt-2">
          <CheckboxList
            dots={chunk2}
            medias={mediaPerBodyLocation}
            onCheckboxChange={onCheckboxChange}
            onBodyPartClick={(id) => onCheckboxChange(id, true)}
          />
        </div>
        <div className="desktop:col-span-2 col-span-1 max-desktop:order-first">
          <div className="flex justify-center">
            <DotPlacer
              dots={frontHeadDots}
              onDotSelected={(id, isDetails) =>
                handleBodyParsing('front', id, isDetails)
              }
              type={SvgImageTag.HEAD_FRONT_SVG}
            />
            <DotPlacer
              dots={backHeadDots}
              onDotSelected={(id, isDetails) =>
                handleBodyParsing('back', id, isDetails)
              }
              type={SvgImageTag.HEAD_BACK_SVG}
            />
          </div>
        </div>
        <div className="grid grid-cols-1 gap-4 mt-2">
          <CheckboxList
            medias={mediaPerBodyLocation}
            dots={chunk1}
            onCheckboxChange={onCheckboxChange}
            onBodyPartClick={(id) => onCheckboxChange(id, true)}
          />
        </div>
      </section>
    </>
  );
};

export default HeadBodySection;
