import React from 'react';
import { useGetPage } from 'hooks/useGetPage';
import { pageIds } from 'utilities/constants';

const NoProviders = () => {
  const { data: selectAProviderLocale } = useGetPage({
    locale: 'en',
    pageId: pageIds.SELECT_A_PROVIDER,
  });

  return (
    <div className="flex flex-col items-center justify-center w-full h-full">
      <h5 className="text-charcoal-gray text-h2 font-medium mb-2">
        {selectAProviderLocale?.noProvidersFound}
      </h5>
      <p className="text-med-gray-3 font-medium text-base">
        {selectAProviderLocale?.noProvidersFoundPlaceholder}
      </p>
    </div>
  );
};

export default NoProviders;
