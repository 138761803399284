import { useCallback, useEffect, useState } from 'react';
import {
  parseServiceType,
  ProviderOutput,
} from '../../../select-a-provider/ProviderCardProps';
import { useGetFreeProvidersByTimeSlotsLazyQuery } from 'graphql/generated/hasura';
import { extractUUIDFromResURL } from 'utilities/functions';
import { useGetFhirProvidersLazyQuery } from 'graphql/generated/remote-schema-hasura';
import { IScheduledAnAppointmentFormProps } from '../../interface';
import StorageService from 'utilities/storageService';
import { APPOINTMENT_STORAGE } from '../../routePath';

const appointmentStorage = new StorageService(
  APPOINTMENT_STORAGE,
  sessionStorage,
);

export const useGetProviders = (filter = {}) => {
  const form = appointmentStorage.getData() as IScheduledAnAppointmentFormProps;
  const [total, setTotal] = useState<number>(0);
  const [providers, setProviders] = useState<ProviderOutput[]>([]);

  const [getProvidersByTimeSlots, { loading: freeProvidersLoading }] =
    useGetFreeProvidersByTimeSlotsLazyQuery({
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        const providers =
          data?.GetFreeProvidersByTimeSlots?.providers.map<ProviderOutput>(
            (provider) => {
              const pictureId = extractUUIDFromResURL(
                provider?.SENSITIVE_profile_picture_id ?? '',
              );
              return {
                ...provider,
                isFavorite: false,
                accepted_appointment_types: [],
                SENSITIVE_profile_picture_id: pictureId,
              } as unknown as ProviderOutput;
            },
          );
        setProviders(providers || []);
        data?.GetFreeProvidersByTimeSlots?.total &&
          setTotal(data.GetFreeProvidersByTimeSlots?.total);
      },
    });

  const [getFhirProviders, { loading: providersLoading }] =
    useGetFhirProvidersLazyQuery({
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        const providers = data.getFHIRProviders.providers.map<ProviderOutput>(
          (provider) => {
            const pictureId = extractUUIDFromResURL(
              provider?.SENSITIVE_profile_picture_id ?? '',
            );
            return {
              ...provider,
              isFavorite: false,
              accepted_appointment_types: provider.serviceOfferings.map(
                (serviceOffering) => ({
                  price: serviceOffering.price,
                  serviceType: parseServiceType(serviceOffering.serviceType),
                }),
              ),
              SENSITIVE_profile_picture_id: pictureId,
            } as ProviderOutput;
          },
        );
        setProviders(providers);
        data.getFHIRProviders.total && setTotal(data.getFHIRProviders.total);
      },
    });

  const getProviders = useCallback(() => {
    if (form?.selectedAppointment?.type?.id) {
      return getProvidersByTimeSlots({
        variables: {
          ...filter,
          date: String(form?.selectedAppointment?.timeSlot),
          appointmentTypeId: String(form?.selectedAppointment?.type?.id),
        },
      });
    }
    return getFhirProviders({ variables: { ...filter } });
  }, [
    filter,
    form?.selectedAppointment?.timeSlot,
    form?.selectedAppointment?.type?.id,
    getFhirProviders,
    getProvidersByTimeSlots,
  ]);

  useEffect(() => {
    getProviders();
  }, [getProviders]);

  return {
    providers,
    total,
    loading: providersLoading || freeProvidersLoading,
  };
};
