import { useEffect, useMemo, useState } from 'react';
import { Dot, DotBodyGroup } from './interfaces';
import { useGetComponent } from 'hooks/useGetComponent';
import { componentIds } from 'utilities/constants';
import { initialDotsState } from './constants';
import { chain, isEmpty } from 'lodash';

export const useGetFrontAndBackDots = (initialSelectedIds: string[] = []) => {
  const [frontDots, setFrontDots] = useState<Dot[]>([]);
  const [backDots, setBackDots] = useState<Dot[]>([]);

  const { data: locale, loading } = useGetComponent({
    locale: 'en',
    componentId: componentIds.APPOINTMENT_PREWORK,
  });

  /* Setting the locale into the dots to avoid hardcoding texts */
  useEffect(
    () => {
      if (locale && !loading) {
        const frontLocaleCheckbox = locale?.frontCheckboxes;
        const backLocaleCheckbox = locale?.backCheckboxes;
        if (
          frontLocaleCheckbox ||
          Object.values(frontLocaleCheckbox).length > 0
        ) {
          const mappedFrontLocaleCheckboxes = initialDotsState.front.map(
            (dot) => ({
              ...dot,
              location: frontLocaleCheckbox[dot.strapiKey],
              selected: initialSelectedIds.includes(String(dot.id)),
            }),
          );
          setFrontDots(mappedFrontLocaleCheckboxes);
        }
        if (
          backLocaleCheckbox ||
          Object.values(backLocaleCheckbox).length > 0
        ) {
          const mappedBackLocaleCheckboxes = initialDotsState.back.map(
            (dot) => ({
              ...dot,
              location: backLocaleCheckbox[dot.strapiKey],
              selected: initialSelectedIds.includes(String(dot.id)),
            }),
          );
          setBackDots(mappedBackLocaleCheckboxes);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [locale, loading],
  );

  if (loading || !locale)
    return {
      frontDots,
      backDots,
      setFrontDots,
      setBackDots,
    };

  return { frontDots, backDots, setFrontDots, setBackDots };
};

const DEFAULT_GROUPS = {
  [DotBodyGroup.BODY_LEFT]: [],
  [DotBodyGroup.BODY_RIGHT]: [],
  [DotBodyGroup.UPPER_EXTREMITIES_RIGHT]: [],
  [DotBodyGroup.LOWER_EXTREMITIES_RIGHT]: [],
  [DotBodyGroup.UPPER_EXTREMITIES_LEFT]: [],
  [DotBodyGroup.LOWER_EXTREMITIES_LEFT]: [],
};

export const useGroupByDots = (dotsState: Dot[]) => {
  const group = useMemo(
    () =>
      chain(
        dotsState.sort((a, b) =>
          String(a.location).localeCompare(String(b.location)),
        ),
      )
        .groupBy((dot) => dot.group)
        .value(),
    [dotsState],
  );

  return !isEmpty(group) ? group : DEFAULT_GROUPS;
};
