import React from 'react';
import { ReactComponent as Clock } from 'assets/icons/time.svg';
import { AppointmentTypeEnum } from 'graphql/generated/hasura';
import { appointmentRenderInfo } from '../../interfaces';
import { convertTo12Hourformat } from '../../../dashboard/components/upcomingAppointments/functions';
import IconRow from './IconRow';

interface IStartTimeAndTypeAppointmentProps {
  start: string;
  end: string;
  type: AppointmentTypeEnum;
}

const getPeriodString = (start: string, end: string) => {
  return `${convertTo12Hourformat(start)} - ${convertTo12Hourformat(end)}`;
};

const StartTimeAndTypeAppointment: React.FC<
  IStartTimeAndTypeAppointmentProps
> = ({ start, end, type }) => {
  const { icon: Icon, label } = appointmentRenderInfo[type];

  return (
    <div className="flex flex-col justify-center gap-2">
      <IconRow
        icon={<Clock className="fill-neutral-800" />}
        text={getPeriodString(start, end)}
      />
      <IconRow icon={<Icon className="fill-neutral-800" />} text={label} />
    </div>
  );
};

export default StartTimeAndTypeAppointment;
