import React from 'react';
import DatePicker from 'react-datepicker';
import { ReactComponent as Arrow } from 'assets/icons/upArrow.svg';
import 'react-datepicker/dist/react-datepicker.css';
import './index.css';
import { ReactDatePickerCustomHeaderProps } from 'react-datepicker/dist/calendar';
import { twMerge } from 'tailwind-merge';

interface ICustomMonthDatepickerProps {
  onChange: (date: Date | null) => void;
  startDate: Date | null;
  className?: string;
  testID?: string;
}

const renderCustomHeader = ({
  monthDate,
  decreaseYear,
  increaseYear,
  nextYearButtonDisabled,
  prevYearButtonDisabled,
}: ReactDatePickerCustomHeaderProps) => (
  <div className="border border-transparent border-b-gray-opacity-15 pb-2">
    <button
      aria-label="Previous Year"
      className={twMerge(
        'react-datepicker__navigation react-datepicker__navigation--previous',
        prevYearButtonDisabled && 'hidden',
      )}
      disabled={prevYearButtonDisabled}
      onClick={decreaseYear}
    >
      <Arrow className="h-[7px] fill-med-gray-3 -rotate-90" />
    </button>
    <span className="react-datepicker__current-month text-sm">
      {monthDate.toLocaleString('en-US', {
        year: 'numeric',
      })}
    </span>
    <button
      aria-label="Next Year"
      disabled={nextYearButtonDisabled}
      className={twMerge(
        'react-datepicker__navigation react-datepicker__navigation--next',
        nextYearButtonDisabled && 'hidden',
      )}
      onClick={increaseYear}
    >
      <Arrow className="rotate-90 h-[7px] fill-med-gray-3" />
    </button>
  </div>
);

const renderMonthContent = (
  month: number,
  shortMonth: string,
  longMonth: string,
) => {
  return <span>{longMonth}</span>;
};

const today = new Date();
const nextYearDate = new Date(today);
nextYearDate.setFullYear(today.getFullYear() + 1);

const CustomMonthDatepicker: React.FC<ICustomMonthDatepickerProps> = ({
  onChange,
  testID,
  className,
  startDate = today,
}) => {
  return (
    <DatePicker
      data-testid={testID}
      selected={startDate}
      minDate={today}
      maxDate={nextYearDate}
      onChange={(date) => onChange(date)}
      renderMonthContent={renderMonthContent}
      renderCustomHeader={renderCustomHeader}
      showMonthYearPicker
      dateFormat="MMMM YYYY"
      className="w-full !py-2 !px-3"
      icon={
        <Arrow className="rotate-180 fill-med-gray-3 w-[12px] flex items-center translate-y-[6px] custom-arrow" />
      }
      wrapperClassName={className}
      toggleCalendarOnIconClick
      popperClassName="-mt-3"
      showIcon
      showPopperArrow={false}
    />
  );
};

export default CustomMonthDatepicker;
