import React, { useMemo } from 'react';

interface IDateAppointmentProps {
  startDate: string;
}

const DateAppointment: React.FC<IDateAppointmentProps> = ({ startDate }) => {
  const date = useMemo(() => new Date(startDate), [startDate]);

  const day = date.toLocaleDateString('en-US', {
    day: '2-digit',
  });

  const weekDay = date.toLocaleDateString('en-US', {
    weekday: 'short',
  });
  const month = date.toLocaleDateString('en-US', {
    month: 'short',
  });

  return (
    <div className="bg-white rounded-md flex flex-col gap-2 items-center justify-center p-2">
      <div className="text-neutral-800 font-semibold text-base">
        {weekDay}, {month}
      </div>
      <div className="text-neutral-800 font-semibold text-h3">{day}</div>
    </div>
  );
};

export default DateAppointment;
