import React from 'react';
import {
  BlockerState,
  INavigateBlock,
  useNavigateBlock,
} from 'hooks/useNavigateBlock';
import { componentIds } from 'utilities/constants';
import { useGetPage } from 'hooks/useGetPage';
import { ModalButtonProps } from 'components/modal/modal.interfaces';
import Modal from 'components/modal/modalComponent';
import StorageService from 'utilities/storageService';
import { APPOINTMENT_STORAGE } from '../routePath';

const mock = {
  title: 'Cancel scheduling',
  description: 'Are you sure you want to cancel your appointment scheduling?',
  cancelBtn: 'No, Stay',
  continueBtn: 'Yes, Cancel',
};

const appointmentStorage = new StorageService(
  APPOINTMENT_STORAGE,
  sessionStorage,
);

const NavigateBlockModal: React.FC<INavigateBlock> = ({ isBlock }) => {
  const { handleConfirm, handleCancel, blocker } = useNavigateBlock(isBlock);

  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: componentIds.SCHEDULE_APPOINTMENT_LEAVE_MODAL,
  });

  const handleLeave = () => {
    appointmentStorage.clear();
    handleConfirm();
  };

  const Buttons: ModalButtonProps[] = [
    {
      label: locale?.continueBtn || mock.continueBtn,
      onClick: handleLeave,
      className: 'desktop:w-[160px] !text-sm !font-bold rounded',
    },
    {
      label: locale?.cancelBtn || mock.cancelBtn,
      onClick: handleCancel,
      type: 'underline',
      className: '!text-sm !font-bold',
    },
  ];

  if (loading && !locale) {
    return null;
  }

  return (
    <Modal
      isOpen={blocker.state === BlockerState.BLOCKED}
      onClose={handleCancel}
      buttons={Buttons}
      modalContainerCustomClass="desktop:max-w-[600px] mx-auto"
      titleCustomClass="normal-case !text-charcoal-gray !text-h3 !py-5"
      title={locale?.title || mock.title}
      disableMobileGrow
    >
      <p className="text-med-gray font-medium">
        {locale?.description || mock.description}
      </p>
    </Modal>
  );
};

export default NavigateBlockModal;
