import React from 'react';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import { twMerge } from 'tailwind-merge';

import ButtonComponent from './button/buttonComponent';
import IconComponent from './iconComponent';

import { ReactComponent as ErrorIcon } from 'assets/icons/warning.svg';

interface CustomProps {
  label: string;
  supportText?: string;
  btnText?: string;
  className?: string;
  error?: string;
}

interface IDropzone extends DropzoneOptions, CustomProps {}

const Dropzone: React.FC<IDropzone> = ({
  onDrop,
  supportText,
  accept,
  label,
  className,
  error,
}) => {
  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    noClick: true,
    accept,
  });

  return (
    <div
      className={twMerge(
        'w-full h-[240px] p-4 flex flex-col items-center justify-center border border-dashed border-med-gray rounded-lg',
        className,
        error && 'border-[#BF3F3F] bg-[#FFF4C6]',
      )}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      {isDragActive ? (
        <p>Drop the files here ...</p>
      ) : (
        <>
          <p className="font-semibold text-lg text-neutral-800">{label}</p>
          <p className="font-semibold text-sm text-med-gray-3 text-center">
            {supportText}
          </p>

          {error && (
            <div className="text-[#BF3F3F] text-xs font-semibold flex gap-1 mt-2.5">
              <IconComponent
                width={'w-3'}
                height={'h-[11px]'}
                icon={ErrorIcon}
                fill={'fill-current'}
                stroke={'stroke-current'}
                className="mt-0.5"
              />
              <p>{error}</p>
            </div>
          )}

          <ButtonComponent
            className="mt-8 w-auto !text-sm !rounded-[5px]"
            onClick={open}
          >
            Choose File
          </ButtonComponent>
        </>
      )}
    </div>
  );
};

export default Dropzone;
