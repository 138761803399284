import React, { useEffect, useMemo, useState } from 'react';
import { ReactComponent as Done } from 'assets/icons/ball-check-icon.svg';
import { useGetComponent } from 'hooks/useGetComponent';
import { componentIds, pageIds } from 'utilities/constants';
import Loader from 'components/loaderComponent';
import { getTimeDifferenceInHours } from '../../../dashboard/components/upcomingAppointments/functions';
import ButtonComponent from 'components/button/buttonComponent';
import { DASHBOARD } from 'utilities/routes';
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import StorageService from 'utilities/storageService';
import { APPOINTMENT_STORAGE } from '../../components/scheduled-a-appointment/routePath';
import { AppointmentDetails, useGetAppointments } from './hook';
import { getAppointmentFullTimeDate } from 'utilities/dateHelper';
import { boldString, sanitize } from '../../../../utilities/sanitizer';
import CancelAppointmentModal from '../../components/modalContent/CancelAppointmentModal';
import { useGetPage } from 'hooks/useGetPage';
import { CANCEL_APPOINTMENT_ID } from '../../components/upcoming-appointments/helper/const';

const bolderStr = 'least 2 days (48 hours)';

const MIN_RESCHEDULE_TIME = 48;
const appointmentStorage = new StorageService(
  APPOINTMENT_STORAGE,
  sessionStorage,
);

const AppointmentPaymentSuccess = () => {
  const [url] = useSearchParams();
  const navigate = useNavigate();

  const appointmentId = url.get('appointment_id');
  const [appointment, setAppointment] = useState<AppointmentDetails>();

  const { data: locale, loading: localeLoading } = useGetComponent({
    locale: 'en',
    componentId: componentIds.APPOINTMENT_PREWORK,
  });

  const { data: localePage, loading: localeLoadingPage } = useGetPage({
    pageId: pageIds.MY_APPOINTMENTS,
    locale: 'en',
  });

  const getData = useGetAppointments(appointmentId);

  const handleGoHome = () => navigate(DASHBOARD);

  const handleCancel = () =>
    navigate({
      search: createSearchParams({
        [CANCEL_APPOINTMENT_ID]: String(appointmentId),
      }).toString(),
    });

  const isReschedulePosiblelity = useMemo(
    () =>
      getTimeDifferenceInHours(
        String(appointment?.start),
        new Date().toISOString(),
      ) >= MIN_RESCHEDULE_TIME,
    [appointment],
  );

  const type = useMemo(() => {
    const typeObj = appointment?.appointmentType?.toLowerCase();
    if (!typeObj) {
      return '';
    }
    return localePage[typeObj]?.deets;
  }, [appointment?.appointmentType, localePage]);

  const description = useMemo(() => {
    return boldString(
      locale?.payments?.rescheduleDescriptionSuccess,
      bolderStr,
    );
  }, [locale?.payments?.rescheduleDescriptionSuccess]);

  useEffect(() => {
    getData()
      .then((data) => setAppointment(data))
      .catch((error) => console.error(error));
    return () => {
      appointmentStorage.clear();
    };
  }, [getData]);

  if (!locale || localeLoading || !localePage || localeLoadingPage) {
    return <Loader />;
  }

  return (
    <div className="flex items-center justify-center flex-col w-full p-5 desktop:py-20 desktop:px-16 gap-8 bg-white rounded-lg">
      <Done />
      <h4 className="desktop:text-h1 text-h3 font-medium text-center max-w-xl text-neutral-800">
        {locale?.payments?.appointmentSuccessfully}
      </h4>

      <div className="desktop:p-8 p-5 rounded-lg flex items-center justify-center flex-col max-w-xl bg-neutral-100">
        <p className="text-base font-medium text-neutral-800">{`${type} with ${appointment?.providerFirstName} ${appointment?.providerLastName} on`}</p>
        <p className="font-semibold text-charcoal-gray text-h3 mt-2.5 mb-5 text-center">
          {getAppointmentFullTimeDate(String(appointment?.start))}
        </p>

        <ButtonComponent
          onClick={handleGoHome}
          fullWidthClassName="w-auto"
          className="!text-sm !px-5 !py-3 !rounded-[5px]"
        >
          {locale?.payments?.toHomePage}
        </ButtonComponent>

        {isReschedulePosiblelity ? (
          <>
            <div className="flex max-desktop:flex-col max-desktop:gap-5 items-center justify-around w-full mt-8">
              <ButtonComponent
                type="underline"
                className="!text-sm !font-bold"
                onClick={handleCancel}
              >
                {locale?.payments?.cancel}
              </ButtonComponent>
              <ButtonComponent type="underline" className="!text-sm !font-bold">
                {locale?.payments?.reschedule}
              </ButtonComponent>
            </div>
            <p
              className="text-sm text-med-gray-3 font-medium text-center mt-2.5"
              dangerouslySetInnerHTML={{ __html: sanitize(description) }}
            />
          </>
        ) : (
          <p className="text-sm text-med-gray-3 font-medium text-center mt-8">
            {locale?.payments?.rescheduleDescriptionDecline}
          </p>
        )}
      </div>

      <CancelAppointmentModal callback={handleGoHome} />
    </div>
  );
};

export default AppointmentPaymentSuccess;
