import React from 'react';
import { ReactComponent as Done } from 'assets/icons/white-checkbox-check.svg';
import { twMerge } from 'tailwind-merge';

export interface ITab {
  title: string;
  location: string;
  isPassed?: boolean;
}

interface ILocationTabProps extends ITab {
  isActive?: boolean;
  number: number;
}

const convertNumberToTabFormat = (num: number) =>
  num.toString().padStart(2, '0');

const LocationTab: React.FC<ILocationTabProps> = ({
  isActive,
  isPassed = false,
  title,
  number,
}) => {
  return (
    <div
      className={twMerge(
        'w-full p-5 flex gap-3 rounded-lg text-med-gray-3 font-600 text-base items-center font-semibold',
        isActive && 'text-neutral-800 bg-white',
      )}
    >
      <span
        className={twMerge(
          'rounded-full flex items-center justify-center bg-white text-sm p-1 px-1.5',
          isActive && 'bg-clc-blue text-white',
          isPassed && 'hidden',
        )}
      >
        {convertNumberToTabFormat(number)}
      </span>
      {isPassed && <Done className="w-4 h-3 stroke-clc-blue" />}
      <span>{title}</span>
    </div>
  );
};

export default LocationTab;
