import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useGetComponent } from 'hooks/useGetComponent';
import { componentIds, genericActionsIds } from 'utilities/constants';
import { AlertState } from '../../../interfaces';
import { useGetFrontAndBackDotsWithMediaSync } from 'hooks/useGetFrontAndBackDotsWithMediaSync';
import {
  Dot,
  ImageObject,
} from '../../../../my-skin/components/BodySymtomsLocation/interfaces';
import ModalSymptomDetails from '../../../../my-skin/components/ModalSymptomDetails';
import HeadBodySection from '../../../../my-skin/components/BodySymtomsLocation/bodySections/HeadBodySection';
import FrontBodySection from '../../../../my-skin/components/BodySymtomsLocation/bodySections/FrontBodySection';
import BackBodySection from '../../../../my-skin/components/BodySymtomsLocation/bodySections/BackBodySection';
import { useGetAppointmentByCodexAppointmentIdQuery } from 'graphql/generated/remote-schema-hasura';
import { useLocation } from 'react-router-dom';
import Loader from '../../../../../components/loaderComponent';
import { useGetGenericActions } from '../../../../../hooks/useGetGenericActions';
import ButtonComponent from '../../../../../components/button/buttonComponent';
import { QR_ACTION } from 'utilities/routes';
import ModalUploadPhoto from '../../../../my-skin/components/ModalUploadPhoto';
import ModalPhotoLibrary from '../../../../my-skin/components/ModalPhotoLibrary';
import { useRootConfig } from 'utilities/rootConfig';
import {
  getTokenFromFirebase,
  useGenerateCustomToken,
} from '../../../../../firebase/hooks';
import { dataURLToFile } from 'utilities/functions';
import StorageService from 'utilities/storageService';
import { APPOINTMENT_STORAGE } from '../routePath';
import { ScanActionEnum } from '../../../../qr-action/actionName';

const TOKEN_TIME = 55 * 60 * 1000;
const appointmentStorage = new StorageService(
  APPOINTMENT_STORAGE,
  sessionStorage,
);

interface ISymptomsPlacement {
  handleNext: (value: Map<string, ImageObject>) => void;
}

const SymptomsPlacement: React.FC<ISymptomsPlacement> = ({ handleNext }) => {
  const form = appointmentStorage.getData();

  const initialMedia = useMemo(
    () => form?.selectedPlacement?.media || [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [form?.selectedPlacement?.media?.length],
  );

  const location = useLocation();
  const [selectedDot, setDot] = useState<Dot | null>(null);
  const editAppointmentId = location.state?.toEditAppointmentCodexId;
  const { handleGenerateCustomToken, data } = useGenerateCustomToken();

  const [isShowAddsPhotoModal, setIsShowAddsPhotoModal] =
    useState<boolean>(false);
  const [isShowLibraryModal, setIsShowLibraryModal] = useState<boolean>(false);
  const [bodySymptomsPlacerLabel, setBodySymptomsPlacerLabel] =
    useState<string>('');
  const [selectetdBodyPlace, setSelectedBodyPlace] = useState<number | null>(
    null,
  );

  const { baseUrl } = useRootConfig();
  const [authToken, setAuthToken] = useState<string | null>(null);
  const token = useMemo(() => data?.GenerateCustomToken?.token, [data]);

  const handleGetAuthToken = useCallback(async () => {
    const token = await getTokenFromFirebase();
    setAuthToken(token);
    return token;
  }, []);

  useEffect(() => {
    handleGetAuthToken();
  }, [handleGetAuthToken]);

  const { data: locale, loading: localeLoading } = useGetComponent({
    locale: 'en',
    componentId: componentIds.APPOINTMENT_PREWORK,
  });

  const { data: editAppointmentData, loading: editAppointmentLoading } =
    useGetAppointmentByCodexAppointmentIdQuery({
      variables: {
        appointmentCodexId: editAppointmentId,
      },
      skip: !editAppointmentId,
    });

  const { data: genericActions } = useGetGenericActions({
    locale: 'en',
    genericActionId: [genericActionsIds.CONTINUE],
  });

  const [, setAlert] = useState<AlertState>();
  const {
    frontDots,
    backDots,
    UploadInputComponent,
    mediaPerBodyLocation,
    uploadFile,
    updateMediaAndDotsWithoutSync,
  } = useGetFrontAndBackDotsWithMediaSync(setAlert, initialMedia);

  const dotsArray = useMemo(
    () => [...frontDots, ...backDots],
    [frontDots, backDots],
  );

  const toggleFrontDot = (id: number): void => {
    const dot = frontDots.find((dot) => dot.id === id);
    if (dot?.selected) {
      setDot(dot);
    }
  };
  const toggleBackDot = (id: number): void => {
    const dot = backDots.find((dot) => dot.id === id);
    if (dot?.selected) {
      setDot(dot);
    }
  };

  const handleDeleteSymptom = (): void => {
    const newFrontDots = frontDots.map((dot) => ({
      ...dot,
      selected: selectedDot?.id === dot.id ? false : dot.selected,
    }));
    const newBackDots = backDots.map((dot) => ({
      ...dot,
      selected: selectedDot?.id === dot.id ? false : dot.selected,
    }));
    updateMediaAndDotsWithoutSync(newFrontDots, newBackDots, (prevMedia) => {
      const newMap = new Map(prevMedia);
      if (selectedDot?.id) {
        newMap.delete(selectedDot?.id.toString());
      }
      return newMap;
    });
    setDot(null);
  };

  const addPhotoModalOnClose = () => {
    setIsShowAddsPhotoModal(false);
  };
  const onShowLibraryModal = () => {
    addPhotoModalOnClose();
    setIsShowLibraryModal(true);
  };

  const onCloseLibrary = () => {
    setIsShowLibraryModal(false);
    setDot(null);
  };

  const onBodySymptomsPlacerClick = (
    label: string,
    _direction: string,
    bodyId: number,
  ) => {
    setBodySymptomsPlacerLabel(label);
    setSelectedBodyPlace(bodyId);
    setIsShowAddsPhotoModal(true);
  };

  const handleBodyParsing = (
    direction: 'front' | 'back',
    bodyId: number,
    isDetails = false,
  ) => {
    const dot = dotsArray.find((dot) => dot.id === bodyId);
    if (!dot) return;
    if (!isDetails) {
      return onBodySymptomsPlacerClick(
        dot?.location as string,
        direction,
        bodyId,
      );
    }
    if (direction === 'back') {
      toggleBackDot(bodyId);
    } else {
      toggleFrontDot(bodyId);
    }
  };

  const onUploadByMediaLibrary = useCallback(
    (url: string, id: string, description?: string) => {
      const newBackDots = backDots.map((dot) => ({
        ...dot,
        selected: dot.id === selectetdBodyPlace || dot.selected,
      }));
      const newFrontDots = frontDots.map((dot) => ({
        ...dot,
        selected: dot.id === selectetdBodyPlace || dot.selected,
      }));

      updateMediaAndDotsWithoutSync(newFrontDots, newBackDots, (prevMedia) => {
        const newMap = new Map(prevMedia);
        if (selectetdBodyPlace) {
          newMap.set(selectetdBodyPlace.toString(), {
            image: url,
            mediaId: id,
            description: description || '',
            location: bodySymptomsPlacerLabel,
          });
        }
        return newMap;
      });
      setIsShowLibraryModal(false);
    },
    [
      bodySymptomsPlacerLabel,
      backDots,
      frontDots,
      selectetdBodyPlace,
      updateMediaAndDotsWithoutSync,
    ],
  );

  const onUploadPhoto = useCallback(
    async (url: string, location: string, description?: string) => {
      const file = dataURLToFile(url, 'image');
      const buffer = await file.arrayBuffer();
      const result = await uploadFile({
        variables: {
          file: {
            mimetype: file.type,
            originalname: file.name,
            buffer: Array.from(new Uint8Array(buffer)),
          },
        },
      });

      const image = result.data?.UploadFileToStorage?.file?.url;
      const mediaId = result.data?.UploadFileToStorage?.file?.fileId;

      const newBackDots = backDots.map((dot) => ({
        ...dot,
        selected: dot.id === selectetdBodyPlace || dot.selected,
      }));
      const newFrontDots = frontDots.map((dot) => ({
        ...dot,
        selected: dot.id === selectetdBodyPlace || dot.selected,
      }));

      updateMediaAndDotsWithoutSync(newFrontDots, newBackDots, (prevMedia) => {
        const newMap = new Map(prevMedia);
        if (selectetdBodyPlace) {
          newMap.set(selectetdBodyPlace.toString(), {
            image: image || url,
            mediaId: String(mediaId),
            description: description || '',
            location,
          });
        }
        return newMap;
      });
      setIsShowLibraryModal(false);
    },
    [
      backDots,
      frontDots,
      selectetdBodyPlace,
      updateMediaAndDotsWithoutSync,
      uploadFile,
    ],
  );

  useEffect(() => {
    handleGenerateCustomToken();
    const interval = setInterval(() => {
      handleGenerateCustomToken();
    }, TOKEN_TIME);

    return () => {
      clearInterval(interval);
    };
  }, [handleGenerateCustomToken]);

  useEffect(() => {
    if (!editAppointmentData?.getFHIRAppointmentByCodexId?.appointment) {
      return;
    }
    const lastAppointment =
      editAppointmentData.getFHIRAppointmentByCodexId.appointment;
    if (lastAppointment.symptomsLocation && lastAppointment.media?.length) {
      const mediaMap = lastAppointment.media.reduce<Map<string, ImageObject>>(
        (acc, media) => {
          if (media.bodySite && media.fileId) {
            acc.set(media.bodySite, {
              image: media.url,
              mediaId: media.fileId,
            });
          }
          return acc;
        },
        new Map(),
      );
      const symptomsLocationSet = new Set(
        lastAppointment.symptomsLocation.filter((location) =>
          mediaMap.has(location),
        ),
      );
      if (!symptomsLocationSet.size || !mediaMap.size) {
        return;
      }
      updateMediaAndDotsWithoutSync(
        (dots) =>
          dots.map((dot) => ({
            ...dot,
            selected: !!(dot.location && symptomsLocationSet.has(dot.location)),
          })),
        (dots) =>
          dots.map((dot) => ({
            ...dot,
            selected: !!(dot.location && symptomsLocationSet.has(dot.location)),
          })),
        mediaMap,
      );
    }
  }, [
    editAppointmentData?.getFHIRAppointmentByCodexId.appointment,
    updateMediaAndDotsWithoutSync,
  ]);

  if (!locale || localeLoading || editAppointmentLoading || !genericActions) {
    return <Loader />;
  }

  return (
    <div className="px-[60px] py-[30px] bg-white rounded-md">
      <div className="flex flex-row w-full desktop:w-auto justify-between items-center">
        <h4 className="text-left text-h4 text-charcoal-gray font-semibold">
          {locale?.whereExperiencingSymptoms}
        </h4>
        <div className="text-sm font-semibold text-alert-negative">
          *Required
        </div>
      </div>

      <p className="text-base font-medium text-med-gray-3 my-4">
        {locale?.whereExperiencingSymptomsPageDescriptions}
      </p>

      <hr />

      <UploadInputComponent />
      <ModalUploadPhoto
        isOpen={isShowAddsPhotoModal}
        onClose={addPhotoModalOnClose}
        bodySymptom={bodySymptomsPlacerLabel}
        qrCode={`${baseUrl}${QR_ACTION}?action=${
          ScanActionEnum.SCAN_APPOINTMENTS_PHOTO
        }&token=${token}&authToken=${authToken}&bodySite=${encodeURI(
          bodySymptomsPlacerLabel,
        )}`}
        onShowLibraryModal={onShowLibraryModal}
        onUploadPhoto={onUploadPhoto}
      />
      <ModalPhotoLibrary
        isOpen={isShowLibraryModal}
        onClose={onCloseLibrary}
        bodySymptom={bodySymptomsPlacerLabel}
        bodyDescriptions={
          mediaPerBodyLocation.get(String(selectedDot?.id))?.description
        }
        onConfirm={onUploadByMediaLibrary}
      />
      <ModalSymptomDetails
        mediaPerBodyLocation={mediaPerBodyLocation}
        dot={selectedDot}
        setDot={setDot}
        qrCode={`${baseUrl}${QR_ACTION}?action=${
          ScanActionEnum.SCAN_APPOINTMENTS_PHOTO
        }&token=${token}&authToken=${authToken}&bodySite=${encodeURI(
          bodySymptomsPlacerLabel,
        )}`}
        onShowLibraryModal={onShowLibraryModal}
        isShowLibraryModal={isShowLibraryModal}
        handleDeleteSymptom={handleDeleteSymptom}
      />
      <div className="mb-4 desktop:mb-0 w-full">
        <HeadBodySection
          backDots={backDots}
          frontDots={frontDots}
          handleBodyParsing={handleBodyParsing}
          mediaPerBodyLocation={mediaPerBodyLocation}
        />
        <FrontBodySection
          dots={frontDots}
          handleBodyParsing={handleBodyParsing}
          mediaPerBodyLocation={mediaPerBodyLocation}
        />
        <BackBodySection
          dots={backDots}
          handleBodyParsing={handleBodyParsing}
          mediaPerBodyLocation={mediaPerBodyLocation}
        />
      </div>

      <ButtonComponent
        className="mt-4"
        type="filled"
        onClick={() => handleNext(mediaPerBodyLocation)}
      >
        {genericActions[genericActionsIds.CONTINUE].continue}
      </ButtonComponent>
    </div>
  );
};

export default SymptomsPlacement;
