import React, { useMemo } from 'react';
import { ReactComponent as Arrow } from 'assets/icons/blue-arrow-left.svg';
import ReactPaginate from 'react-paginate';
import { createSearchParams, useNavigate } from 'react-router-dom';

interface IPaginateBlockProps {
  itemsPerPage: number;
  totalItems: number;
  setNewOffset: (offset: number) => void;
  offsetParams?: string;
}

const PaginateBlock: React.FC<IPaginateBlockProps> = ({
  itemsPerPage,
  totalItems,
  setNewOffset,
  offsetParams = 'offset',
}) => {
  const navigate = useNavigate();
  const pageCount = useMemo(
    () => Math.ceil(totalItems / itemsPerPage),
    [totalItems, itemsPerPage],
  );

  const handlePageClick = (event: { selected: number }) => {
    const newOffset = (event.selected * itemsPerPage) % totalItems;
    setNewOffset(newOffset);
    navigate({
      search: createSearchParams({
        [offsetParams]: newOffset.toString(),
      }).toString(),
    });
  };

  return (
    <>
      <ReactPaginate
        breakLabel="..."
        nextLabel={<Arrow className="rotate-180 fill-med-gray-3 h-[12px]" />}
        onPageChange={handlePageClick}
        marginPagesDisplayed={3}
        pageCount={pageCount}
        previousLabel={<Arrow className="fill-med-gray-3 h-[12px]" />}
        previousClassName="flex items-center justify-center hover:bg-hover-blue"
        nextClassName="flex items-center justify-center hover:bg-hover-blue"
        breakLinkClassName="px-3 py-1.5"
        nextLinkClassName="px-3 py-1.5"
        previousLinkClassName="px-3 py-1.5"
        renderOnZeroPageCount={null}
        disableInitialCallback
        className="flex border border-gray-opacity-15 rounded-md w-fit overflow-hidden text-sm"
        activeLinkClassName="!text-clc-blue"
        breakClassName="flex items-end justify-center underline text-med-gray-3 font-bold"
        pageLinkClassName="px-3 py-1.5 font-bold underline text-med-gray-3 flex items-center justify-center border border-transparent border-x-gray-opacity-15 hover:bg-hover-blue"
      />
    </>
  );
};

export default PaginateBlock;
