import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
import { ReactComponent as Phone } from 'assets/icons/upload.svg';
import { ReactComponent as Picture } from 'assets/icons/mediaLibraryIcon.svg';
import { ReactComponent as PencilIcon } from 'assets/icons/pencilIcon.svg';
import Modal from 'components/modal/modalComponent';
import { useGetGenericActions } from '../../../hooks/useGetGenericActions';
import { genericActionsIds, pageIds } from '../../../utilities/constants';
import { ModalButtonProps } from '../../../components/modal/modal.interfaces';
import { useGetPage } from '../../../hooks/useGetPage';
import { Dot, ImageObject } from './BodySymtomsLocation/interfaces';
import InputComponent from '../../../components/inputComponent';
import { useUploadFileToStorageMutation } from '../../../graphql/generated/hasura';
import { twMerge } from 'tailwind-merge';
import ButtonComponent from '../../../components/button/buttonComponent';
import CroppedImageComponent from '../appointments/CroppedImageComponent';
import QRCode from 'react-qr-code';
import { useDropzone } from 'react-dropzone';

interface IModalSymptomDetails {
  dot: Dot | null;
  setDot: React.Dispatch<React.SetStateAction<Dot | null>>;
  handleDeleteSymptom: () => void;
  onShowLibraryModal?: () => void;
  isShowLibraryModal?: boolean;
  qrCode?: string;
  mediaPerBodyLocation: Map<string, string | ImageObject>;
}

const initial = {
  image: '',
  mediaId: '',
};

const ModalSymptomDetails: React.FC<IModalSymptomDetails> = ({
  dot,
  mediaPerBodyLocation,
  setDot,
  onShowLibraryModal = () => ({}),
  isShowLibraryModal = false,
  handleDeleteSymptom,
  qrCode,
}) => {
  const [formModal, setForm] = useState<ImageObject>(initial);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [uploadFile, { loading: isMediaSaving }] =
    useUploadFileToStorageMutation({});

  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.EDIT_SYMPTOM_MODAL,
  });

  const { data, loading: localeLoading } = useGetPage({
    pageId: pageIds.APPOINTMENT_PREWORK,
    locale: 'en',
  });

  const onClose = () => {
    setDot(null);
    setIsEdit(false);
  };

  const onEdit = () => setIsEdit(true);

  const onLibraryClick = () => {
    onShowLibraryModal();
    setIsEdit(false);
  };

  const handleOnTextAreaChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      const description = event.target.value;
      setForm((prev) => ({ ...prev, description }));
    },
    [],
  );

  const handleImageUpload = useCallback(
    async (files: File[]) => {
      const file = files[0];
      const buffer = await file.arrayBuffer();
      const result = await uploadFile({
        variables: {
          file: {
            mimetype: file.type,
            originalname: file.name,
            buffer: Array.from(new Uint8Array(buffer)),
          },
        },
      });
      const url = result.data?.UploadFileToStorage?.file?.url;
      const mediaId = result.data?.UploadFileToStorage?.file?.fileId;

      if (!url || !mediaId) return;
      setForm((prev) => ({ ...prev, image: url, mediaId }));
    },
    [uploadFile],
  );

  const onDeleteFile = () => {
    setForm((prev) => ({ ...prev, image: '', mediaId: '' }));
  };

  const handleUpdate = () => {
    mediaPerBodyLocation.set(String(dot?.id), formModal);
    return onClose();
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop: handleImageUpload,
    noClick: true,
  });

  const { data: genericAction, loading: genericActionLoading } =
    useGetGenericActions({
      locale: 'en',
      genericActionId: [
        genericActionsIds.CANCEL,
        genericActionsIds.EDIT,
        genericActionsIds.CLOSE,
        genericActionsIds.SAVE,
      ],
    });

  const Buttons: ModalButtonProps[] = [
    {
      label: !isEdit
        ? genericAction?.[genericActionsIds.CLOSE]?.close
        : genericAction?.[genericActionsIds.SAVE]?.save,
      onClick: !isEdit ? onClose : handleUpdate,
      disabled: !formModal?.image,
      containerClassName: 'font-bold',
      className: '!text-sm',
    },
    {
      label: !isEdit
        ? genericAction?.[genericActionsIds.EDIT]?.edit
        : genericAction?.[genericActionsIds.CANCEL]?.cancel,
      onClick: !isEdit ? onEdit : onClose,
      type: 'underline',
      containerClassName: 'font-bold',
      Icon: !isEdit ? PencilIcon : undefined,
      iconPosition: 'left',
      className: '!text-sm',
    },
    ...(!isEdit
      ? [
          {
            label: locale?.deleteDetails,
            type: 'underline',
            Icon: TrashIcon,
            floatLeft: true,
            fill: 'fill-clc-blue',
            iconPosition: 'left' as const,
            containerClassName: 'font-bold',
            className: 'ml-auto !text-sm',
            onClick: handleDeleteSymptom,
          },
        ]
      : []),
  ];

  useEffect(() => {
    setForm(mediaPerBodyLocation.get(String(dot?.id)) as ImageObject);
  }, [dot, mediaPerBodyLocation]);

  if (
    (loading && !locale) ||
    (genericActionLoading && !genericAction) ||
    (!data && localeLoading) ||
    !dot ||
    isShowLibraryModal
  ) {
    return null;
  }

  return (
    <Modal
      isOpen={true}
      onClose={onClose}
      title={`${isEdit ? 'Edit' : dot.location} details`}
      buttons={Buttons}
      titleCustomClass="normal-case desktop:!text-h3"
      modalDesktopWidth="desktop:max-w-[720px]"
      customContentContainerClass="py-5"
    >
      <div className="flex flex-col gap-[30px] desktop:py-2">
        {!isEdit ? (
          <div className={twMerge('gap-6', isMediaSaving && 'opacity-50')}>
            <div className="max-w-[500px] mx-auto rounded-lg overflow-hidden mb-2.5">
              <img
                src={formModal?.image}
                alt="scan"
                className="w-full h-full object-cover"
              />
            </div>

            <div className="text-base text-med-gray-3 font-medium">
              <p>{formModal?.description}</p>
            </div>
          </div>
        ) : (
          <div className="w-full">
            <h2 className="text-h1 font-medium">{dot.location}</h2>

            <div className="w-full">
              {formModal?.image ? (
                <CroppedImageComponent
                  croppedImage={formModal?.image}
                  setFile={onDeleteFile}
                />
              ) : (
                <>
                  <div className="text-base font-medium text-med-gray-3 my-2.5">
                    <p>{data?.addProblemAreaPhotoDescription}</p>
                  </div>
                  <div className="grid grid-cols-12 gap-6">
                    <QRCode
                      size={240}
                      className="self-center my-[18px] col-span-5"
                      value={String(qrCode)}
                    />
                    <div className="relative flex items-center justify-center col-span-1 h-full">
                      <span className="text-gray-500 text-sm">or</span>
                      <div className="absolute inset-0 flex flex-col py-4 items-center">
                        <div className="h-[45%] w-px bg-gray-500" />
                        <div className="h-[45%] w-px bg-gray-500 mt-auto" />
                      </div>
                    </div>
                    <div className="col-span-6 flex flex-col items-center justify-center gap-3">
                      <p className="text-base font-medium text-med-gray-3">
                        {data?.youCanUploadPhotosFromDeviceOrLibrary}
                      </p>
                      <ButtonComponent
                        type="outlined-transparent"
                        className="desktop:w-full w-full whitespace-nowrap font-bold !text-sm"
                        containerClassName="w-full"
                        iconPosition="left"
                        onClick={open}
                        Icon={Phone}
                      >
                        {locale.uploadPhoto || 'Upload photo From device'}
                      </ButtonComponent>

                      <ButtonComponent
                        type="outlined-transparent"
                        className="desktop:w-full w-full whitespace-nowrap font-bold !text-sm"
                        containerClassName="w-full"
                        iconPosition="left"
                        Icon={Picture}
                        onClick={onLibraryClick}
                      >
                        {locale.selectLibraryBtn}
                      </ButtonComponent>
                    </div>
                  </div>
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                  </div>
                </>
              )}
            </div>

            <div className="mt-4 desktop:mt-0">
              <InputComponent
                value={formModal?.description}
                type="text-area"
                maxLengthValue={500}
                placeholder={data?.enterIssue}
                textAreaProps={{
                  height: 'h-52',
                  textAreaTitle: locale?.descriptionLabel,
                  containerHeight: 'h-full',
                  textAreaTitleClassName: 'font-semibold text-neutral-800',
                  onTextAreaChange: handleOnTextAreaChange,
                  name: 'appointmentNotes',
                }}
              />
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default ModalSymptomDetails;
