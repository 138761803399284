import React, { FC, useState } from 'react';
import ReactCrop, { Crop, PixelCrop } from 'react-image-crop';

import ButtonComponent from '../../../components/button/buttonComponent';

import { ReactComponent as PlusIcon } from 'assets/icons/addIcon.svg';
import { ReactComponent as MinusIcon } from 'assets/icons/minusIcon-v2.svg';

interface IPhotoCropper {
  setCrop: (crop: Crop) => void;
  setCompletedCrop: (crop: Crop) => void;
  onImageLoad: (e: React.SyntheticEvent<HTMLImageElement>) => void;
  cropImgRef: React.RefObject<HTMLImageElement>;
  crop?: Crop;
  cropSrc?: string | null;
}

const PhotoCropper: FC<IPhotoCropper> = ({
  crop,
  setCrop,
  setCompletedCrop,
  cropSrc,
  onImageLoad,
  cropImgRef,
}) => {
  const [scale, setScale] = useState<number>(1);

  const increaseSize = () => {
    setScale((prevScale) => Math.min(prevScale + 0.1, 3));
  };

  const decreaseSize = () => {
    setScale((prevScale) => Math.max(prevScale - 0.1, 0.5));
  };

  return (
    <div
      className="flex flex-col gap-3 justify-center"
      data-testid="photo-coper"
    >
      <ReactCrop
        className="w-fit h-auto mx-auto"
        crop={crop}
        keepSelection={true}
        onChange={(_: PixelCrop, percentCrop: Crop) => setCrop(percentCrop)}
        onComplete={(_: PixelCrop, percentCrop: Crop) =>
          setCompletedCrop(percentCrop)
        }
      >
        <img
          className="w-full"
          src={cropSrc || ''}
          onLoad={onImageLoad}
          ref={cropImgRef}
          alt="Selected image"
          style={{
            maxHeight: 630,
            transform: `scale(${scale})`,
            transition: 'transform 0.2s ease-in-out',
          }}
        />
      </ReactCrop>

      <div className="flex absolute items-center justify-center space-x-0 bottom-auto flex-col space-y-4 right-14">
        <ButtonComponent
          Icon={PlusIcon}
          type="icon"
          paddingX="px-2.5"
          paddingY="py-2.5"
          className="text-med-gray-3"
          onClick={increaseSize}
        />
        <ButtonComponent
          Icon={MinusIcon}
          type="icon"
          paddingX="px-2.5"
          paddingY="py-2.5"
          className="text-med-gray-3"
          onClick={decreaseSize}
        />
      </div>
    </div>
  );
};

export default PhotoCropper;
