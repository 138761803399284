import { useCallback, useEffect } from 'react';
import { useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  AUTH_LOGIN,
  TEST_KITS_MANAGEMENT_BARCODE_CAMERA,
  MY_SKIN_FACE_CAMERA,
  MY_APPOINTMENTS_SCHEDULE_AN_APPOINTMENT,
  FACE_PHOTO,
  APPOINTMENT_SUBMIT_PHOTO,
} from 'utilities/routes';
import { LOCAL_STORAGE_USER_FROM_QR } from 'utilities/constants';
import { useValidateCustomToken } from '../../firebase/hooks';
import StorageService from '../../utilities/storageService';
import { ScanActionEnum } from './actionName';

const qrStorage = new StorageService(LOCAL_STORAGE_USER_FROM_QR);

const actionFabric = {
  [ScanActionEnum.SCAN_FACE]: MY_SKIN_FACE_CAMERA,
  [ScanActionEnum.SCAN_BARCODE]: TEST_KITS_MANAGEMENT_BARCODE_CAMERA,
  [ScanActionEnum.SCAN_APPOINTMENTS_PAGE]:
    MY_APPOINTMENTS_SCHEDULE_AN_APPOINTMENT,
  [ScanActionEnum.SCAN_APPOINTMENTS_PHOTO]: `${FACE_PHOTO}${APPOINTMENT_SUBMIT_PHOTO}`,
};

export const QrAction = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { handleValidateCustomToken } = useValidateCustomToken();
  const queryParams = new URLSearchParams(location.search);
  const action = queryParams.get('action') as ScanActionEnum;
  const token = queryParams.get('token');
  const actionChecked = useRef<boolean>(false);

  const getAction = useCallback(() => {
    if (!action) {
      return;
    }
    const path = actionFabric[action];
    if (path) {
      return navigate(`${path}${location.search}`, {
        state: { token },
        replace: true,
      });
    }
    return navigate(AUTH_LOGIN, { replace: true });
  }, [action, location, navigate, token]);

  const checkActions = useCallback(async () => {
    if (!token || !action) {
      return qrStorage.setData('false');
    }
    try {
      await handleValidateCustomToken(token);
      qrStorage.setData('true');
      return getAction();
    } catch (error) {
      console.error('There was an error when try to validate custom token.');
      qrStorage.setData('false');
      navigate(AUTH_LOGIN, { replace: true });
    }
  }, [action, getAction, handleValidateCustomToken, navigate, token]);

  useEffect(() => {
    if (!actionChecked.current) {
      checkActions();
      actionChecked.current = true;
    }
  }, [checkActions]);

  return null;
};
