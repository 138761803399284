import React, { useState } from 'react';
import Collapse from '../../components/Collapse';
import { BlockCollapseProps } from '../../interface';
import InputComponent from 'components/inputComponent';
import { componentIds } from 'utilities/constants';
import { useGetComponent } from 'hooks/useGetComponent';

const MAX_REASON_TEXT = 500;

interface IReasonBlockProps extends BlockCollapseProps {
  initialState: string;
}

const ReasonBlock: React.FC<IReasonBlockProps> = ({
  id,
  handleContinue,
  handleEdit,
  currentCollapsesOpen,
  initialState = '',
}) => {
  const { data: localeComponents } = useGetComponent({
    componentId: componentIds.APPOINTMENT_PREWORK,
    locale: 'en',
  });

  const [subjectOfAppointment, setSubjectOfAppointment] =
    useState<string>(initialState);

  const onSubjectOfAppointmentChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => setSubjectOfAppointment(e.target.value);

  return (
    <Collapse
      savedData={subjectOfAppointment}
      title={localeComponents?.symptoms?.reasonTitle}
      currentCollapsesOpen={currentCollapsesOpen}
      id={id}
      handleContinue={(id) => handleContinue(id, subjectOfAppointment)}
      handleEdit={handleEdit}
      disabled={!subjectOfAppointment}
      lineClassName="my-2.5"
    >
      <div className="flex flex-col gap-1 mt-3">
        <div className="flex flex-col items-start">
          <span className="text-neutral-800 font-semibold text-base">
            {localeComponents.symptoms.reasonTitle}
          </span>
        </div>
        <div className="w-full">
          <InputComponent
            type="text"
            value={subjectOfAppointment}
            testID="subject-of-appointment-input"
            customInputClassname="!py-3 !px-4 leading-[22px] !font-medium text-neutral-800"
            placeholder={localeComponents?.symptoms?.reasonPlaceholder}
            onChange={onSubjectOfAppointmentChange}
            maxLengthValue={MAX_REASON_TEXT}
          />
        </div>
      </div>
    </Collapse>
  );
};

export default ReasonBlock;
