import React, { useCallback, useMemo, useState } from 'react';
import { ReactComponent as Cancel } from 'assets/icons/iconClose.svg';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';

import { UpcomingAppoinment } from '../../interfaces';
import { createSearchParams, useNavigate } from 'react-router-dom';
import {
  MY_APPOINTMENTS_CHAT_CONSULTATION,
  MY_APPOINTMENTS_RESCHEDULE_AN_APPOINTMENT,
  MY_APPOINTMENTS_VIDEO_CONSULTATION,
  PROVIDERS_PROVIDER_BIO,
} from 'utilities/routes';
import ButtonComponent from 'components/button/buttonComponent';
import { getStatusClassName } from './helper/getStatusClassName';
import { twMerge } from 'tailwind-merge';
import DateAppointment from './DateAppointment';
import StartTimeAndTypeAppointment from './StartTimeAndTypeappointment';
import ReasonAndPaymentBlock from './ReasonAndPaymentBlock';
import { useIsJoinable } from 'hooks/useIsJoinable';
import { AppointmentTypeEnum } from 'graphql/generated/hasura';
import { useMyAppointmentsLocale } from '../../pages/MyAppointments';
import OptionsModal from 'components/optionsModal/OptionsModal';
import { CANCEL_APPOINTMENT_ID } from './helper/const';
import IconRow from './IconRow';
import { getAppointmentDate } from 'utilities/dateHelper';

interface IUpcomingAppointmentCardProps {
  appointment: UpcomingAppoinment;
  locale: Record<string, string>;
}

const UpcomingAppointmentCard: React.FC<IUpcomingAppointmentCardProps> = ({
  appointment,
}) => {
  const navigate = useNavigate();

  const {
    providerId,
    name,
    status,
    date,
    appointmentType,
    end,
    subjectOfAppointment,
    appointmentId,
    patientId,
  } = appointment;
  const [opened, setOpened] = useState<boolean>(false);
  const { locale } = useMyAppointmentsLocale();

  const options = useMemo(
    () => [
      {
        Icon: CalendarIcon,
        label: locale?.reschedule,
        onClick: () =>
          navigate(
            `${MY_APPOINTMENTS_RESCHEDULE_AN_APPOINTMENT}/${appointmentId}`,
          ),
      },
      {
        Icon: Cancel,
        label: locale?.cancelAppointment,
        iconClassName: 'stroke-clc-blue stroke-2',
        onClick: () =>
          navigate({
            search: createSearchParams({
              [CANCEL_APPOINTMENT_ID]: appointmentId,
            }).toString(),
          }),
      },
    ],
    [appointmentId, locale, navigate],
  );

  const handleJoinAppointment = () => {
    const base =
      appointmentType === AppointmentTypeEnum.Chat
        ? MY_APPOINTMENTS_CHAT_CONSULTATION
        : MY_APPOINTMENTS_VIDEO_CONSULTATION;
    navigate(`${base}&appointment-id=${appointmentId}`, {
      state: { providerId, patientId },
    });
  };

  const statusClassName = getStatusClassName(status);

  const isJoinable = useIsJoinable({ startDate: date, endDate: end, status });

  const handleProviderClick = useCallback(() => {
    return navigate(PROVIDERS_PROVIDER_BIO, {
      state: { providerId: providerId },
    });
  }, [providerId, navigate]);

  return (
    <>
      <div className="max-desktop:hidden">
        <div className="flex items-center px-8 py-5 border border-transparent border-b-gray-opacity-15 bg-neutral-100 rounded-tr-xl rounded-tl-xl">
          <span className="text-title font-semibold text-neutral-800">
            Appointment with
          </span>
          <ButtonComponent
            type="underline"
            onClick={handleProviderClick}
            noUpperCase
            className="ml-1 !text-clc-blue !text-h5"
          >
            {name}
          </ButtonComponent>
          <span className={twMerge('ml-4 capitalize', statusClassName)}>
            {status}
          </span>

          <div className="ml-auto">
            <OptionsModal
              opened={opened}
              setOpened={setOpened}
              options={options}
              className="!bg-transparent !border-transparent -mr-5"
              optionsMenuClassName="rounded-md w-[16rem] !border-transparent -left-[14rem]"
              optionsClassName="uppercase text-clc-blue text-sm font-bold"
            />
          </div>
        </div>
        <div className="grid grid-cols-12 gap-6 px-8 py-5 border-b-gray-opacity-15 bg-neutral-100 rounded-br-xl rounded-bl-xl">
          <div className="col-span-2">
            <DateAppointment startDate={date} />
          </div>
          <div className="col-span-3 flex justify-start">
            <StartTimeAndTypeAppointment
              type={appointmentType}
              start={date}
              end={String(end)}
            />
          </div>
          <div className="col-span-4 flex justify-start">
            <ReasonAndPaymentBlock
              subjectOfAppointment={subjectOfAppointment}
              appointmentId={appointmentId}
            />
          </div>
          <div className="col-span-3 flex items-center justify-end">
            {isJoinable && (
              <ButtonComponent
                className="!text-sm rounded max-w-[6.75rem] !px-3 ml-auto block"
                desktopFullWidth
                onClick={handleJoinAppointment}
              >
                {locale.joinNow}
              </ButtonComponent>
            )}
          </div>
        </div>
      </div>

      <div className="flex flex-col desktop:hidden">
        <div className="p-5 border border-transparent border-b-gray-opacity-15 bg-neutral-100 rounded-tr-xl rounded-tl-xl relative">
          <div className={twMerge('capitalize w-max mb-4', statusClassName)}>
            {status}
          </div>
          <div className="absolute top-2 right-5">
            <OptionsModal
              opened={opened}
              setOpened={setOpened}
              options={options}
              className="!bg-transparent !border-transparent -mr-5"
              optionsMenuClassName="rounded-md w-[16rem] !border-transparent -left-[14rem]"
              optionsClassName="uppercase text-clc-blue text-sm font-bold"
              btnTitleClassName="hidden"
            />
          </div>
          <div className="flex items-center">
            <span className="text-title font-semibold text-neutral-800">
              Appointment with
            </span>
            <ButtonComponent
              type="underline"
              onClick={handleProviderClick}
              noUpperCase
              className="ml-1 !w-auto !text-clc-blue !text-h5"
            >
              {name}
            </ButtonComponent>
          </div>
        </div>
        <div className="p-5 border-b-gray-opacity-15 bg-neutral-100 flex flex-col gap-2 rounded-br-xl rounded-bl-xl">
          <IconRow
            icon={<CalendarIcon className="fill-neutral-800" />}
            text={getAppointmentDate(appointment.date)}
          />

          <StartTimeAndTypeAppointment
            type={appointmentType}
            start={date}
            end={String(end)}
          />
          <ReasonAndPaymentBlock
            subjectOfAppointment={subjectOfAppointment}
            appointmentId={appointmentId}
          />

          {isJoinable && (
            <ButtonComponent
              className="!text-sm rounded max-w-[6.75rem] !px-3 ml-auto block"
              desktopFullWidth
              onClick={handleJoinAppointment}
            >
              {locale.joinNow}
            </ButtonComponent>
          )}
        </div>
      </div>
    </>
  );
};

export default UpcomingAppointmentCard;
