import React from 'react';
import IconRow from './IconRow';
import { ReactComponent as Pay } from 'assets/icons/appointmentsIcons/dollar.svg';
import { ReactComponent as Reason } from 'assets/icons/appointmentsIcons/reason-goal.svg';

interface IReasonAndPaymentBlockProps {
  subjectOfAppointment?: string;
  appointmentId: string;
}

const ReasonAndPaymentBlock: React.FC<IReasonAndPaymentBlockProps> = ({
  subjectOfAppointment,
  appointmentId,
}) => {
  return (
    <div className="flex flex-col justify-center gap-2 w-full">
      <IconRow
        icon={<Reason className="min-w-[1.25rem] ill-neutral-800" />}
        tooltipId={appointmentId}
        text={subjectOfAppointment}
      />
      <IconRow
        icon={<Pay className="fill-neutral-800" />}
        text={'Paid ($60)'}
      />
    </div>
  );
};

export default ReasonAndPaymentBlock;
