import React from 'react';
import AppointmentTypeSelect from './AppointmentTypeSelect';
import { IServiceTypes } from '../interface';

interface IAppointmentTypeSelectWrapperProps {
  types: IServiceTypes[];
  selectedType: IServiceTypes | null;
  isDisabled?: boolean;
  onSelect: (type: IServiceTypes) => void;
}

const AppointmentTypeSelectWrapper: React.FC<
  IAppointmentTypeSelectWrapperProps
> = ({ types, onSelect, selectedType }) => {
  return (
    <div className="desktop:flex-wrap max-desktop:flex-col flex gap-2">
      {types.map((type) => (
        <AppointmentTypeSelect
          key={type.id}
          isSelected={selectedType?.id === type.id}
          serviceOfferingType={type}
          onSelect={onSelect}
        />
      ))}
    </div>
  );
};

export default AppointmentTypeSelectWrapper;
