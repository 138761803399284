import React, { useMemo, useState } from 'react';
import { BlockCollapseProps } from '../../interface';
import { useGetPage } from 'hooks/useGetPage';
import { pageIds } from 'utilities/constants';
import Collapse from '../../components/Collapse';
import InputComponent from 'components/inputComponent';

const MAX_OTHER_TEXT = 200;

interface ISymptomsBlockProps extends BlockCollapseProps {
  initialState: string[];
}

const SymptomsBlock: React.FC<ISymptomsBlockProps> = ({
  id,
  handleContinue,
  handleEdit,
  currentCollapsesOpen,
  initialState = [],
}) => {
  const [symptoms, setSymptoms] = useState<string[]>(initialState);
  const { data: locale, loading: localeLoading } = useGetPage({
    pageId: pageIds.APPOINTMENT_PREWORK,
    locale: 'en',
  });

  const [isOtherSelected, setIsOtherSelected] = useState(false);
  const [isNoneSelected, setIsNoneSelected] = useState(false);
  const symptomsList: string[] = useMemo(
    () => [
      locale?.symptoms.acne,
      locale?.symptoms.ageSpots,
      locale?.symptoms.darkEyeCircles,
      locale?.symptoms.dryness,
      locale?.symptoms.eyePuffiness,
      locale?.symptoms.oilliness,
      locale?.symptoms.redness,
      locale?.symptoms.sensitiveSkin,
      locale?.symptoms.wrinkles,
    ],
    [locale],
  );
  const batchSize = 6;
  const isAllSelected = useMemo<boolean>(
    () => locale && symptomsList.every((symptom) => symptoms.includes(symptom)),
    [symptoms, locale, symptomsList],
  );
  const otherIndex = useMemo<number>(
    () => symptoms.findIndex((symptom) => !symptomsList.includes(symptom)),
    [symptoms, symptomsList],
  );

  const handleSymptomChange = (symptom: string): (() => void) => {
    return () => {
      const index = symptoms.indexOf(symptom);
      if (index === -1) {
        setIsNoneSelected(false);
        setSymptoms([...symptoms, symptom]);
      } else {
        const newSymptoms = [...symptoms];
        newSymptoms.splice(index, 1);
        setSymptoms(newSymptoms);
      }
    };
  };

  const onOtherChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    if (otherIndex !== -1) {
      const newSymptoms = [...symptoms];
      newSymptoms[otherIndex] = newValue;
      return setSymptoms(newSymptoms);
    }
    return setSymptoms([...symptoms, newValue]);
  };

  const onOtherChecked = () => {
    if (otherIndex !== -1) {
      const newSymptoms = [...symptoms];
      newSymptoms.splice(otherIndex, 1);
      setSymptoms(newSymptoms);
    }
    setIsNoneSelected(false);
    setIsOtherSelected((bool) => !bool);
  };

  const onAllClicked = () => {
    const newSymptoms = [...symptomsList];
    if (otherIndex !== -1) {
      newSymptoms.push(symptoms[otherIndex]);
    }
    setIsNoneSelected(false);
    setSymptoms(newSymptoms);
  };
  const onNoneClicked = () => {
    setIsNoneSelected(true);
    setIsOtherSelected(false);
    setSymptoms([locale.symptoms.none]);
  };

  const saveData = useMemo(() => {
    let data = [...symptoms];
    if (symptoms.length > 1 && symptoms.includes(locale.symptoms.none)) {
      data = data.filter((symptoms) => symptoms !== locale.symptoms.none);
    }
    return data;
  }, [locale.symptoms.none, symptoms]);

  if (!locale || localeLoading) {
    return null;
  }

  return (
    <Collapse
      savedData={saveData.join(', ')}
      title={locale.symptomTypes}
      currentCollapsesOpen={currentCollapsesOpen}
      id={id}
      handleContinue={(id) => handleContinue(id, saveData)}
      handleEdit={handleEdit}
      disabled={!symptoms.length || (isOtherSelected && !symptoms[otherIndex])}
    >
      <div className="flex flex-col">
        <InputComponent
          type="radio"
          testID="none-symptoms"
          radioInputProps={{
            radioLabelTextWeight: 'font-semibold text-med-gray-3 text-sm',
            radioError: null,
            radioInputValue: locale.symptoms.none,
            radioInputLabel: locale.symptoms.none,
            radioInputCheckedValue: isNoneSelected
              ? locale.symptoms.none
              : undefined,
            onRadioClick: onNoneClicked,
          }}
        />
        <InputComponent
          type="radio"
          testID="all-symptoms"
          radioInputProps={{
            radioLabelTextWeight: 'font-semibold text-med-gray-3 text-sm',
            radioError: null,
            radioInputValue: locale.symptoms.all,
            radioInputLabel: locale.symptoms.all,
            radioInputCheckedValue: isAllSelected
              ? locale.symptoms.all
              : undefined,
            onRadioClick: onAllClicked,
          }}
        />
        <div className="flex flex-col desktop:flex-row mt-3 items-stretch desktop:items-start gap-2 desktop:gap-36">
          <div className="flex flex-col gap-2">
            {symptomsList.slice(0, batchSize).map((symptom) => (
              <div key={symptom} className="flex flex-row items-center gap-2">
                <InputComponent
                  type="checkbox"
                  name={symptom}
                  testID={`symptom-${symptom}`}
                  checkboxProps={{
                    checkboxLabel: symptom,
                    checkboxLabelClassName:
                      'font-semibold text-med-gray-3 text-sm',
                    checked: symptoms.includes(symptom),
                    onCheckboxChange: handleSymptomChange(symptom),
                  }}
                />
              </div>
            ))}
          </div>
          <div className="flex flex-col gap-2 grow">
            {symptomsList.slice(batchSize).map((symptom) => (
              <div key={symptom} className="flex flex-row items-center gap-2">
                <InputComponent
                  type="checkbox"
                  testID={`symptom-${symptom}`}
                  name={symptom}
                  checkboxProps={{
                    checkboxLabel: symptom,
                    checkboxLabelClassName:
                      'font-semibold text-med-gray-3 text-sm',
                    checked: symptoms.includes(symptom),
                    onCheckboxChange: handleSymptomChange(symptom),
                  }}
                />
              </div>
            ))}
            <div className="flex flex-row items-center gap-2">
              <InputComponent
                type="checkbox"
                testID="other-symptoms-checkbox"
                name="other-symptoms-checkbox"
                checkboxProps={{
                  checked: isOtherSelected,
                  onCheckboxChange: onOtherChecked,
                  checkboxLabel: locale.symptoms.other,
                  checkboxLabelClassName:
                    'font-semibold text-med-gray-3 text-sm',
                }}
              />
            </div>
            <div className="flex flex-row items-center gap-2">
              {isOtherSelected && (
                <div className="flex flex-col gap-1 grow ml-8">
                  <InputComponent
                    type="text"
                    testID="other-symptoms-input"
                    value={otherIndex !== -1 ? symptoms[otherIndex] : ''}
                    onChange={onOtherChange}
                    maxLengthValue={MAX_OTHER_TEXT}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Collapse>
  );
};

export default SymptomsBlock;
