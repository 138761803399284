import React from 'react';
import LocationTab, { ITab } from './LocationTab';
import { useLocation } from 'react-router-dom';

interface ILocationTabs {
  tabs: ITab[];
}

const getLocation = (pathname: string) => {
  const pathArray = pathname.split('/');
  return pathArray[pathArray.length - 1];
};

const LocationTabs: React.FC<ILocationTabs> = ({ tabs = [] }) => {
  const { pathname } = useLocation();

  return (
    <div className="desktop:flex gap-3 items-center mb-6">
      {tabs.map((tab, index) => (
        <LocationTab
          {...tab}
          number={index + 1}
          key={tab.location}
          isActive={getLocation(pathname) === tab.location}
        />
      ))}
    </div>
  );
};

export default LocationTabs;
