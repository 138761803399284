import React, { useCallback, useMemo, useRef } from 'react';
import { ReactComponent as Close } from 'assets/icons/close.svg';
import { createPortal } from 'react-dom';
import useOutsideClick from 'components/datePicker/hooks/useOutsideClick';
import ButtonComponent from 'components/button/buttonComponent';
import CustomRangeDatepicker from 'components/react-datapicker/CustomRangeDatepicker';
import Select, {
  PropsValue,
  components,
  OptionProps,
  SingleValueProps,
} from 'react-select';
import {
  DropdownIndicatorSingle,
  ISingleValueLabel,
  NullIndicatorSeparator,
} from 'components/dropdown/ReactSelectComponents';
import { AppointmentTypeEnum } from 'graphql/generated/hasura';
import {
  appointmentRenderInfo,
  HistoricAppointmentsFilter,
} from '../../interfaces';
import { historyStatuses } from '../AppointmentHistory';
import { FILTER_BLOCK_ID } from './tableKeyMap';
import { useMyAppointmentsLocale } from '../../pages/MyAppointments';
import { capitalizeFirstLetter } from '../../../../utilities/functions';
import moment from 'moment';

const SingleValue = (props: SingleValueProps<ISingleValueLabel>) => {
  return (
    <components.SingleValue
      {...props}
      innerProps={{
        className: 'text-sm !text-neutral-800',
      }}
    >
      {props.children}
    </components.SingleValue>
  );
};

const Option = (props: OptionProps<ISingleValueLabel>) => {
  return (
    <components.Option {...props} className="!text-sm !text-neutral-800">
      {props.children}
    </components.Option>
  );
};

interface IPastAppointmentFilter {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleFilterChange: (form: IFilterForm) => void;
  defaultFilter: HistoricAppointmentsFilter;
}

export interface IFilterForm {
  from?: string;
  to?: string;
  type?: PropsValue<ISingleValueLabel>;
  appointmentStatus?: PropsValue<ISingleValueLabel>;
}

const PastAppointmentFilter: React.FC<IPastAppointmentFilter> = ({
  open,
  setOpen,
  handleFilterChange,
  defaultFilter,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const { locale, loading } = useMyAppointmentsLocale();

  const typeOptions = useMemo(
    () =>
      [
        {
          value: undefined,
          label: locale.all,
        },
        {
          value: AppointmentTypeEnum.Chat,
          label: locale[appointmentRenderInfo[AppointmentTypeEnum.Chat].text],
        },
        {
          value: AppointmentTypeEnum.Video,
          label: locale[appointmentRenderInfo[AppointmentTypeEnum.Video].text],
        },
      ] as ISingleValueLabel[],
    [locale],
  );

  const statusOptions = useMemo(
    () =>
      [
        {
          value: undefined,
          label: locale.all,
        },
        ...historyStatuses.map((status) => ({
          value: status,
          label: capitalizeFirstLetter(status),
        })),
      ] as ISingleValueLabel[],
    [locale],
  );

  const [form, setForm] = React.useState<IFilterForm>(() => {
    const { from, to, type, appointmentStatus = [] } = defaultFilter;
    const formStatus = statusOptions.find(
      ({ value }) => value === appointmentStatus[0],
    );
    const typeStatus = typeOptions.find(({ value }) => value === type);
    return {
      from,
      type: type ? typeStatus : undefined,
      status: formStatus,
      to,
    } as IFilterForm;
  });
  const closeOptions = useCallback(() => setOpen(false), [setOpen]);
  const container = document.getElementById(FILTER_BLOCK_ID);

  const onDateChange = (from: string | undefined, to: string | undefined) => {
    const toDate = to ? moment(to).endOf('day').toISOString() : undefined;
    setForm((form) => ({ ...form, from, to: toDate }));
  };

  const onTypeChange = (type: ISingleValueLabel) => {
    setForm((form) => ({ ...form, type }));
  };

  const onStatusChange = (appointmentStatus: ISingleValueLabel) => {
    setForm((form) => ({ ...form, appointmentStatus }));
  };

  const onReset = () => {
    handleFilterChange({});
    setForm({});
    closeOptions();
  };

  const onApply = () => {
    handleFilterChange(form);
    closeOptions();
  };

  useOutsideClick(ref, closeOptions, open);

  if (!open || !container || !locale || loading) {
    return null;
  }
  return createPortal(
    <div
      onClick={(event) => event.stopPropagation()}
      ref={ref}
      className="z-40 w-[340px] border rounded-xl bg-white border-gray-opacity-15 absolute top-24 right-0 max-mobile:-right-16"
    >
      <div className="p-5 text-neutral-800 flex items-center justify-between border border-transparent border-b-gray-opacity-15">
        <div className="font-semibold">{locale.filterBy}</div>
        <ButtonComponent
          type="icon"
          Icon={Close}
          onClick={closeOptions}
          className="!p-0 !h-auto !w-auto hover:!bg-light-teal"
          iconWidth="!stroke-med-gray-3 !w-[20px] !h-[20px]"
        />
      </div>

      <div className="p-5 text-neutral-800 flex flex-col justify-between gap-6 mb-2">
        <div>
          <div className="font-semibold">{locale.dateRange}</div>
          <CustomRangeDatepicker
            onDateChange={onDateChange}
            start={form?.from}
            end={form?.to}
          />
        </div>
        <div>
          <div className="font-semibold">{locale.type}</div>
          <Select
            value={form?.type}
            options={typeOptions}
            onChange={(type) =>
              onTypeChange(type as unknown as ISingleValueLabel)
            }
            components={{
              DropdownIndicator: DropdownIndicatorSingle,
              IndicatorSeparator: NullIndicatorSeparator,
              SingleValue,
              Option,
            }}
          />
        </div>
        <div>
          <div className="font-semibold">{locale?.status}</div>
          <Select
            options={statusOptions}
            value={form?.appointmentStatus}
            onChange={(status) =>
              onStatusChange(status as unknown as ISingleValueLabel)
            }
            components={{
              DropdownIndicator: DropdownIndicatorSingle,
              IndicatorSeparator: NullIndicatorSeparator,
              SingleValue,
              Option,
            }}
          />
        </div>
      </div>

      <div className="p-5 mt-auto text-neutral-800 flex items-center justify-between border border-transparent border-t-gray-opacity-15">
        <ButtonComponent
          onClick={onReset}
          className="!text-sm"
          type="underline"
        >
          {locale.reset}
        </ButtonComponent>
        <ButtonComponent className="!text-sm" onClick={onApply}>
          {locale.apply}
        </ButtonComponent>
      </div>
    </div>,
    container,
  );
};

export default PastAppointmentFilter;
