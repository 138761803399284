import ButtonComponent from 'components/button/buttonComponent';
import InputComponent from 'components/inputComponent';
import { ReactComponent as RightArrowIcon } from 'assets/icons/blue-arrow-right-filter.svg';
import { ReactComponent as TopArrowIcon } from 'assets/icons/blue-arrow-top.svg';
import { ReactComponent as VideoIcon } from 'assets/icons/appointmentsIcons/videoIcon.svg';
import { ReactComponent as ChatIcon } from 'assets/icons/appointmentsIcons/chatIcon.svg';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { filterOptions } from '../mocks/sidebarFilters';
import { ProviderToggleButtonGroup } from './ProviderToggleButtonGroup';
import { useGetPage } from 'hooks/useGetPage';
import {
  allAvailableFilters,
  availableLanguages,
  availableSpecialties,
  componentIds,
  genericActionsIds,
  pageIds,
} from 'utilities/constants';
import { useGetGenericActions } from 'hooks/useGetGenericActions';
import {
  AcceptedAppointmentsFilterEnum,
  GenderFilterEnum,
} from 'app/my-appointments/pages/MyProvider.interface';
import { useGetComponent } from 'hooks/useGetComponent';
import {
  ProviderSideBarFilterProps,
  ProvidersFilterOptionType,
  ProvidersFilterType,
} from './ProviderSideBarFilterInterfaces';

const SELECT_ALL_TYPE = 'select-all';
const VIEW_ELEM_COUNT = 4;
const FULL_LISTS_OF_OPTIONS = {
  specialty: availableSpecialties,
  acceptedAppointments: Object.values(AcceptedAppointmentsFilterEnum),
  gender: Object.values(GenderFilterEnum),
  languages: availableLanguages,
};

export const ProviderSideBarFilter: React.FC<ProviderSideBarFilterProps> = ({
  selectedFilters,
  setSelectedFilters,
}) => {
  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.PROVIDERS_SELECTION,
  });

  const { data: localeSidebar, loading: localeLoading } = useGetComponent({
    locale: 'en',
    componentId: componentIds.PROVIDER_SIDEBAR_FILTERS,
  });

  const { data: genericActions } = useGetGenericActions({
    locale: 'en',
    genericActionId: [genericActionsIds.VIEW_ALL, genericActionsIds.SHOW_LESS],
  });
  const { loading: selectAProviderLocaleLoading, data: selectAProviderLocale } =
    useGetPage({
      locale: 'en',
      pageId: pageIds.SELECT_A_PROVIDER,
    });
  const { data: specialtiesAndLanguagesLocales } = useGetComponent({
    locale: 'en',
    componentId: componentIds.AVAILABLE_SPECIALTIES_AND_LANGUAGES,
  });

  const formatGender = useCallback((options: ProvidersFilterOptionType[]) => {
    return options
      .filter(({ type }) => type !== 'button')
      .map((option) => ({
        ...option,
        type: option.type === 'radio' ? SELECT_ALL_TYPE : 'checkbox',
      }));
  }, []);

  const formatOptions = useCallback(
    (
      options: string[],
      locale: Record<string, string>,
      withAllOptions = true,
    ): ProvidersFilterOptionType[] => {
      const allOption = {
        label: selectAProviderLocale?.all,
        type: SELECT_ALL_TYPE,
        value: selectAProviderLocale?.all,
      };
      const opt = options.map((option) => ({
        type: 'checkbox',
        value: option,
        label: locale[option],
      }));
      if (withAllOptions) {
        return [allOption, ...opt];
      }
      return opt;
    },
    [selectAProviderLocale?.all],
  );

  const hideFiltersForType = useCallback(
    (type: string, withAllOption = true) =>
      () => {
        if (!allAvailableFilters[type]) return;
        setFilters((prevFilters) => ({
          ...prevFilters,
          [type]: {
            options: formatOptions(
              allAvailableFilters[type].slice(0, VIEW_ELEM_COUNT),
              specialtiesAndLanguagesLocales[type],
              withAllOption,
            ),
            canLoadMore: true,
            onLoadMore: () => {
              if (!allAvailableFilters[type]) return;
              setFilters((prevFilters) => ({
                ...prevFilters,
                [type]: {
                  options: formatOptions(
                    allAvailableFilters[type],
                    specialtiesAndLanguagesLocales[type],
                  ),
                  canShowLess: true,
                  onShowLess: hideFiltersForType(type),
                  fullListOfOptions:
                    FULL_LISTS_OF_OPTIONS[
                      type as keyof typeof FULL_LISTS_OF_OPTIONS
                    ],
                },
              }));
            },
            fullListOfOptions:
              FULL_LISTS_OF_OPTIONS[type as keyof typeof FULL_LISTS_OF_OPTIONS],
          },
        }));
      },
    [formatOptions, specialtiesAndLanguagesLocales],
  );

  const loadAllFiltersForType = useCallback(
    (type: string, withAllOption = true) =>
      () => {
        if (!allAvailableFilters[type]) return;
        setFilters((prevFilters) => ({
          ...prevFilters,
          [type]: {
            options: formatOptions(
              allAvailableFilters[type],
              specialtiesAndLanguagesLocales[type],
              withAllOption,
            ),
            canShowLess: true,
            onShowLess: hideFiltersForType(type, withAllOption),
            fullListOfOptions:
              FULL_LISTS_OF_OPTIONS[type as keyof typeof FULL_LISTS_OF_OPTIONS],
          },
        }));
      },
    [formatOptions, specialtiesAndLanguagesLocales, hideFiltersForType],
  );

  useEffect(() => {
    if (
      !selectAProviderLocale ||
      !specialtiesAndLanguagesLocales ||
      !localeSidebar
    )
      return;
    const filters: Record<string, ProvidersFilterType> = {
      specialty: {
        options: formatOptions(
          availableSpecialties.slice(0, VIEW_ELEM_COUNT),
          specialtiesAndLanguagesLocales.specialty,
        ),
        canLoadMore: true,
        onLoadMore: loadAllFiltersForType('specialty'),
        fullListOfOptions: FULL_LISTS_OF_OPTIONS['specialty'],
      },
      acceptedAppointments: {
        options: formatOptions(
          Object.values(AcceptedAppointmentsFilterEnum),
          selectAProviderLocale,
        ),
        fullListOfOptions: FULL_LISTS_OF_OPTIONS['acceptedAppointments'],
        icons: [<VideoIcon />, <ChatIcon />],
      },
      gender: {
        options: formatGender(localeSidebar.filterOptions['gender'].options),
        fullListOfOptions: FULL_LISTS_OF_OPTIONS['gender'],
      },
      languages: {
        options: formatOptions(
          availableLanguages.slice(0, VIEW_ELEM_COUNT),
          specialtiesAndLanguagesLocales.languages,
          false,
        ),
        canLoadMore: true,
        onLoadMore: loadAllFiltersForType('languages', false),
        fullListOfOptions: FULL_LISTS_OF_OPTIONS['languages'],
      },
    };
    setFilters(filters);
  }, [
    loadAllFiltersForType,
    formatOptions,
    selectAProviderLocale,
    specialtiesAndLanguagesLocales,
    localeSidebar,
    formatGender,
  ]);

  const [filters, setFilters] =
    useState<Record<string, ProvidersFilterType>>(filterOptions);
  const [selectedButton, setSelectedButton] = useState<string[]>([]);

  const handleFilterChange = (
    filterType: string,
    value: string,
    type: string,
  ) => {
    setSelectedFilters((prevFilters) => {
      const currentFilters = prevFilters[filterType] ?? [];

      if (type === SELECT_ALL_TYPE) {
        !currentFilters.length && loadAllFiltersForType(filterType)();

        return {
          ...prevFilters,
          [filterType]: currentFilters.length
            ? undefined
            : FULL_LISTS_OF_OPTIONS[
                filterType as keyof typeof FULL_LISTS_OF_OPTIONS
              ],
        };
      }

      const updatedFilters = currentFilters.includes(value)
        ? currentFilters.filter((filter) => filter !== value)
        : [...currentFilters, value];

      return {
        ...prevFilters,
        [filterType]: updatedFilters.length ? updatedFilters : undefined,
      };
    });
  };

  const handleButtonChange = (button: string) => {
    let buttons;
    if (selectedButton.includes(button)) {
      buttons = selectedButton.filter((selected) => selected !== button);
    } else {
      buttons = [...selectedButton, button];
    }
    setSelectedButton(buttons);
  };

  if (
    loading ||
    !locale ||
    !genericActions ||
    selectAProviderLocaleLoading ||
    localeLoading
  )
    return null;

  return (
    <div
      className="flex flex-col w-full py-4 px-8 gap-4 bg-base-content rounded-10"
      data-testid="find-provider-filter"
    >
      <p className=" font-semibold text-neutral-800 text-h5">
        {locale?.providerBio?.filters}
      </p>
      <hr className="flex flex-row w-full items-center h-px bg-black-blur" />
      <InputComponent
        name="All"
        type="checkbox"
        checkboxProps={{
          isAlternativeCheckboxColor: true,
          checkboxInputLabel: 'All',
          checkboxLabel: 'All',
          checkboxLabelClassName: 'text-sm text-med-gray-3 font-semibold',
          onCheckboxChange: () => {
            if (
              localeSidebar.toggleButtonGroupMock.buttons?.length ===
              selectedButton.length
            ) {
              return setSelectedButton([]);
            }
            return setSelectedButton(() =>
              localeSidebar.toggleButtonGroupMock.buttons.map(
                (button: { label: string }) => button.label,
              ),
            );
          },
          checked:
            localeSidebar.toggleButtonGroupMock.buttons?.length ===
            selectedButton.length,
        }}
      />
      <ProviderToggleButtonGroup
        buttons={localeSidebar.toggleButtonGroupMock.buttons}
        selectedButton={selectedButton}
        onButtonChange={handleButtonChange}
      />
      {Object.entries(filters).map(
        ([
          filterType,
          {
            options,
            canLoadMore,
            onLoadMore,
            canShowLess,
            onShowLess,
            fullListOfOptions,
            icons,
          },
        ]) => {
          return (
            <div key={filterType} className="flex flex-col gap-2">
              <p className="font-semibold text-neutral-800 text-h6">
                {selectAProviderLocale[filterType]}
              </p>
              {options.map((option, index) => (
                <Fragment key={option.value}>
                  {option.type !== 'button' && (
                    <InputComponent
                      name={filterType + option.label}
                      type="checkbox"
                      {...(option.type === SELECT_ALL_TYPE && {
                        checkboxProps: {
                          isAlternativeCheckboxColor: true,
                          checkboxInputLabel: filterType + option.value,
                          checkboxLabel: option.label,
                          checkboxLabelClassName:
                            'text-sm text-med-gray-3 font-semibold',
                          onCheckboxChange() {
                            handleFilterChange(
                              filterType,
                              option.value,
                              SELECT_ALL_TYPE,
                            );
                          },
                          checked:
                            selectedFilters[filterType]?.length ===
                            fullListOfOptions?.length,
                        },
                      })}
                      {...(option.type === 'checkbox' && {
                        checkboxProps: {
                          isAlternativeCheckboxColor: true,
                          checkboxInputLabel: option.value,
                          checkboxLabel: icons ? (
                            <p className="flex items-center gap-1.5">
                              {icons[index - 1]} {option.label}
                            </p>
                          ) : (
                            option.label
                          ),
                          checkboxLabelClassName:
                            'text-sm text-med-gray-3 font-semibold',
                          checked: selectedFilters[filterType]?.includes(
                            option.value,
                          ),
                          onCheckboxChange() {
                            handleFilterChange(
                              filterType,
                              option.value,
                              'checkbox',
                            );
                          },
                        },
                      })}
                    />
                  )}
                </Fragment>
              ))}
              {canLoadMore && (
                <div className="flex items-center">
                  <ButtonComponent
                    fullWidthClassName="w-auto"
                    type="underline"
                    Icon={RightArrowIcon}
                    onClick={onLoadMore}
                    iconHeight="h-3"
                    iconWidth="ml-2 h-[12px] w-[6px]"
                    className="!text-sm font-bold"
                    containerClassName="!gap-0.5"
                  >
                    {genericActions[genericActionsIds.VIEW_ALL]?.viewAll}
                  </ButtonComponent>
                </div>
              )}
              {canShowLess && (
                <div className="flex items-center">
                  <ButtonComponent
                    fullWidthClassName="w-auto"
                    type="underline"
                    Icon={TopArrowIcon}
                    onClick={onShowLess}
                    iconWidth="w-[12px] h-[6px]"
                    className="!text-sm font-bold"
                  >
                    {genericActions[genericActionsIds.SHOW_LESS]?.showLess}
                  </ButtonComponent>
                </div>
              )}
            </div>
          );
        },
      )}
    </div>
  );
};
