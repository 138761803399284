import React, { useEffect, useMemo, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import ModalComponent from 'components/modal/modalComponent';
import { ModalButtonProps } from 'components/modal/modal.interfaces';
import InputComponent from 'components/inputComponent';
import { CANCEL_APPOINTMENT_ID } from '../upcoming-appointments/helper/const';
import { useGetAppointments } from '../../pages/payments/hook';
import { GetAppointmentOutput } from 'graphql/generated/hasura';
import { convertToFullInfoFormat } from '../../../dashboard/components/upcomingAppointments/functions';
import { sanitize } from 'utilities/sanitizer';
import { useCancelAppointmentMutation } from 'graphql/generated/remote-schema-hasura';
import { useGetComponent } from 'hooks/useGetComponent';
import { componentIds } from 'utilities/constants';

interface ICancelAppointmentModalProps {
  callback?: () => void;
}

export function boldString(str: string, find: string, insert: string) {
  if (!find || !str) return str;
  const escapedFind = find.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  const re = new RegExp(escapedFind, 'gi');
  return str.replace(
    re,
    `<span class="font-semibold text-neutral-800">${insert}</span>`,
  );
}

const CancelAppointmentModal: React.FC<ICancelAppointmentModalProps> = ({
  callback,
}) => {
  const [url, setUrl] = useSearchParams();
  const appointmentId = url.get(CANCEL_APPOINTMENT_ID);

  const { data: locale, loading: loading } = useGetComponent({
    componentId: componentIds.APPOINTMENT_PREWORK,
    locale: 'en',
  });

  const [appointment, setAppointment] = useState<GetAppointmentOutput>();

  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const handleCheckboxChange = (checked: boolean) => {
    setIsCheckboxChecked(checked);
  };

  const handleGoBack = () => {
    url.delete(CANCEL_APPOINTMENT_ID);
    setAppointment(undefined);
    setUrl(url);
  };

  const providerAndTime = useMemo(() => {
    const startTime =
      appointment?.start && convertToFullInfoFormat(String(appointment?.start));
    const name = `${appointment?.providerFirstName} ${appointment?.providerLastName}`;
    let localeStr = locale?.cancelModal?.providerAndTime;

    if (!startTime || !localeStr || !name) {
      return '';
    }
    localeStr = boldString(localeStr, '{providerName}', name);
    return boldString(localeStr, '{start}', startTime);
  }, [appointment, locale]);

  const getData = useGetAppointments(appointmentId);

  const [cancelAppointment, { loading: cancelAppointmentLoading }] =
    useCancelAppointmentMutation();

  const handleCancel = async () => {
    await cancelAppointment({
      variables: {
        codexAppointmentId: String(appointmentId),
        cancelationReason: '',
      },
    });
    handleGoBack();
    if (!callback) {
      return;
    }
    callback();
  };

  const Buttons: ModalButtonProps[] = [
    {
      label: locale?.cancelModal?.cancelBtn,
      onClick: handleCancel,
      disabled: !isCheckboxChecked || cancelAppointmentLoading,
      className: 'desktop:w-[160px] !text-sm !font-bold !rounded',
    },
    {
      label: locale?.cancelModal?.backBtn,
      onClick: handleGoBack,
      type: 'underline',
      className: '!text-sm !font-bold',
    },
  ];

  useEffect(() => {
    if (appointmentId) {
      getData()
        .then((data) => setAppointment(data))
        .catch((error) => console.error(error));
    }
  }, [appointmentId, getData]);

  if (!appointmentId || !locale || loading) {
    return null;
  }

  return (
    <ModalComponent
      titleCustomClass="normal-case font-exo"
      buttons={Buttons}
      isOpen={true}
      title={locale.cancelModal.title}
      contentContainerGap="gap-2"
      onClose={handleGoBack}
      modalDesktopWidth="desktop:max-w-[600px]"
    >
      <p
        className="text-med-gray-3 font-medium"
        dangerouslySetInnerHTML={{ __html: sanitize(providerAndTime) }}
      />
      <p className="text-med-gray-3 font-medium">
        {locale?.cancelModal?.noteTitle}
      </p>
      <ul className="list-disc font-medium ml-4 marker:text-xs marker:text-med-gray-3 text-med-gray-3">
        <li
          dangerouslySetInnerHTML={{
            __html: sanitize(locale?.cancelModal?.firstNote),
          }}
        />
        <li>{locale?.cancelModal?.secondNote}</li>
      </ul>

      <div className="flex flex-row gap-2.5 items-start mt-8">
        <InputComponent
          testID="policy-input"
          type="checkbox"
          name="agreementsCheckbox"
          checkboxProps={{
            checked: isCheckboxChecked,
            onCheckboxChange: handleCheckboxChange,
            isAlternativeCheckboxColor: true,
            checkboxLabel: (
              <div className="text-med-gray text-sm font-semibold">
                <p>{locale?.cancelModal?.linkDescriptions}</p>
                <Link
                  to={'/legal-documents/2'}
                  className="text-clc-blue underline"
                  target="_blank"
                >
                  {locale?.cancelModal?.linkTitle}
                </Link>
                <span className="text-alert-negative">*</span>
              </div>
            ),
          }}
        />
      </div>
    </ModalComponent>
  );
};

export default CancelAppointmentModal;
