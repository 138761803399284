import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import { ReactDatePickerCustomHeaderProps } from 'react-datepicker/dist/calendar';
import { ReactComponent as Arrow } from 'assets/icons/blue-arrow-left.svg';
import { ReactComponent as Datepicker } from 'assets/icons/calendar.svg';
import ButtonComponent from '../button/buttonComponent';

const renderDayContents = (day: number) => {
  return <span className="flex items-center justify-center h-full">{day}</span>;
};

const renderCustomHeader = ({
  monthDate,
  decreaseMonth,
  increaseMonth,
}: ReactDatePickerCustomHeaderProps) => (
  <div>
    <button
      aria-label="Previous Month"
      className={
        'react-datepicker__navigation react-datepicker__navigation--previous'
      }
      onClick={decreaseMonth}
    >
      <Arrow className="h-[12px] fill-med-gray-3" />
    </button>
    <span className="react-datepicker__current-month">
      {monthDate.toLocaleString('en-US', {
        month: 'long',
        year: 'numeric',
      })}
    </span>
    <button
      aria-label="Next Month"
      className={
        'react-datepicker__navigation react-datepicker__navigation--next'
      }
      onClick={increaseMonth}
    >
      <Arrow className="rotate-180 h-[12px] fill-med-gray-3" />
    </button>
  </div>
);

interface ICustomRangeDatepickerProps {
  start?: string;
  end?: string;
  onDateChange: (from: string | undefined, to: string | undefined) => void;
}

const dateConverter = (date: Date | null) => {
  if (!date) {
    return undefined;
  }
  return date.toISOString();
};

const CustomRangeDatepicker: React.FC<ICustomRangeDatepickerProps> = ({
  start,
  end,
  onDateChange,
}) => {
  const [startDate, setStartDate] = useState<Date | undefined>(() =>
    start ? new Date(start) : undefined,
  );
  const [endDate, setEndDate] = useState<Date | undefined>(() =>
    end ? new Date(end) : undefined,
  );
  const onChange = (dates: Array<unknown>) => {
    const [start, end] = dates;

    const startDate = new Date(start as Date);

    setStartDate(startDate);
    setEndDate(end as Date);

    onDateChange(dateConverter(startDate), dateConverter(end as Date));
  };

  const onTodayClick = () => ({});

  return (
    <DatePicker
      showPopperArrow={false}
      selected={startDate}
      onChange={onChange}
      startDate={startDate}
      endDate={endDate}
      wrapperClassName="w-full"
      className="w-full"
      formatWeekDay={(nameOfDay) => nameOfDay.slice(0, 3)}
      renderDayContents={renderDayContents}
      renderCustomHeader={renderCustomHeader}
      icon={<Datepicker />}
      popperPlacement="bottom"
      showIcon
      selectsRange
    >
      <div className="inline-flex items-center justify-center w-full border border-transparent border-t-gray-opacity-15 pt-2">
        <ButtonComponent
          type="underline"
          className="!text-sm font-sans"
          onClick={onTodayClick}
        >
          Today
        </ButtonComponent>
      </div>
    </DatePicker>
  );
};

export default CustomRangeDatepicker;
