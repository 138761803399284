import React, { ChangeEvent, FC, useCallback, useMemo, useState } from 'react';

import Dropzone from '../../../components/Dropzone';
import InputComponent from '../../../components/inputComponent';
import Modal from '../../../components/modal/modalComponent';
import { useGetGenericActions } from '../../../hooks/useGetGenericActions';
import { useGetPage } from '../../../hooks/useGetPage';
import { useGetFhirPatientMediaQuery } from '../../../graphql/generated/remote-schema-hasura';
import { useImageCrop } from '../../../hooks/useImageCrop';
import PhotoCropper from '../appointments/PhotoCropper';
import CroppedImageComponent from '../appointments/CroppedImageComponent';
import UploadingTypesComponent from '../appointments/UploadingTypesComponent';

import {
  genericActionsIds,
  LIST_BATCH_SIZE,
  pageIds,
} from '../../../utilities/constants';

interface IModalUploadPhoto {
  isOpen: boolean;
  onClose: () => void;
  bodySymptom: string;
  qrCode: string;
  onShowLibraryModal: () => void;
  onUploadPhoto: (photo: string, id: string, description: string) => void;
}

const ALLOWED_FORMATS = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif'];
const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10 MB

const ModalUploadPhoto: FC<IModalUploadPhoto> = ({
  isOpen,
  onClose,
  bodySymptom,
  qrCode,
  onShowLibraryModal,
  onUploadPhoto,
}) => {
  const [isShowUploadDetails, setIsShowUploadDetails] =
    useState<boolean>(false);
  const [hasMedia, setHasMedia] = useState<boolean>(false);
  const [fileError, setFileError] = useState<string>('');
  const [file, setFile] = useState<File | null>(null);
  const [isShowCropper, setIsShowCropper] = useState<boolean>(false);
  const [croppedImage, setCroppedImage] = useState('');
  const [description, setDescription] = useState<string>('');

  const fileUrl = useMemo(() => {
    return file ? URL.createObjectURL(file) : '';
  }, [file]);

  const {
    src: cropSrc,
    setSrc: setCropSrc,
    crop,
    setCrop,
    imgRef: cropImgRef,
    setCompletedCrop,
    onImageLoad,
    onCropComplete,
  } = useImageCrop(fileUrl, 1, file?.type || ALLOWED_FORMATS[0]);

  const { loading: sharedMediaLoading } = useGetFhirPatientMediaQuery({
    variables: {
      limit: LIST_BATCH_SIZE,
    },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setHasMedia(data.getFHIRMediaByRequestPatientCodexID.total > 0);
    },
  });

  const { data: genericActions, loading: genericActionsLoading } =
    useGetGenericActions({
      locale: 'en',
      genericActionId: [genericActionsIds.CANCEL],
    });

  const { data: locale, loading: localeLoading } = useGetPage({
    pageId: pageIds.APPOINTMENT_PREWORK,
    locale: 'en',
  });

  const onDescriptionChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(event.target.value);
  };

  const onCloseModal = useCallback(() => {
    setIsShowUploadDetails(false);
    setFileError('');
    setFile(null);
    setCroppedImage('');
    setIsShowCropper(false);
    onClose();
  }, [onClose]);

  const onSavePhoto = useCallback(async () => {
    const capturedPhoto = await onCropComplete('');
    setCroppedImage(capturedPhoto as string);
    setIsShowCropper(false);
  }, [onCropComplete]);

  const onUpload = useCallback(() => {
    onCloseModal();
    onUploadPhoto(croppedImage, bodySymptom, description);
    setDescription('');
  }, [bodySymptom, croppedImage, description, onCloseModal, onUploadPhoto]);

  const buttons = useMemo(
    () => [
      ...(isShowUploadDetails
        ? [
            {
              label: locale?.uploadButton,
              disabled: !fileUrl,
              onClick: isShowCropper ? onSavePhoto : onUpload,
              type: 'primary',
              className: '!text-sm font-bold',
            },
          ]
        : []),
      {
        label: genericActions?.[genericActionsIds.CANCEL].cancel,
        disabled: false,
        onClick: onCloseModal,
        type: 'underline',
        className: '!text-sm !font-bold',
      },
    ],
    [
      fileUrl,
      genericActions,
      isShowCropper,
      isShowUploadDetails,
      locale?.uploadButton,
      onCloseModal,
      onSavePhoto,
      onUpload,
    ],
  );

  const onImageUpload = useCallback(
    async (files: File[]) => {
      const nawFile = files[0];

      if (!nawFile) {
        return;
      }

      if (!ALLOWED_FORMATS.includes(nawFile.type)) {
        setFileError(locale?.invalidFileFormat);
        return;
      }

      if (nawFile.size > MAX_FILE_SIZE) {
        setFileError(locale?.imageMustBeUpToTenMb);
        return;
      }

      setFile(nawFile);
      setCropSrc(URL.createObjectURL(nawFile));
      setIsShowCropper(true);
    },
    [locale?.imageMustBeUpToTenMb, locale?.invalidFileFormat, setCropSrc],
  );

  const title = !isShowUploadDetails
    ? locale?.addProblemAreaPhotoTitle
    : isShowCropper
    ? locale?.cropPhoto
    : locale?.uploadPhotoFromDeviceTitle;

  if (genericActionsLoading || localeLoading || !locale || sharedMediaLoading) {
    return null;
  }

  return (
    <Modal
      isOpen={isOpen}
      title={title}
      onClose={onCloseModal}
      buttons={buttons}
      modalDesktopWidth="desktop:max-w-[720px]"
      titleCustomClass="normal-case desktop:text-2xl"
      testID="modal-upload-photo"
    >
      {!isShowCropper ? (
        <div className="flex flex-col gap-3 justify-center">
          <h2 className="text-h1 text-neutral-800 font-medium">
            {bodySymptom}
          </h2>
          {!isShowUploadDetails ? (
            <UploadingTypesComponent
              locale={locale}
              qrCode={qrCode}
              setIsShowUploadDetails={setIsShowUploadDetails}
              hasMedia={hasMedia}
              onShowLibraryModal={onShowLibraryModal}
            />
          ) : (
            <div
              className="flex flex-col gap-3 justify-center"
              data-testid="dropzone-symptom-photo"
            >
              <div className="text-base font-medium text-med-gray-3 mb-5">
                <p>{locale?.changePhotoDescription}</p>
              </div>

              {croppedImage ? (
                <CroppedImageComponent
                  croppedImage={croppedImage}
                  setCroppedImage={setCroppedImage}
                  setFile={setFile}
                />
              ) : (
                <Dropzone
                  label={locale?.dropFileHereToUpload}
                  supportText={locale?.supportedFormat}
                  className="col-span-5 mb-5 py-12"
                  onDrop={onImageUpload}
                  error={fileError}
                />
              )}

              <div>
                <label className="text-neutral-800 font-semibold text-base">
                  {locale?.describeIssueYourBody}
                </label>
                <InputComponent
                  type="text-area"
                  value={description}
                  maxLengthValue={500}
                  placeholder={locale?.enterIssue}
                  customInputClassname="placeholder:text-med-gray-3 placeholder:font-medium"
                  textAreaProps={{
                    height: 'h-32',
                    name: 'photoDescription',
                    onTextAreaChange: onDescriptionChange,
                  }}
                />
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="flex flex-col gap-3 justify-center">
          <PhotoCropper
            crop={crop}
            setCrop={setCrop}
            setCompletedCrop={setCompletedCrop}
            cropSrc={cropSrc}
            onImageLoad={onImageLoad}
            cropImgRef={cropImgRef}
          />
        </div>
      )}
    </Modal>
  );
};

export default ModalUploadPhoto;
