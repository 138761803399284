import React from 'react';
import { twMerge } from 'tailwind-merge';
import { convertTo12Hourformat } from '../../../../dashboard/components/upcomingAppointments/functions';

interface ITimeSlotProps {
  time: string;
  key: React.Key;
  isSelected?: boolean;
  isDisabled?: boolean;
  onSelect: (slot: string) => void;
}

const TimeSlot: React.FC<ITimeSlotProps> = ({
  time,
  isSelected,
  isDisabled = false,
  onSelect,
  key,
}) => {
  return (
    <div
      key={key}
      data-testid={`time-slot-${time}`}
      onClick={() => !isDisabled && onSelect(time)}
      className={twMerge(
        'py-4 px-8 rounded-xl bg-white border border-gray-opacity-15 min-w-[180px] w-full flex items-center basis-[24.25%] sm:basis-[49%] md:basis-[32%] lg:basis-[32%] 2xl:basis-[24.25%] gap-2.5',
        isDisabled && 'bg-neutral-100',
        isSelected && 'border-clc-blue',
      )}
    >
      <span
        className={twMerge(
          'rounded-full border border-med-gray-3 w-7 h-7',
          isSelected && 'border-clc-blue border-[10px]',
        )}
      />
      <span
        className={twMerge(
          'text-base font-semibold text-neutral-800 whitespace-nowrap',
          isDisabled && 'line-through',
        )}
      >
        {convertTo12Hourformat(time)}
      </span>
    </div>
  );
};

export default TimeSlot;
