import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { ReactComponent as Clear } from 'assets/icons/close.svg';
import debounce from 'lodash/debounce';
import { twMerge } from 'tailwind-merge';

interface SearchInputProps {
  placeholder?: string;
  className?: string;
  inputClassName?: string;
  testID?: string;
  disabled?: boolean;
  handleOnChange: (e: string) => void;
  ref: React.Ref<HTMLInputElement>;
}

const DEBOUNCE_TIME = 500;

const SearchInput = forwardRef(
  (
    {
      placeholder,
      handleOnChange,
      disabled,
      className,
      inputClassName,
      testID,
    }: SearchInputProps,
    ref: React.Ref<HTMLInputElement>,
  ) => {
    const innerRef = useRef<HTMLInputElement>(null);

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    useImperativeHandle(ref, () => innerRef.current!, []);

    const clearInput = () => {
      handleSearch('');
      if (innerRef && innerRef?.current) {
        innerRef.current.value = '';
      }
    };

    const handleSearch = debounce(
      (query: string) => handleOnChange(query),
      DEBOUNCE_TIME,
    );

    return (
      <div
        className={twMerge(
          'relative flex flex-row grow-0 gap-2 justify-between bg-white text-base border input-border__gray rounded-md placeholder-gray-500 text-gray-700 focus-within:outline-none focus-within:border-blue-400 overflow-hidden',
          className,
        )}
      >
        <input
          ref={innerRef}
          placeholder={placeholder}
          disabled={disabled}
          data-testid={testID}
          className={twMerge(
            'placeholder-gray-500 grow p-3 text-dark-gray font-semibold text-sm outline-none border-0 focus:outline-none',
            'placeholder:italic placeholder:font-normal placeholder:text-med-gray-3',
            inputClassName,
          )}
          onChange={(event) => handleSearch(event.target.value)}
        />

        {innerRef.current?.value && (
          <Clear
            onClick={clearInput}
            className={twMerge(
              'absolute cursor-pointer right-3 top-1/2 -translate-y-1/2',
            )}
          />
        )}
      </div>
    );
  },
);

export default SearchInput;
