import React from 'react';
import SearchInput from 'components/SearchInput';
import { useGetPage } from 'hooks/useGetPage';
import { pageIds } from 'utilities/constants';
import { FindAProviderFilterType } from '../index';

interface ISearchProviderProps {
  setFilter: React.Dispatch<React.SetStateAction<FindAProviderFilterType>>;
  initialFilter: { limit: number; offset: number };
}

const SearchProvider: React.FC<ISearchProviderProps> = ({
  setFilter,
  initialFilter = {},
}) => {
  const { data: locale } = useGetPage({
    locale: 'en',
    pageId: pageIds.PROVIDERS_SELECTION,
  });

  return (
    <div className="flex flex-col desktop:flex-row gap-4 desktop:items-center w-full">
      <p className="text-neutral-800 font-semibold text-h6">
        {locale?.findAProvider?.filters?.search}
      </p>
      <div className="w-full">
        <SearchInput
          testID="search-provider"
          placeholder={locale?.findAProvider?.filters?.searchPlaceholder}
          handleOnChange={(search) => {
            setFilter((prev) => ({
              ...initialFilter,
              ...prev,
              search,
            }));
          }}
        />
      </div>
    </div>
  );
};

export default SearchProvider;
