import React from 'react';
import TimeSlot from './TimeSlot';

interface ITimeSlotContainerProps {
  date: Date | null;
  selectedTime: string;
  onSelect: (slot: string) => void;
  times: string[];
}

const TimeSlotsContainer: React.FC<ITimeSlotContainerProps> = ({
  onSelect,
  selectedTime,
  times,
}) => {
  return (
    <div className="flex-wrap flex gap-2">
      {times.map((time) => (
        <TimeSlot
          time={time}
          key={time}
          onSelect={onSelect}
          isSelected={selectedTime === time}
        />
      ))}
    </div>
  );
};

export default TimeSlotsContainer;
