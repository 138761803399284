import React, { useCallback, useEffect, useMemo } from 'react';
import { useGetComponent } from 'hooks/useGetComponent';
import { componentIds } from 'utilities/constants';
import Loader from 'components/loaderComponent';
import ButtonComponent from 'components/button/buttonComponent';
import StorageService from 'utilities/storageService';
import { APPOINTMENT_STORAGE } from '../routePath';
import { IScheduledAnAppointmentFormProps } from '../interface';
import { appointmentConstructor } from '../../../helpers';
import {
  SuccesfullCreatedScheduledAppointment,
  useCreateScheduledAppointmentMutation,
} from 'graphql/generated/hasura';

const appointmentStorage = new StorageService(
  APPOINTMENT_STORAGE,
  sessionStorage,
);
const mockBut = 'Pay $price and Confirm Appointment';

const PaymentDetails = () => {
  const form = appointmentStorage.getData() as IScheduledAnAppointmentFormProps;

  const { data: locale, loading: localeLoading } = useGetComponent({
    locale: 'en',
    componentId: componentIds.APPOINTMENT_PREWORK,
  });

  const [createScheduledAppointment, { loading }] =
    useCreateScheduledAppointmentMutation();

  const butTitle = useMemo(
    () =>
      (locale?.payments?.paymentConfirm || mockBut).replace(
        '$price',
        `$${form?.selectedAppointment?.type.price || 0}`,
      ),
    [form, locale],
  );

  const clearAppointment = useCallback(() => {
    return appointmentStorage.clear();
  }, []);

  const handleAppointmentCreate = useCallback(async () => {
    const appointment = appointmentConstructor(form);
    const { data } = await createScheduledAppointment({
      variables: { ...appointment },
    });
    if (!data) {
      return;
    }
    clearAppointment();
    const { stripeRedirectedLink } =
      data.CreateScheduledAppointment as SuccesfullCreatedScheduledAppointment;
    setTimeout(() => (window.location.href = stripeRedirectedLink), 100);
  }, [clearAppointment, createScheduledAppointment, form]);

  useEffect(() => {
    return () => {
      clearAppointment();
    };
  }, [clearAppointment]);

  if (!locale || localeLoading) {
    return <Loader />;
  }

  return (
    <div className="px-[60px] py-[30px] bg-white rounded-md">
      <div className="flex flex-row w-full desktop:w-auto justify-between items-center">
        <h4 className="text-left text-h4 text-neutral-800 font-semibold">
          {locale?.payments?.paymentDetailsTitle}
        </h4>
      </div>

      <p className="text-base font-medium text-med-gray-3 my-4">
        {locale?.payments?.paymentDetailsDescription}
      </p>

      <hr />

      <ButtonComponent
        testID="create-appointment"
        className="mt-4 !text-sm"
        onClick={handleAppointmentCreate}
        disabled={loading}
      >
        {butTitle}
      </ButtonComponent>
    </div>
  );
};

export default PaymentDetails;
