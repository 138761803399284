import React from 'react';
import { IAppointmentDetailsData } from '../../interface';
import { useGetComponent } from 'hooks/useGetComponent';
import { componentIds } from 'utilities/constants';
import { APPOINTMENT_DETAILS } from '../../routePath';
import SavedContentCard from '../SavedContentCard';
import { selectedDateFormat } from 'utilities/functions';
import { convertTo12Hourformat } from '../../../../../dashboard/components/upcomingAppointments/functions';

interface IAppointmentDetailsCardProps {
  selectedAppointment?: IAppointmentDetailsData;
}

const AppointmentDetailsCard: React.FC<IAppointmentDetailsCardProps> = ({
  selectedAppointment,
}) => {
  const { type, timeSlot } = selectedAppointment || {};
  const { data: locale } = useGetComponent({
    componentId: componentIds.APPOINTMENT_PREWORK,
    locale: 'en',
  });

  if (!locale || !selectedAppointment) return null;

  return (
    <SavedContentCard
      title={locale.appointmentDetailsPage}
      link={APPOINTMENT_DETAILS}
    >
      <div className="text-med-gray-3 font-medium text-base">
        <p>{`${type?.name} (${type?.duration} ${locale?.appointmentDetails?.minutes}) ($${type?.price})`}</p>
        <p>{selectedDateFormat(String(timeSlot))}</p>
        <p>{convertTo12Hourformat(timeSlot as string)}</p>
      </div>
    </SavedContentCard>
  );
};

export default AppointmentDetailsCard;
