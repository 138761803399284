import React from 'react';
import { twMerge } from 'tailwind-merge';
import { IServiceTypes } from '../interface';
import { useGetComponent } from '../../../../../hooks/useGetComponent';
import { componentIds } from '../../../../../utilities/constants';

interface IAppointmentTypeSelectProps {
  serviceOfferingType: IServiceTypes;
  key: React.Key;
  isSelected?: boolean;
  isDisabled?: boolean;
  onSelect: (type: IServiceTypes) => void;
}

const AppointmentTypeSelect: React.FC<IAppointmentTypeSelectProps> = ({
  isSelected,
  isDisabled = false,
  onSelect,
  key,
  serviceOfferingType,
}) => {
  const { data } = useGetComponent({
    componentId: componentIds.APPOINTMENT_PREWORK,
    locale: 'en',
  });
  const { name, price, duration, id } = serviceOfferingType;
  // const Icon = appointmentRenderInfo[serviceType].icon;

  return (
    <div
      key={key}
      data-testid={`service-type-${id}`}
      onClick={() => !isDisabled && onSelect(serviceOfferingType)}
      className={twMerge(
        'py-5 px-8 rounded-xl max-desktop:max-w-[290px] bg-white border border-gray-opacity-15 flex items-center justify-between basis-[49%] gap-2.5',
        isDisabled && 'bg-neutral-100',
        isSelected && 'border-clc-blue',
      )}
    >
      <div className="flex flex-col">
        {/*<Icon className="h-8 w-5 fill-med-gray-3" />*/}

        <span className="text-base text-neutral-800 font-semibold">{name}</span>
        <span className="text-base text-med-gray-3 font-medium">
          {`${duration} ${data?.appointmentDetails?.minutes}`}
        </span>
        <span className="text-title text-neutral-800 font-semibold mt-2">
          (${price})
        </span>
      </div>
      <span
        className={twMerge(
          'rounded-full border border-med-gray-3 w-7 h-7',
          isSelected && 'border-clc-blue border-[10px]',
        )}
      />
    </div>
  );
};

export default AppointmentTypeSelect;
