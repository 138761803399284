import React, { useMemo } from 'react';
import { twMerge } from 'tailwind-merge';

interface ISelectDay {
  date: Date;
  key: React.Key;
  onSelect: (day: Date) => void;
  isSelected?: boolean;
  isDisabled?: boolean;
}

const SelectDay: React.FC<ISelectDay> = ({
  date,
  isSelected,
  isDisabled = false,
  onSelect,
  key,
}) => {
  const weekDay = useMemo(
    () => date.toLocaleDateString('en-US', { weekday: 'short' }),
    [date],
  );
  const day = useMemo(
    () => date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' }),
    [date],
  );

  return (
    <div
      key={key}
      data-testid={`select-day-${date.toISOString()}`}
      onClick={() => !isDisabled && onSelect(date)}
      className={twMerge(
        'py-5 px-8 min-w-[7.5rem] rounded-xl border border-gray-opacity-15 bg-white flex flex-col basis-12 items-center justify-center gap-2',
        isDisabled && 'bg-neutral-100',
      )}
    >
      <span
        className={twMerge(
          'rounded-full border border-med-gray-3 w-7 h-7',
          isSelected && 'border-clc-blue border-[10px]',
        )}
      />
      <span className="text-base font-medium text-med-gray-3">{weekDay}</span>
      <span
        className={twMerge(
          'text-base font-semibold text-neutral-800 whitespace-nowrap',
          isDisabled && 'line-through',
        )}
      >
        {day}
      </span>
    </div>
  );
};

export default SelectDay;
