import React, { useCallback, useMemo, useState } from 'react';
import {
  Routes,
  Route,
  Navigate,
  useNavigate,
  useLocation,
} from 'react-router-dom';
import Layout from './Layout';
import {
  APPOINTMENT_DETAILS,
  APPOINTMENT_STORAGE,
  FIND_A_PROVIDER,
  PAYMENTS,
  PLACE_SYMPTOMS,
  SYMPTOMS,
} from './routePath';
import SelectProvider from './select-a-provider';
import AppointmentDetails from './appointment-details';
import StorageService from 'utilities/storageService';
import {
  IAppointmentDetailsData,
  IPlacementProps,
  IScheduledAnAppointmentFormProps,
  ISymptomsProps,
} from './interface';
import IssuesAndSymptoms from './issues-and-symptoms';
import SymptomsPlacement from './symptoms-placement';
import AppointmentPreworkDesktop from '../../pages/AppointmentPreworkDesktop';
import { ImageObject } from '../../../my-skin/components/BodySymtomsLocation/interfaces';
import PaymentDetails from './payment-details';
import { useGetComponent } from 'hooks/useGetComponent';
import { componentIds } from 'utilities/constants';
import { ITab } from './components/LocationTab';

const appointmentStorage = new StorageService(
  APPOINTMENT_STORAGE,
  sessionStorage,
);

const ScheduledAnAppointment = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const form = appointmentStorage.getData() as IScheduledAnAppointmentFormProps;

  const { data: locale } = useGetComponent({
    componentId: componentIds.APPOINTMENT_PREWORK,
    locale: 'en',
  });
  const [startLocation, setStartLocation] = useState<string>(FIND_A_PROVIDER);

  const tabs = useMemo(
    () => ({
      [APPOINTMENT_DETAILS]: {
        title: locale?.appointmentDetailsPage,
        location: APPOINTMENT_DETAILS,
        isPassed: !!form?.selectedAppointment,
      },
      [FIND_A_PROVIDER]: {
        title: locale?.selectAProviderPage,
        location: FIND_A_PROVIDER,
        isPassed: !!form?.selectedProvider,
      },
      [SYMPTOMS]: {
        title: locale?.symptomsPage,
        location: SYMPTOMS,
        isPassed: !!form?.selectedSymptoms,
      },
      [PLACE_SYMPTOMS]: {
        title: locale?.whereExperiencingSymptomsPage,
        location: PLACE_SYMPTOMS,
        isPassed: !!form?.selectedPlacement,
      },
      [PAYMENTS]: { title: locale?.paymentDetailsPage, location: PAYMENTS },
    }),
    [form, locale],
  );

  const firstTabs = useMemo(
    () =>
      startLocation === FIND_A_PROVIDER
        ? [tabs[FIND_A_PROVIDER], tabs[APPOINTMENT_DETAILS]]
        : [tabs[APPOINTMENT_DETAILS], tabs[FIND_A_PROVIDER]],
    [startLocation, tabs],
  );

  const tabsArray: ITab[] = useMemo(
    () => [...firstTabs, tabs[SYMPTOMS], tabs[PLACE_SYMPTOMS], tabs[PAYMENTS]],
    [firstTabs, tabs],
  );

  const handleStartLocation = () => {
    setStartLocation(firstTabs[1].location);
    navigate(firstTabs[1].location, { state: { skipBlocker: true } });
    appointmentStorage.clear();
  };

  const handleContinue = useCallback(() => {
    const path = pathname.split('/');
    const loc = path[path.length - 1];
    const currentIndex = tabsArray.findIndex((item) => item.location === loc);
    navigate(tabsArray[currentIndex + 1].location, {
      state: { skipBlocker: true },
    });
  }, [navigate, pathname, tabsArray]);

  const onProviderSelect = (
    codexProviderId: string,
    calendarId?: string | null,
    ownerId?: string | null,
  ) => {
    appointmentStorage.addData({
      selectedProvider: { codexProviderId, calendarId, ownerId },
    });
    handleContinue();
  };

  const onAppointmentSelect = (data: IAppointmentDetailsData) => {
    appointmentStorage.addData({
      selectedAppointment: data,
    });
    handleContinue();
  };

  const onSymptomsSelect = (data: ISymptomsProps) => {
    appointmentStorage.addData({
      selectedSymptoms: data,
    });
    handleContinue();
  };

  const onPlacementsSelect = (data: Map<string, ImageObject>) => {
    const media = Array.from(
      data,
      ([id, { mediaId, description, image, location }]) =>
        ({
          id,
          mediaId,
          image,
          description,
          location,
        } as IPlacementProps),
    );
    appointmentStorage.addData({
      selectedPlacement: { media },
    });
    handleContinue();
  };

  return (
    <Routes>
      <Route
        path="/"
        element={
          <Layout
            handleStartLocation={handleStartLocation}
            startLocation={startLocation}
            tabs={tabsArray}
          />
        }
      >
        <Route
          path={FIND_A_PROVIDER}
          element={<SelectProvider onProviderSelect={onProviderSelect} />}
        />
        <Route
          path={APPOINTMENT_DETAILS}
          element={<AppointmentDetails handleNext={onAppointmentSelect} />}
        />
        <Route
          path={SYMPTOMS}
          element={<IssuesAndSymptoms handleNext={onSymptomsSelect} />}
        />
        <Route
          path={PLACE_SYMPTOMS}
          element={<SymptomsPlacement handleNext={onPlacementsSelect} />}
        />
        <Route path={'test'} element={<AppointmentPreworkDesktop />} />
        <Route path={PAYMENTS} element={<PaymentDetails />} />
        <Route path="/" element={<Navigate to={`${FIND_A_PROVIDER}`} />} />
      </Route>
    </Routes>
  );
};

export default ScheduledAnAppointment;
