import {
  ChangeEvent,
  Dispatch,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import MfaSmsComponent from 'components/multiFactorAuth/MfaSmsComponent';
import {
  useGetCountriesQuery,
  useRegisterPatchProviderMutation,
} from 'graphql/generated/hasura';
import ButtonComponent from 'components/button/buttonComponent';
import { AuthContext } from 'auth/context/AuthContext';
import {
  exact10DigitsPattern,
  exact6DigitsPattern,
  onlyDigitsPattern,
} from 'utilities/variables';
import InputComponent, {
  SelectWithFlagsValues,
} from 'components/inputComponent';
import { normalizePhoneNumber } from 'utilities/functions';
import { questionnaire_questions_question } from '../interfaces/dynamicQuestionnaireResponse.interface';
import Alert from 'components/alertComponent';
import { componentIds, genericActionsIds, pageIds } from 'utilities/constants';
import { useTimer } from 'hooks/useTimer';
import { FirebaseLocale } from '../../../firebase/interfaces';
import { useFirebaseSmsMfa } from '../../../firebase/hooks';
import parsePhoneNumberFromString from 'libphonenumber-js';
import StorageService from 'utilities/storageService';
import { HASURA_USER_STORAGE_KEY } from '../../../firebase/constants';
import { useGetPage } from '../../../hooks/useGetPage';
import { SECONDS_IN_MIN } from 'utilities/constants/time';
import { useGetGenericActions } from '../../../hooks/useGetGenericActions';
import { ReactComponent as ProtectIcon } from 'assets/icons/2FA.svg';
import moment from 'moment';
import { useGetComponent } from 'hooks/useGetComponent';

const hasuraUserStorage = new StorageService(HASURA_USER_STORAGE_KEY);

interface ISignUpProviderMfa {
  question: questionnaire_questions_question;
  handleInputPhoneDataChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    question: questionnaire_questions_question,
    type: 'phoneData' | 'officePhoneData',
    typeOfField: 'phone' | 'ext',
  ) => void;
  onPhoneCountryChange: (code: string) => void;
  mfaPhone: string;
  setMfaPhone: Dispatch<React.SetStateAction<string>>;
}

export const SignUpProviderMfa = ({
  question,
  handleInputPhoneDataChange,
  onPhoneCountryChange,
  mfaPhone,
  setMfaPhone,
}: ISignUpProviderMfa) => {
  const { setPhoneNumber, setCountry, user } = useContext(AuthContext);
  const recaptchaContainerWrapper = useRef<HTMLDivElement>(null);
  const [phoneError, setPhoneError] = useState<string>('');
  const [localPhoneNumber, setLocalPhoneNumber] = useState<string>('');
  const [localCountry, setLocalCountry] = useState<string>('1');
  const [disableButton, setDisableButton] = useState<boolean>(true);
  const [isVerifyOpen, setIsVerifyOpen] = useState<boolean>(false);

  const [registerPatchProviderMutation] = useRegisterPatchProviderMutation();

  const { data } = useGetCountriesQuery({});

  const { data: locale } = useGetComponent({
    locale: 'en',
    componentId: componentIds.SIGN_UP_PROVIDER_MODULE,
  });

  const mappedCountriesValues = useMemo(
    () =>
      data?.countries
        ? [...data.countries]
            .sort((a, b) => {
              const codea = parseInt(a.code);
              const codeb = parseInt(b.code);

              if (codea < codeb) {
                return -1;
              } else if (codea > codeb) {
                return 1;
              } else {
                return 0;
              }
            })
            .map((country) => {
              return { code: country.code, flag: country.flag };
            })
        : [],
    [data?.countries],
  );

  const validatePhoneNumber = (phoneNumber: string) =>
    new RegExp(exact10DigitsPattern).test(phoneNumber);

  const handleOnCountryChange = (country: SelectWithFlagsValues) => {
    setLocalCountry(country.value);

    onPhoneCountryChange(country.value);
  };

  const handleOnPhoneNumberChange = (phoneNumber: string) => {
    const normalizedPhoneNumber = normalizePhoneNumber(phoneNumber);
    const validePhoneNumber = validatePhoneNumber(normalizedPhoneNumber);
    setPhoneError(!validePhoneNumber ? locale?.mfa?.phoneError : '');
    setDisableButton(!validePhoneNumber);
    setLocalPhoneNumber(normalizedPhoneNumber);

    handleInputPhoneDataChange(
      { target: { value: phoneNumber } } as React.ChangeEvent<HTMLInputElement>,
      question,
      'phoneData',
      'phone',
    );
  };

  const handleSavePhoneNumber = async () => {
    if (!validatePhoneNumber(localPhoneNumber)) {
      setPhoneError(locale?.mfa?.phoneError);
      return;
    }

    if (localCountry) {
      setCountry(localCountry);
    }

    if (localPhoneNumber) {
      setPhoneNumber(localPhoneNumber);
    }

    setIsVerifyOpen(true);
  };

  const { data: firebaseData, loading: firebaseLocaleLoading } = useGetPage({
    locale: 'en',
    pageId: pageIds.FIREBASE_ERRORS,
  });

  const [code, setCode] = useState<string>('');
  const [isSubmittingCode, setIsSubmittingCode] = useState(false);

  const {
    timeLeft,
    temporalyDisableResendActions,
    setTimeLeft,
    setTemporalyDisableResendActions,
  } = useTimer();

  const firstRendered = useRef(false);

  const firebaseLocale: FirebaseLocale = firebaseData;

  const { isVerified, handleSendVerificationCode, handleVerifyCode, errorMsg } =
    useFirebaseSmsMfa(firebaseLocale);

  const handleOnInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const code = normalizePhoneNumber(e.target.value);

    setCode(code);
  };

  const handleSendCode = useCallback(
    async (recaptchaContainerWrapper: HTMLElement) => {
      if (!recaptchaContainerWrapper || !user?.phoneNumber || !user?.country) {
        return;
      }
      const callingCode = user.country.replace(onlyDigitsPattern, '');
      const formattedPhoneNumber = parsePhoneNumberFromString(
        user.phoneNumber,
        { defaultCallingCode: callingCode },
      );

      if (formattedPhoneNumber) {
        hasuraUserStorage.addData({ phone: formattedPhoneNumber.number });
        await handleSendVerificationCode(
          formattedPhoneNumber.number,
          'recaptcha-container',
        );
      }
    },
    [handleSendVerificationCode, user],
  );

  const handleResendCode = () => {
    if (!recaptchaContainerWrapper.current) {
      return;
    }
    setTemporalyDisableResendActions(true);
    setTimeLeft(SECONDS_IN_MIN);
    handleSendCode(recaptchaContainerWrapper.current);
  };

  const handleSubmitCode = async () => {
    setIsSubmittingCode(true);

    try {
      await handleVerifyCode(code);
    } catch (e) {
      console.error(e);
    } finally {
      setIsSubmittingCode(false);
    }
  };

  const { data: genericAction, loading: genericActionLoading } =
    useGetGenericActions({
      locale: 'en',
      genericActionId: [genericActionsIds.CONFIRM, genericActionsIds.CANCEL],
    });

  const patchProvider = useCallback(async () => {
    await registerPatchProviderMutation({
      variables: {
        providerData: {
          phone: `+${localCountry}${localPhoneNumber}`,
        },
      },
    });
  }, [registerPatchProviderMutation, localCountry, localPhoneNumber]);

  const getContent = () => {
    if (mfaPhone)
      return (
        <div className="border p-[15px] rounded-[10px] flex flex-col gap-[15px]">
          <div className="flex justify-between">
            <div className="flex items-center gap-2.5">
              <ProtectIcon />
              <p className="text-h5 font-semibold text-charcoal-gray">
                {locale?.mfa?.protectTitle}
              </p>
            </div>
            <div className="flex gap-[5px] items-center">
              <p className="text-base font-semibold text-btn-black">
                {mfaPhone}
              </p>
              <div className="text-sm font-semibold text-green-600 px-2.5 border border-green-600 rounded-3xl bg-green-50">
                {locale?.mfa?.verified}
              </div>
            </div>
            <p className="text-sm font-medium flex items-center text-med-gray-3">
              {`${locale?.mfa?.verified}: ${moment().format('ll')}`}
            </p>
          </div>
          <div className="text-sm font-medium text-med-gray-3">
            <p>{locale?.mfa?.protectText1}</p>
            <p>{locale?.mfa?.protectText2}</p>
          </div>
        </div>
      );

    if (isVerifyOpen) {
      return (
        <>
          <p className="text-base text-med-gray-3 font-medium mb-[30px]">
            {locale?.mfa?.sentText}
          </p>
          <div className="flex flex-col gap-[10px] pt-[60px] desktop:py-2">
            {errorMsg && (
              <div>
                <Alert type="warning" text={errorMsg} />
              </div>
            )}
            <div className="flex flex-col w-full desktop:max-w-[450px] desktop:mx-auto">
              <label
                className="font-base font-semibold text-dark-gray"
                htmlFor="Verification code"
              >
                {locale?.mfa?.codeLabel}
              </label>
              <div className="flex flex-row gap-2.5">
                <InputComponent
                  testID="verification-code-input"
                  type="text"
                  name="Verification Code"
                  pattern={exact6DigitsPattern}
                  onChange={handleOnInputChange}
                  value={code}
                  maxLengthValue={6}
                  placeholder={locale?.mfa?.codePlceholder}
                  customInputClassname="w-[336px]"
                />
                <ButtonComponent
                  className="h-[43px] !rounded !text-sm"
                  disabled={isSubmittingCode || !code}
                  onClick={handleSubmitCode}
                >
                  {genericAction?.[genericActionsIds.CONFIRM]?.confirm}
                </ButtonComponent>
              </div>
            </div>
            <div className="flex w-full justify-center desktop:mx-auto">
              <span className="text-med-gray mr-2">
                {locale?.mfa?.didntGetCode}
              </span>
              <ButtonComponent
                onClick={handleResendCode}
                type="underline"
                disabled={temporalyDisableResendActions}
              >
                {locale?.mfa?.sendAgain}
              </ButtonComponent>
              {temporalyDisableResendActions && (
                <span className="ml-2">{timeLeft}</span>
              )}
            </div>
          </div>
        </>
      );
    }

    if (!isVerifyOpen) {
      return (
        <>
          <p className="text-base text-med-gray-3 font-medium mb-[30px]">
            {locale?.mfa?.subtitle}
          </p>
          <MfaSmsComponent
            countries={mappedCountriesValues}
            country={localCountry}
            onCountryChange={handleOnCountryChange}
            onPhoneNumberChange={handleOnPhoneNumberChange}
            phoneNumber={localPhoneNumber}
            currentPhoneNumber={normalizePhoneNumber(user?.phoneNumber ?? '')}
            phoneError={phoneError}
          >
            <ButtonComponent
              disabled={disableButton}
              onClick={handleSavePhoneNumber}
              className="!rounded w-full desktop:w-[88px] h-[44px] flex-shrink-0 !text-sm mt-3 desktop:mt-[2px]"
            >
              {locale?.mfa?.verify}
            </ButtonComponent>
          </MfaSmsComponent>
        </>
      );
    }
  };

  useEffect(() => {
    if (
      recaptchaContainerWrapper.current &&
      !firstRendered.current &&
      !firebaseLocaleLoading &&
      firebaseLocale &&
      !!isVerifyOpen
    ) {
      firstRendered.current = true;
      setTimeLeft(SECONDS_IN_MIN);
      setTemporalyDisableResendActions(true);

      handleSendCode(recaptchaContainerWrapper.current);
    }
  }, [
    handleSendCode,
    firebaseLocaleLoading,
    firebaseLocale,
    recaptchaContainerWrapper,
    isVerifyOpen,
    setTimeLeft,
    setTemporalyDisableResendActions,
  ]);

  useEffect(() => {
    if (isVerified) {
      setMfaPhone(
        `(${localPhoneNumber.slice(0, 3)}) ${localPhoneNumber.slice(
          3,
          6,
        )}-${localPhoneNumber.slice(6, 8)}-${localPhoneNumber.slice(8, 10)}`,
      );
      patchProvider();
    }
  }, [isVerified, setMfaPhone, localPhoneNumber, patchProvider]);

  if (genericActionLoading && !genericAction) {
    return null;
  }

  return (
    <div className="mt-5 desktop:my-[30px]">
      {getContent()}
      <div className="flex justify-center" ref={recaptchaContainerWrapper}>
        <div id="recaptcha-container" />
      </div>
    </div>
  );
};
