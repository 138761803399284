import React, { useCallback, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthContextType, AuthContext } from 'auth/context/AuthContext';
import { ReactComponent as Camera } from 'assets/icons/photo-camera.svg';
import { ReactComponent as Trash } from 'assets/icons/trash.svg';
import { ReactComponent as Plus } from 'assets/icons/circledPlusIcon.svg';
import guidePhoto1 from 'assets/images/dermGuide1.png';
import guidePhoto2 from 'assets/images/dermGuide2.png';
import { ModalButtonProps } from 'components/modal/modal.interfaces';
import { genericActionsIds, pageIds } from 'utilities/constants';
import { useGetPage } from 'hooks/useGetPage';
import { useGetGenericActions } from 'hooks/useGetGenericActions';
import {
  APPOINTMENT_SUBMIT_PHOTO_SUCCESS,
  APPOINTMENT_UPLOAD_PHOTO,
  DASHBOARD,
  FACE_PHOTO,
} from 'utilities/routes';
import { Helmet } from 'react-helmet';
import Modal from 'components/modal/modalComponent';
import ButtonComponent from 'components/button/buttonComponent';
import { dataURLToFile } from 'utilities/functions';
import { useUploadFileToStorageMutation } from 'graphql/generated/hasura';

const DermGuidePhoto: React.FC<{
  src: string;
  index: number;
  text: string;
}> = ({ src, index, text }) => {
  return (
    <div className="flex gap-2 items-center">
      <div className="relative min-w-[5rem] w-20 h-20 rounded-md overflow-hidden">
        <img src={src} alt="guide-photo" />
        <span className="absolute top-0 left-0 bg-clc-blue rounded-br-md text-white text-base font-semibold flex items-center justify-center w-6 h-6">
          {index}
        </span>
      </div>
      <p className="text-sm text-med-gray-3 font-medium">{text}</p>
    </div>
  );
};

export const PhotoSubmission = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const bodySite = queryParams.get('bodySite');
  const authToken = queryParams.get('authToken');
  const { isLoggedIn } = useContext<AuthContextType>(AuthContext);
  const token = location.state?.token || '';
  const capturedPhoto = location.state?.image || '';
  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.PHOTO_SUBMISSION,
  });

  const { data: genericAction, loading: genericActionLoading } =
    useGetGenericActions({
      locale: 'en',
      genericActionId: [genericActionsIds.SUBMIT, genericActionsIds.CANCEL],
    });

  const [uploadFile, { loading: isMediaSaving }] =
    useUploadFileToStorageMutation({
      context: {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      },
    });

  const onUploadPhoto = useCallback(
    async (url: string) => {
      const file = dataURLToFile(url, 'image');
      const buffer = await file.arrayBuffer();

      const result = await uploadFile({
        variables: {
          file: {
            mimetype: file.type,
            originalname: file.name,
            buffer: Array.from(new Uint8Array(buffer)),
          },
        },
      });

      const image = result.data?.UploadFileToStorage?.file?.url;
      const mediaId = result.data?.UploadFileToStorage?.file?.fileId;

      return { image, mediaId, bodySite };
    },
    [bodySite, uploadFile],
  );

  const handlePhotoSubmission = async () => {
    await onUploadPhoto(capturedPhoto);
    navigate(`${FACE_PHOTO}${APPOINTMENT_SUBMIT_PHOTO_SUCCESS}`, {
      state: { image: capturedPhoto, token },
      replace: true,
    });
  };

  const handlePhotoRetake = () => {
    navigate(`${FACE_PHOTO}${APPOINTMENT_UPLOAD_PHOTO}${location.search}`, {
      state: { token },
      replace: true,
    });
  };

  const handleCancelSubmission = () => {
    navigate(DASHBOARD, { replace: true });
  };

  const Buttons: ModalButtonProps[] = [
    {
      label: genericAction?.[genericActionsIds.SUBMIT].submit,
      onClick: handlePhotoSubmission,
      disabled: isMediaSaving,
      className: !capturedPhoto ? 'hidden' : '',
    },
    {
      label: genericAction?.[genericActionsIds.CANCEL].cancel,
      type: 'underline',
      onClick: handleCancelSubmission,
    },
  ];

  if ((loading && !locale) || (genericActionLoading && !genericAction))
    return null;

  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <Modal
        isOpen={true}
        title={String(bodySite)}
        buttons={Buttons}
        disableMobileClose={!isLoggedIn}
        onClose={handleCancelSubmission}
        contentPaddingX="px-4 py-8"
      >
        <div>
          <p className="text-base text-med-gray-3 font-medium mb-3">
            {locale?.takePhotoText ||
              'Please take a photo of the area that you are currently experiencing symptoms on.'}
          </p>
          <p className="text-base text-neutral-800 font-semibold">
            {locale?.bestResult ||
              'For best results, please use your phone camera, and follow the guidance below.'}
          </p>

          <div className="flex flex-col gap-4 my-4">
            <DermGuidePhoto
              src={guidePhoto1}
              index={1}
              text={
                locale?.guide1 ||
                'Stand in a well lit area and ensure there are no shadows on your body. It is best to use natural light, if possible.'
              }
            />
            <DermGuidePhoto
              src={guidePhoto2}
              index={2}
              text={
                locale?.guide2 ||
                'Please remove any makeup, glasses, hats, jewelry or any other accessories, if you are currently wearing them.'
              }
            />
          </div>

          {!capturedPhoto ? (
            <div
              onClick={handlePhotoRetake}
              className="bg-frost-blue w-full h-[240px] rounded-md flex flex-col items-center justify-center gap-2 border border-dashed border-med-gray-3"
            >
              <p className="text-neutral-800 font-semibold">
                Tap to take photo
              </p>
              <Plus />
            </div>
          ) : (
            <div className="relative w-full h-[240px] rounded-md overflow-hidden">
              <img
                className="object-cover w-full h-full"
                src={capturedPhoto}
                alt={locale?.scanSubmission}
              />
              <div className="absolute bottom-0 bg-[rgba(0,0,0,.35)] flex items-center justify-between p-3 w-full">
                <ButtonComponent
                  type="outlined-transparent"
                  className="border !border-white text-white !w-32"
                  containerClassName="text-white"
                  onClick={handlePhotoRetake}
                  Icon={Camera}
                  iconPosition="left"
                >
                  {locale?.retake || 'Retake'}
                </ButtonComponent>
                <ButtonComponent
                  type="outlined-transparent"
                  className="border !border-white text-white !w-32"
                  containerClassName="text-white"
                  onClick={handlePhotoRetake}
                  Icon={Trash}
                  iconPosition="left"
                >
                  {locale?.delete || 'Delete'}
                </ButtonComponent>
              </div>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};
