import React, { useMemo, useState } from 'react';
import { useGetPage } from 'hooks/useGetPage';
import { componentIds, pageIds } from 'utilities/constants';
import StorageService from 'utilities/storageService';
import Loader from 'components/loaderComponent';
import ReasonBlock from './components/ReasonBlock';
import SymptomsBlock from './components/SymptomsBlock';
import TimeSymptoms from './components/TimeSymptoms';
import RateBlock from './components/RateBlock';
import { ISymptomsProps } from '../interface';
import { APPOINTMENT_STORAGE } from '../routePath';
import { useGetComponent } from 'hooks/useGetComponent';

interface IIssuesAndSymptomsProps {
  handleNext: (value: ISymptomsProps) => void;
}

const appointmentStorage = new StorageService(
  APPOINTMENT_STORAGE,
  sessionStorage,
);

const collapsesIdsArray = ['reason', 'symptoms', 'time', 'rate'];

const IssuesAndSymptoms: React.FC<IIssuesAndSymptomsProps> = ({
  handleNext,
}) => {
  const form = appointmentStorage.getData();
  const initialValues = useMemo(
    () => form?.selectedSymptoms || {},
    [form?.selectedSymptoms],
  );
  const [values, setValues] = useState<ISymptomsProps>(initialValues);

  const [currentCollapsesOpen, setCurrentCollapsesOpen] = useState(
    collapsesIdsArray[0],
  );
  const { data: locale, loading: localeLoading } = useGetPage({
    pageId: pageIds.APPOINTMENT_PREWORK,
    locale: 'en',
  });

  const { data: components, loading: loadingComponent } = useGetComponent({
    componentId: componentIds.APPOINTMENT_PREWORK,
    locale: 'en',
  });

  const handleContinue = (id: string, value: unknown) => {
    const index = collapsesIdsArray.indexOf(id);
    if (index < 0) {
      return;
    }
    if (index === collapsesIdsArray.length - 1) {
      return handleNext({ ...values, [id]: value });
    }
    setValues((values) => ({ ...values, [id]: value }));
    setCurrentCollapsesOpen(collapsesIdsArray[index + 1]);
  };

  const handleEdit = (id: string) => {
    const index = collapsesIdsArray.indexOf(id);
    if (index < 0) {
      return;
    }
    setCurrentCollapsesOpen(collapsesIdsArray[index]);
  };

  if (!locale || localeLoading || !components || loadingComponent) {
    return <Loader />;
  }

  return (
    <div className="px-[60px] py-[30px] bg-white rounded-md">
      <div className="flex flex-row w-full desktop:w-auto justify-between items-center">
        <h4 className="text-left text-h4 text-neutral-800 font-semibold">
          {components?.symptomsPage}
        </h4>
        <div className="text-sm font-semibold text-alert-negative">
          *Required
        </div>
      </div>

      <p className="text-base font-medium text-med-gray-3 my-4">
        {components?.symptomsPageDescriptions}
      </p>

      <hr className="mb-8" />

      <div className="flex flex-col gap-4">
        <ReasonBlock
          currentCollapsesOpen={currentCollapsesOpen}
          id={collapsesIdsArray[0]}
          handleContinue={handleContinue}
          handleEdit={handleEdit}
          initialState={initialValues[collapsesIdsArray[0]]}
        />

        <SymptomsBlock
          currentCollapsesOpen={currentCollapsesOpen}
          id={collapsesIdsArray[1]}
          handleContinue={handleContinue}
          handleEdit={handleEdit}
          initialState={initialValues[collapsesIdsArray[1]]}
        />

        <TimeSymptoms
          currentCollapsesOpen={currentCollapsesOpen}
          id={collapsesIdsArray[2]}
          handleContinue={handleContinue}
          handleEdit={handleEdit}
          initialState={initialValues[collapsesIdsArray[2]]}
        />

        <RateBlock
          currentCollapsesOpen={currentCollapsesOpen}
          id={collapsesIdsArray[3]}
          handleContinue={handleContinue}
          handleEdit={handleEdit}
          initialState={initialValues[collapsesIdsArray[3]]}
        />
      </div>
    </div>
  );
};

export default IssuesAndSymptoms;
