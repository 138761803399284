import React from 'react';
import { useGetComponent } from 'hooks/useGetComponent';
import { componentIds } from 'utilities/constants';
import SavedContentCard from '../SavedContentCard';
import { PLACE_SYMPTOMS } from '../../routePath';
import { IPlacementProps } from '../../interface';

interface IPlacementCardProps {
  selectedPlacement?: IPlacementProps;
}

const PlacementCard: React.FC<IPlacementCardProps> = ({
  selectedPlacement,
}) => {
  const mediaCount = selectedPlacement?.media?.length || 0;

  const { data: locale } = useGetComponent({
    componentId: componentIds.APPOINTMENT_PREWORK,
    locale: 'en',
  });

  if (!locale || !selectedPlacement) return null;

  return (
    <SavedContentCard
      title={locale.whereExperiencingSymptomsPage}
      link={PLACE_SYMPTOMS}
    >
      <div className="text-med-gray-3 font-medium text-base">
        <p>{!mediaCount ? 'No images added' : 'symptoms added'}</p>
      </div>
    </SavedContentCard>
  );
};

export default PlacementCard;
