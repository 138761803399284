import {
  FormatedAvailableTime,
  ProviderWeeklyWorkingHours,
} from 'graphql/generated/hasura';
import { DaysOfWeek } from './interfaces/upcomingAppointments.interfaces';
import {
  MILLISECONDS_IN_SEC,
  MINUTES_IN_HOUR,
  SECONDS_IN_MIN,
} from 'utilities/constants/time';

export const createAvailabilityTimeFromWorkingHours = (
  workingHours: ProviderWeeklyWorkingHours,
  blockedTimes: FormatedAvailableTime[],
  selectedDate: number,
): FormatedAvailableTime[] => {
  if (!workingHours.data) {
    return [];
  }
  const weekDay = new Date(selectedDate).getDay();
  const dayMapping: Record<number, DaysOfWeek> = {
    0: 'Sunday',
    1: 'Monday',
    2: 'Tuesday',
    3: 'Wednesday',
    4: 'Thursday',
    5: 'Friday',
    6: 'Saturday',
  };

  const dayName = dayMapping[weekDay];
  if (!dayName && process.env.REACT_APP_IS_DEBUG_MODE) {
    throw new Error(`Unexpected day of week: ${weekDay}`);
  }

  const intervals = workingHours.data?.[dayName]?.map((range) =>
    generateTimeIntervals(range, selectedDate),
  );

  return applyBlocksToWorkingHours(intervals?.flat() || [], blockedTimes);
};

const applyBlocksToWorkingHours = (
  workingHours: FormatedAvailableTime[],
  blockedTimes: FormatedAvailableTime[],
): FormatedAvailableTime[] => {
  const newWorkingHours = [...workingHours];
  const workingHoursIndexexToBlock: number[] = [];
  for (const blockedTime of blockedTimes) {
    const blockedTimeStart = new Date(blockedTime.start).getTime();
    const blockedTimeEnd = new Date(blockedTime.end).getTime();
    workingHours.forEach((workingHour, index) => {
      const workingHoursStart = new Date(workingHour.start).getTime();
      const workingHoursEnd = new Date(workingHour.end).getTime();
      if (
        (blockedTimeStart >= workingHoursStart &&
          blockedTimeStart < workingHoursEnd) ||
        (blockedTimeEnd > workingHoursStart &&
          blockedTimeEnd <= workingHoursEnd)
      ) {
        workingHoursIndexexToBlock.push(index);
      }
    });
  }
  for (const index of workingHoursIndexexToBlock) {
    newWorkingHours[index].enabled = false;
  }
  return newWorkingHours;
};

const convertTo24Hour = (time: string) => {
  const [hours, minutes, period] =
    time.match(/(\d+):(\d+)([ap]m)/)?.slice(1) ?? [];
  let adjustedHours = parseInt(hours, 10);
  if (period === 'pm' && adjustedHours !== 12) {
    adjustedHours += 12;
  }
  if (period === 'am' && adjustedHours === 12) {
    adjustedHours = 0;
  }
  return { hours: adjustedHours, minutes: parseInt(minutes, 10) };
};

const formatDate = (hours: number, minutes: number, selectedDate: number) => {
  const formattedMinutes = minutes.toString().padStart(2, '0');
  const date = new Date(selectedDate);
  date.setHours(hours, Number(formattedMinutes), 0, 0);
  return date;
};

const generateTimeIntervals = (
  time: string,
  selectedDate: number,
): FormatedAvailableTime[] => {
  const [startTime, endTime] = time.split('-');
  const start = convertTo24Hour(startTime);

  const end = convertTo24Hour(endTime);
  const intervals: FormatedAvailableTime[] = [];
  let currentHours = start.hours;
  let currentMinutes = start.minutes;

  while (
    currentHours < end.hours ||
    (currentHours === end.hours && currentMinutes < end.minutes)
  ) {
    let intervalEndMinutes = currentMinutes + 30;
    let intervalEndHours = currentHours;

    if (intervalEndMinutes >= 60) {
      intervalEndMinutes -= 60;
      intervalEndHours += 1;
    }

    if (
      intervalEndHours > end.hours ||
      (intervalEndHours === end.hours && intervalEndMinutes > end.minutes)
    ) {
      break;
    }

    intervals.push({
      start: formatDate(
        currentHours,
        currentMinutes,
        selectedDate,
      ).toISOString(),
      end: formatDate(
        intervalEndHours,
        intervalEndMinutes,
        selectedDate,
      ).toISOString(),
      enabled: true,
    });

    currentHours = intervalEndHours;
    currentMinutes = intervalEndMinutes;
  }

  return intervals;
};

export const convertTo12Hourformat = (time: string) => {
  const date = new Date(time);

  const timeStr = date.toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  });

  return timeStr;
};

export const convertToFullInfoFormat = (time: string) => {
  const date = new Date(time);

  const formatter = new Intl.DateTimeFormat('en-US', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
  });

  return formatter.format(date);
};

export const getTimeDifferenceInHours = (
  startTime: string,
  endTime: string,
) => {
  const start = new Date(startTime);
  const end = new Date(endTime);

  const differenceInMs = start.getTime() - end.getTime();

  return (
    differenceInMs / (MILLISECONDS_IN_SEC * SECONDS_IN_MIN * MINUTES_IN_HOUR)
  );
};

export const parseRawWorkingHours = (
  rawWorkingHours: FormatedAvailableTime[],
): string[] => {
  return rawWorkingHours.map((time) => {
    if (!time.enabled) {
      return 'not available';
    }
    return `${convertTo12Hourformat(time.start)} - ${convertTo12Hourformat(
      time.end,
    )}`;
  });
};
