import { useCamera } from './useCamera';
import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import {
  ScreenOrientation,
  useScreenOrientation,
} from './useScreenOrientation';

interface UseInitializeCamera {
  submitPhotoUrl: string;
  token: string;
}

export const useInitializeCamera = ({
  submitPhotoUrl,
  token,
}: UseInitializeCamera) => {
  const navigate = useNavigate();

  const {
    videoRef,
    canvasRef,
    cameraFacingMode,
    switchCameraFacingMode,
    isTorchSupported,
    supportsFacingMode,
    captureImage,
    toggleFlash,
    initializeCamera,
    stopCamera,
    isLoading,
    error,
  } = useCamera();

  const handleCaptureImage = () => {
    const image = captureImage();
    if (image) {
      stopCamera();
      navigate(submitPhotoUrl, {
        state: { image, token },
        replace: true,
      });
    }
  };

  const onOrientationChange = useCallback(
    (orientation: ScreenOrientation) => {
      switch (orientation) {
        case ScreenOrientation.Portrait:
          initializeCamera();
          break;
        case ScreenOrientation.Landscape:
          stopCamera();
          break;
        default:
          console.log(`Unexpected orientation: ${orientation}`);
          stopCamera();
          break;
      }
    },
    [initializeCamera, stopCamera],
  );

  const { screenOrientation } = useScreenOrientation(onOrientationChange);

  return {
    videoRef,
    canvasRef,
    cameraFacingMode,
    switchCameraFacingMode,
    isTorchSupported,
    supportsFacingMode,
    isLoading,
    error,
    toggleFlash,
    handleCaptureImage,
    screenOrientation,
    initializeCamera,
  };
};
