import React, { useCallback, useEffect, useState } from 'react';
import { useGetComponent } from 'hooks/useGetComponent';
import { componentIds } from 'utilities/constants';
import {
  useGetProviderAvailabilityMonthDatesLazyQuery,
  useGetProviderAvailabilityServiceTypesLazyQuery,
  useGetProviderAvailabilityTimeSlotsLazyQuery,
} from 'graphql/generated/hasura';
import Collapse from '../../scheduled-a-appointment/components/Collapse';
import { selectedDateFormat } from 'utilities/functions';
import CustomMonthDatepicker from 'components/react-datapicker/CustomMonthDatepicker';
import CarouselSelectDays from '../../scheduled-a-appointment/components/CarouselSelectDays';
import TimeSlotsContainer from '../../scheduled-a-appointment/components/TimeSlotsContainer';
import {
  AppointmentDetails,
  useGetAppointments,
} from '../../../pages/payments/hook';
import { createSearchParams, useNavigate, useParams } from 'react-router-dom';
import Loader from 'components/loaderComponent';
import { useRescheduleAppointmentMutation } from 'graphql/generated/remote-schema-hasura';
import { MY_APPOINTMENTS_RESCHEDULE_AN_APPOINTMENT } from 'utilities/routes';
import { LAST_START_TIME } from '../../../const/appointment';

const collapsesIdsArray = ['day', 'slot'];

const RescheduleAppointment = () => {
  const { data: locale } = useGetComponent({
    componentId: componentIds.APPOINTMENT_PREWORK,
    locale: 'en',
  });
  const navigate = useNavigate();
  const { appointmentId } = useParams();

  const [loading, setLoading] = useState(false);

  const [appointment, setAppointment] = useState<AppointmentDetails>();

  const [days, setDays] = useState<Date[]>([]);
  const [slots, setSlots] = useState<string[]>([]);
  const [monthDate, setMonths] = useState<Date | null>(new Date());

  const [selectedDay, setSelectedDay] = useState<Date | null>(null);
  const [selectedTime, setSelectedTime] = useState<string>('');

  const [currentCollapsesOpen, setCurrentCollapsesOpen] = useState(
    collapsesIdsArray[0],
  );

  const [rescheduleAppointment, { loading: patchAppointmentLoading }] =
    useRescheduleAppointmentMutation();

  const handleAppointmentReschedule = useCallback(
    (time: string) => {
      return rescheduleAppointment({
        variables: {
          appointmentCodexId: String(appointmentId),
          startAt: time,
        },
      }).then(() => {
        navigate({
          pathname: `${MY_APPOINTMENTS_RESCHEDULE_AN_APPOINTMENT}/${appointmentId}/success`,
          search: createSearchParams({
            [LAST_START_TIME]: String(appointment?.start),
          }).toString(),
        });
      });
    },
    [appointment?.start, appointmentId, navigate, rescheduleAppointment],
  );

  const handleContinue = (id: string) => {
    const index = collapsesIdsArray.indexOf(id);
    if (index < 0) {
      return;
    }
    if (index === collapsesIdsArray.length - 1 && selectedDay) {
      return handleAppointmentReschedule(selectedTime);
    }
    setCurrentCollapsesOpen(collapsesIdsArray[index + 1]);
  };

  const handleEdit = (id: string) => {
    const index = collapsesIdsArray.indexOf(id);
    if (index < 0) {
      return;
    }
    setCurrentCollapsesOpen(collapsesIdsArray[index]);
  };

  const getAppointment = useGetAppointments(String(appointmentId));

  const [getAcuityProviderMonthDates] =
    useGetProviderAvailabilityMonthDatesLazyQuery({
      fetchPolicy: 'network-only',
    });

  const [getAcuityProviderTimeSlots] =
    useGetProviderAvailabilityTimeSlotsLazyQuery({
      fetchPolicy: 'network-only',
      onCompleted(data) {
        if (!data?.GetProviderAvailabilityTimeSlots?.timeSlots) {
          return setSlots([]);
        }
        const times = data.GetProviderAvailabilityTimeSlots.timeSlots.map(
          ({ time }) => time,
        );
        return setSlots(times);
      },
    });

  const [getProviderTypes] = useGetProviderAvailabilityServiceTypesLazyQuery({
    fetchPolicy: 'network-only',
  });

  const onMonthChange = useCallback(
    async (date: Date | null) => {
      if (!date) {
        return null;
      }
      const month = date.toLocaleDateString('en-US', {
        month: 'short',
        year: 'numeric',
      });

      setSelectedTime('');
      setSelectedDay(null);
      const { data } = await getAcuityProviderMonthDates({
        variables: {
          appointmentTypeId: String(appointment?.appointmentTypeId),
          providerCalendarId: appointment?.calendarId,
          month,
        },
      });
      const dates = data?.GetProviderAvailabilityMonthDates.dates || [];
      setMonths(date);
      setDays(dates.map((day) => new Date(day)));
      return date;
    },
    [
      appointment?.appointmentTypeId,
      appointment?.calendarId,
      getAcuityProviderMonthDates,
    ],
  );

  const onSelectDay = (day: Date) => {
    setSelectedTime('');
    setSelectedDay(day);

    const date = day.toLocaleDateString('en-US', {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
    });

    getAcuityProviderTimeSlots({
      variables: {
        appointmentTypeId: String(appointment?.appointmentTypeId),
        date,
        providerCalendarId: appointment?.calendarId,
      },
    });
  };

  const onSelectedTime = (time: string) => {
    setSelectedTime(time);
  };

  const getData = useCallback(async (): Promise<AppointmentDetails> => {
    const appointment = await getAppointment();
    const { data } = await getProviderTypes({
      variables: { providerCalendarId: String(appointment.calendarId) },
    });
    const types = data?.GetProviderAvailabilityServiceTypes?.serviceTypes || [];
    const date = new Date(String(appointment?.start));
    await onMonthChange(date);
    setSelectedDay(date);
    return { ...appointment, appointmentTypeId: types[0]?.id?.toString() };
  }, [getAppointment, getProviderTypes, onMonthChange]);

  useEffect(() => {
    if (appointmentId) {
      setLoading(true);
      getData()
        .then((data) => setAppointment(data))
        .finally(() => setLoading(false))
        .catch((error) => console.error(error));
    }
  }, [appointmentId, getData]);

  if (!locale) {
    return null;
  }

  return (
    <>
      <div className="px-7 py-[30px] desktop:pt-0">
        <div className="flex flex-col gap-4 desktop:gap-2">
          <p
            className="text-h2 text-charcoal-gray font-medium desktop:text-h1"
            data-testid="appointment-reschedule-page"
          >
            Reschedule appointment
          </p>
          <p className="text-base font-medium text-med-gray">
            Need to make a change? You can easily reschedule your appointment to
            a more convenient date and time.
          </p>
        </div>
      </div>

      <div className="px-[60px] py-[30px] bg-white rounded-md">
        <div className="flex flex-row w-full desktop:w-auto justify-between items-center">
          <h4 className="text-left text-h4 text-charcoal-gray font-semibold">
            {locale?.appointmentDetailsPage}
          </h4>
          <div className="text-sm font-semibold text-alert-negative">
            *Required
          </div>
        </div>

        <p className="text-base font-medium text-med-gray-3 my-4">
          If you need to change your appointment, you can select a new date and
          time that works better for you. To begin, choose a new date if
          necessary, and then select a new time. If you only need to change the
          time, keep the current date and pick a new time that suits you.
        </p>

        <hr />

        <div className="flex flex-col gap-4 mt-6">
          <Collapse
            handleContinue={handleContinue}
            handleEdit={handleEdit}
            currentCollapsesOpen={currentCollapsesOpen}
            id={collapsesIdsArray[0]}
            title={locale?.appointmentDetails?.appointmentDate}
            disabled={!selectedDay}
            savedData={selectedDay && selectedDateFormat(selectedDay)}
          >
            {loading ? (
              <Loader />
            ) : (
              <>
                <CustomMonthDatepicker
                  testID="month-datepicker"
                  onChange={onMonthChange}
                  startDate={monthDate}
                  className="mb-2"
                />
                {!!days.length && (
                  <CarouselSelectDays
                    days={days}
                    onSelect={onSelectDay}
                    onMonthChange={onMonthChange}
                    selectedDay={selectedDay}
                  />
                )}
              </>
            )}
          </Collapse>
          <Collapse
            handleContinue={handleContinue}
            handleEdit={handleEdit}
            currentCollapsesOpen={currentCollapsesOpen}
            id={collapsesIdsArray[1]}
            title={locale?.appointmentDetails?.appointmentTime}
            disabled={!selectedTime || patchAppointmentLoading}
            savedData={null}
            btnTitle="Reschedule"
          >
            <TimeSlotsContainer
              times={slots}
              date={selectedDay}
              onSelect={onSelectedTime}
              selectedTime={selectedTime}
            />
          </Collapse>
        </div>
      </div>
    </>
  );
};

export default RescheduleAppointment;
