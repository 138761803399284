import React, { useEffect, useState } from 'react';
import { ReactComponent as Fail } from 'assets/icons/big-warning.svg';
import { useGetComponent } from 'hooks/useGetComponent';
import { componentIds } from 'utilities/constants';
import Loader from 'components/loaderComponent';
import ButtonComponent from '../../../../components/button/buttonComponent';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { DASHBOARD } from 'utilities/routes';
import { AppointmentDetails, useGetAppointments } from './hook';
import { getAppointmentFullTimeDate } from 'utilities/dateHelper';

const AppointmentPaymentFailed = () => {
  const [url] = useSearchParams();
  const navigate = useNavigate();

  const appointmentId = url.get('appointment_id');
  const [appointment, setAppointment] = useState<AppointmentDetails>();

  const { data: locale, loading: localeLoading } = useGetComponent({
    locale: 'en',
    componentId: componentIds.APPOINTMENT_PREWORK,
  });

  const getData = useGetAppointments(appointmentId);

  const handleGoHome = () => navigate(DASHBOARD);

  useEffect(() => {
    getData()
      .then((data) => setAppointment(data))
      .catch((error) => console.error(error));
  }, [getData]);

  if (!locale || localeLoading || !appointment) {
    return <Loader />;
  }

  return (
    <div className="flex items-center justify-center flex-col w-full p-5 desktop:py-20 desktop:px-16 gap-8 bg-white rounded-lg">
      <Fail />
      <h4 className="desktop:text-h1 text-h3 font-medium text-center max-w-xl text-charcoal-gray">
        {locale?.payments?.paymentFailed}
      </h4>

      <div className="desktop:p-8 p-5 rounded-lg flex items-center justify-center flex-col max-w-xl bg-neutral-100">
        <p className="text-base font-medium text-charcoal-gray max-w-sm text-center">{`${locale?.payments?.failedParagraph} ${appointment.appointmentType} with ${appointment.providerFirstName} ${appointment.providerLastName} on`}</p>
        <p className="font-semibold text-charcoal-gray text-h3 mt-2.5 mb-5 text-center">
          {getAppointmentFullTimeDate(String(appointment?.start))}
        </p>

        <ButtonComponent
          fullWidthClassName="w-auto"
          className="!text-sm !px-5 !py-3 !rounded-[5px]"
        >
          {locale?.payments?.retryPayment}
        </ButtonComponent>

        <ButtonComponent
          type="underline"
          className="mt-6 !text-sm !font-bold"
          onClick={handleGoHome}
        >
          {locale?.payments?.toHomePage}
        </ButtonComponent>
        <p className="text-sm text-med-gray-3 font-medium text-center mt-8">
          {locale?.payments?.paymentDetailsDescription}
        </p>
      </div>
    </div>
  );
};

export default AppointmentPaymentFailed;
