import React, { useCallback, useEffect, useState } from 'react';
import { ReactComponent as WhiteCheckedProfileIcon } from 'assets/icons/white-checked-profile.svg';
import SavedContentCard from '../SavedContentCard';
import {
  IScheduledAnAppointmentFormProps,
  ISelectedProviderProps,
} from '../../interface';
import { useGetFhirProviderByCodexIdLazyQuery } from 'graphql/generated/remote-schema-hasura';
import { useGetSignUrlFromStorageForRequesterMutation } from 'graphql/generated/hasura';
import { componentIds } from 'utilities/constants';
import { APPOINTMENT_STORAGE, FIND_A_PROVIDER } from '../../routePath';
import Avatar from 'components/avatarComponent';
import { useGetComponent } from 'hooks/useGetComponent';
import StorageService from 'utilities/storageService';

interface IProviderCard {
  selectedProvider?: ISelectedProviderProps;
}

interface IProvider {
  senderPicture?: string;
  name?: string;
  education?: string;
}

const appointmentStorage = new StorageService(
  APPOINTMENT_STORAGE,
  sessionStorage,
);

const ProviderCard: React.FC<IProviderCard> = ({ selectedProvider }) => {
  const { codexProviderId } = selectedProvider || {};
  const [provider, setProvider] = useState<IProvider>();
  const form = appointmentStorage.getData() as IScheduledAnAppointmentFormProps;

  const { data: locale } = useGetComponent({
    componentId: componentIds.APPOINTMENT_PREWORK,
    locale: 'en',
  });

  const editCallback = () => {
    appointmentStorage.setData({ ...form, selectedAppointment: undefined });
  };

  const [getProvider] = useGetFhirProviderByCodexIdLazyQuery();
  const [getFileUrlFromStorageForRequester] =
    useGetSignUrlFromStorageForRequesterMutation({});

  const getProviderData = useCallback(
    async (codexProviderId: string) => {
      const { data } = await getProvider({
        variables: { codexProviderId },
      });
      const provider =
        data?.getFHIRProviderbyCodexIdArgument.providers[0]?.providers;
      let profileImage = undefined;
      if (!!provider && provider?.SENSITIVE_profile_picture_id) {
        profileImage = await getFileUrlFromStorageForRequester({
          variables: {
            fileId: provider.SENSITIVE_profile_picture_id,
            requestedUserId: codexProviderId,
          },
        });
      }
      return {
        ...provider,
        senderPicture:
          profileImage?.data?.GetSignUrlFromStorageForRequester?.url,
      };
    },
    [getFileUrlFromStorageForRequester, getProvider],
  );

  useEffect(() => {
    if (codexProviderId) {
      getProviderData(codexProviderId)
        .then((data) => {
          const {
            senderPicture,
            SENSITIVE_firstname,
            SENSITIVE_lastname,
            SENSITIVE_education = [],
          } = data;
          setProvider({
            senderPicture: senderPicture,
            name: `${SENSITIVE_firstname} ${SENSITIVE_lastname}`,
            education: SENSITIVE_education[0]?.medicalSchool,
          });
        })
        .catch((error) => console.error(error));
    }
  }, [getProviderData, codexProviderId]);

  if (!selectedProvider || !locale) {
    return null;
  }

  return (
    <SavedContentCard
      title={locale.provider}
      link={FIND_A_PROVIDER}
      editCallback={editCallback}
    >
      <div className="flex items-center gap-3">
        <Avatar imageUrl={provider?.senderPicture} size="sm_md" />
        <div className="flex flex-col">
          <div className="text-neutral-800 text-base font-semibold flex items-center">
            <span>{provider?.name}</span>
            <WhiteCheckedProfileIcon className="w-[14px] h-[14px] ml-1" />
          </div>
          <div className="text-sm font-medium text-med-gray-3">
            {provider?.education}
          </div>
        </div>
      </div>
    </SavedContentCard>
  );
};

export default ProviderCard;
