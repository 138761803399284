import {
  IAppointmentDetailsData,
  IPlacementProps,
  IScheduledAnAppointmentFormProps,
  ISelectedProviderProps,
  ISymptomsProps,
} from '../components/scheduled-a-appointment/interface';
import { FhirAppointmentStatuses } from '../interfaces';
import { CreateScheduledAppointmentInput } from 'graphql/generated/hasura';

const convertCalendarId = (calendarId: string | null | undefined) => {
  if (!calendarId) {
    return 0;
  }
  return +calendarId;
};

export const appointmentConstructor = (
  data: IScheduledAnAppointmentFormProps,
): CreateScheduledAppointmentInput => {
  const {
    selectedAppointment,
    selectedProvider,
    selectedPlacement,
    selectedSymptoms,
  } = data;

  const { calendarId } = selectedProvider as ISelectedProviderProps;
  const { reason, time, rate, symptoms } = selectedSymptoms as ISymptomsProps;
  const { media = [] } = selectedPlacement as IPlacementProps;
  const { type, timeSlot } = selectedAppointment as IAppointmentDetailsData;

  const mediaToInsert = media.map(({ location, mediaId, description }) => ({
    bodySite: location,
    description,
    mediaId,
  }));
  const symptomsLocation = media.map(({ location }) => location);

  return {
    appointmentData: {
      subjectOfAppointment: reason,
      appointmentNotes: {
        anythingElseQuestion: rate.anythingElseQuestion || '',
        howLongQuestion: time || '',
        severityQuestion: rate.severity || '',
        symptomsTypes: symptoms || '',
      },
      mediaToInsert,
      symptomsLocation,
      startAt: timeSlot,
      status: 'pending' as FhirAppointmentStatuses,
    },
    appointmentTypeId: type.id,
    calendarId: convertCalendarId(calendarId),
    datetime: timeSlot,
  };
};
