import { FHIR_APPOINTMENT_STATUS } from 'utilities/interfaces';

const STATUS_CLASS = {
  [FHIR_APPOINTMENT_STATUS.ARRIVED]: '',
  [FHIR_APPOINTMENT_STATUS.BOOKED]: '',
  [FHIR_APPOINTMENT_STATUS.CHECKEDIN]: '',
  [FHIR_APPOINTMENT_STATUS.FULFILLED]: '',
  [FHIR_APPOINTMENT_STATUS.NOSHOW]: '',
  [FHIR_APPOINTMENT_STATUS.CANCELLED]: '',
  [FHIR_APPOINTMENT_STATUS.WAITLIST]: '',
  [FHIR_APPOINTMENT_STATUS.PENDING]:
    'text-yellow-500 border-yellow-500 bg-yellow-50',
  [FHIR_APPOINTMENT_STATUS.PROPOSED]:
    'text-clc-blue border-clc-blue bg-cyan-50',
  [FHIR_APPOINTMENT_STATUS.ENTEREDINERROR]: '',
};

export const getStatusClassName = (status: FHIR_APPOINTMENT_STATUS) => {
  const baseClass =
    'text-sm font-semibold border px-3 min-w-[6rem] rounded-full';
  const className = STATUS_CLASS[status];
  return `${baseClass} ${className}`;
};
