import React, { useContext, useMemo } from 'react';
import { ReactComponent as Search } from 'assets/icons/search.svg';
import { ReactComponent as Filter } from 'assets/icons/filter.svg';
import { useMyAppointmentsLocale } from '../../pages/MyAppointments';
import { AppointmentHistoryContext } from '../AppointmentHistory';
import TableContent from 'components/codexTable/TableContent';
import {
  columns,
  keyMap,
  customClassesDesktop,
  excludeSort,
  FILTER_BLOCK_ID,
} from './tableKeyMap';
import { AppointmentTypeEnum, Order_By } from 'graphql/generated/hasura';
import PaginateBlock from 'components/PaginateBlock';
import { Record_Order_By } from 'components/codexTable/interfaces/record.interfaces';
import SearchInput from 'components/SearchInput';
import EmptyStateComponent from 'components/EmptyStateComponent';
import ButtonComponent from 'components/button/buttonComponent';
import PastAppointmentFilter, { IFilterForm } from './Filter';
import PastAppointmentMobileView from './PastAppointmentMobileView';
import Loader from 'components/loaderComponent';

const PastAppointmentWrapper = () => {
  const { locale, loading } = useMyAppointmentsLocale();
  const [openFilter, setOpenFilter] = React.useState(false);

  const handleFilterClick = () => setOpenFilter((open) => !open);

  const {
    data,
    loading: dataLoading,
    total,
    setVars,
    variables,
  } = useContext(AppointmentHistoryContext);

  const historicAppointmentsOrderBy = useMemo<Record_Order_By>(() => {
    if (!variables.filter.sort || variables.filter.sortDirection === undefined)
      return {};
    return {
      [variables.filter.sort]: variables.filter.sortDirection
        ? Order_By.Asc
        : Order_By.Desc,
    };
  }, [variables]);

  const hasShowPaginate = useMemo(
    () => total > variables.limit,
    [total, variables.limit],
  );

  const setNewOffset = (offset: number) =>
    setVars((vars) => ({ ...vars, offset }));

  const handleSortChange = (nextSort: Record_Order_By) => {
    return setVars((vars) => ({
      ...vars,
      filter: {
        ...vars.filter,
        sort: Object.keys(nextSort)[0],
        sortDirection:
          nextSort[Object.keys(nextSort)[0]] === Order_By.Asc ? 0 : 1,
      },
    }));
  };

  const handleOnChange = (find: string) => {
    return setVars((vars) => ({
      ...vars,
      offset: 0,
      filter: {
        ...vars.filter,
        find,
      },
    }));
  };

  const handleFilterChange = (filter: IFilterForm) => {
    const { to, from, appointmentStatus, type: typeSelect } = filter;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const type = typeSelect?.value as AppointmentTypeEnum;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const status = appointmentStatus?.value as AppointmentStatusFilterOptions;

    return setVars((vars) => ({
      ...vars,
      offset: 0,
      filter: {
        ...vars.filter,
        to,
        from,
        type,
        appointmentStatus: status ? [status] : undefined,
      },
    }));
  };

  const paginateSubtitle = useMemo(
    () =>
      `${locale?.showing} ${variables.offset + 1}-${
        variables.offset + variables.limit
      } ${locale?.appointments} ${locale?.from} ${total}`,
    [locale, total, variables],
  );

  if (!locale || loading || dataLoading) {
    return <Loader />;
  }

  if (
    !data?.length &&
    !variables?.filter?.find &&
    !variables?.filter?.from &&
    !variables?.filter?.to &&
    !variables?.filter?.type &&
    !variables?.filter?.appointmentStatus?.length
  ) {
    return (
      <div className="p-8 bg-white rounded-xl">
        <div className="flex items-center gap-4">
          <h3 className="text-h3 text-neutral-800 font-semibold">
            {locale.historicAppointmentsCardTitle}
          </h3>
          <span className="text-neutral-800 text-sm font-semibold rounded-md desktop:ml-2 ml-auto px-2 py-1 bg-[rgba(0,0,0,.05)]">
            {total}
          </span>
        </div>
        <div className="px-7 flex grow justify-center items-center desktop:min-h-[400px]">
          <EmptyStateComponent
            titleClassName="font-medium text-charcoal-gray"
            title={locale.noHistoricAppointment}
            text={locale.noHistoricAppointmentDescription}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white rounded-xl p-8 gap-8">
      <div
        className="grid grid-cols-12 items-center gap-4 mb-4 relative"
        id={FILTER_BLOCK_ID}
      >
        <div className="col-span-full flex">
          <h3 className="text-h3 text-neutral-800 font-semibold">
            {locale.historicAppointmentsCardTitle}
          </h3>
          <span className="text-neutral-800 text-sm font-semibold rounded-md desktop:ml-2 ml-auto px-2 py-1 bg-[rgba(0,0,0,.05)]">
            {total}
          </span>
        </div>

        <div className="flex mb-7 gap-4 col-span-full desktop:items-center justify-between w-full">
          <div className="relative w-full max-w-[690px]">
            <SearchInput
              testID="search-past-appointment"
              handleOnChange={handleOnChange}
              placeholder={
                locale.searchPlaceholder || 'Search by provider name'
              }
              inputClassName="placeholder:not-italic placeholder:font-medium"
              className="pl-7"
            />
            <Search className="absolute top-1/2 -translate-y-1/2 left-0 w-[40px] fill-med-gray-3" />
          </div>
          <ButtonComponent
            Icon={Filter}
            iconPosition="left"
            iconWidth="w-[20px]"
            className="ml-auto !text-sm max-desktop:mb-auto max-desktop:w-auto !p-3 !border"
            onClick={handleFilterClick}
            type={openFilter ? undefined : 'outlined-transparent'}
          >
            <span className="max-desktop:hidden">{locale?.filter}</span>
          </ButtonComponent>
        </div>

        <PastAppointmentFilter
          open={openFilter}
          setOpen={setOpenFilter}
          handleFilterChange={handleFilterChange}
          defaultFilter={variables.filter}
        />
      </div>

      {data.length ? (
        <>
          <div className="hidden max-desktop:flex flex-col gap-4">
            {data.map((appointment) => (
              <PastAppointmentMobileView
                key={appointment.id}
                {...appointment}
                locale={locale}
              />
            ))}
          </div>
          <TableContent
            columnKeys={columns as unknown as string[]}
            data={data}
            keyMap={keyMap}
            excludeSort={excludeSort}
            onColumnSortChange={handleSortChange}
            orderBy={historicAppointmentsOrderBy}
            customClasses={customClassesDesktop}
            locale={locale}
            noDataMessage={locale.noHistoricAppointment}
            theadClassName="bg-base-content"
          />
        </>
      ) : (
        <div className="px-7 bg-white rounded-xl flex grow justify-center items-center desktop:min-h-[300px]">
          <EmptyStateComponent
            titleClassName="font-exo font-medium text-charcoal-gray"
            title={locale.noFind}
            text={locale.noFindDescription}
          />
        </div>
      )}

      {hasShowPaginate && (
        <div className="flex max-desktop:flex-col gap-4 justify-between items-center mt-7">
          <p className="text-sm text-med-gray-3">{paginateSubtitle}</p>
          <PaginateBlock
            itemsPerPage={variables.limit}
            totalItems={total}
            setNewOffset={setNewOffset}
            offsetParams="pastAppointmentsOffset"
          />
        </div>
      )}
    </div>
  );
};

export default PastAppointmentWrapper;
