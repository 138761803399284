import React from 'react';
import { ReactComponent as Edit } from 'assets/icons/edit.svg';
import ButtonComponent from 'components/button/buttonComponent';
import { useNavigate } from 'react-router-dom';

interface ISavedContentCard {
  title: string;
  link: string;
  editCallback?: () => void;
  children: React.ReactNode;
}

const SavedContentCard: React.FC<ISavedContentCard> = ({
  title,
  link,
  editCallback = () => ({}),
  children,
}) => {
  const navigate = useNavigate();

  const handleEdit = (link: string) => {
    editCallback();
    navigate(link, { state: { skipBlocker: true } });
  };

  return (
    <div className="p-8 pt-5 pb-6 rounded-lg bg-white flex flex-col gap-1 relative">
      <div className="flex items-center justify-between">
        <span className="text-neutral-800 font-semibold text-h4">{title}</span>
        <ButtonComponent
          className="absolute top-1 right-1 p-0 bg-transparent hover:rounded-full hover:!bg-light-blue hover:!fill-clc-blue hover:!text-clc-blue"
          type="icon"
          Icon={Edit}
          testID={`navigate-${link}`}
          onClick={() => handleEdit(link)}
        />
      </div>
      {children}
    </div>
  );
};

export default SavedContentCard;
