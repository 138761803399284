import React, { useMemo } from 'react';
import { useMyAppointmentsLocale } from '../../pages/MyAppointments';
import { useUpcomingAppointments } from '../UpcomingAppointments';
import UpcomingAppointmentCard from './UpcomingAppointmentCard';
import PaginateBlock from 'components/PaginateBlock';
import EmptyStateComponent from 'components/EmptyStateComponent';
import ButtonComponent from 'components/button/buttonComponent';
import { MY_APPOINTMENTS_SCHEDULE_AN_APPOINTMENT } from 'utilities/routes';
import { useNavigate } from 'react-router-dom';
import CancelAppointmentModal from '../modalContent/CancelAppointmentModal';
import Loader from 'components/loaderComponent';

const UpcomingAppointmentsWrapper = () => {
  const { locale, loading: localeLoading } = useMyAppointmentsLocale();
  const navigate = useNavigate();

  const {
    data: upcomingAppointments,
    total,
    variables,
    setVars,
    loading,
  } = useUpcomingAppointments();

  const paginateSubtitle = useMemo(
    () =>
      `${locale?.showing} ${variables.offset + 1}-${
        variables.offset + variables.limit
      } ${locale?.appointments} ${locale?.from} ${total}`,
    [locale, total, variables],
  );

  const hasShowPaginate = useMemo(
    () => total > variables.limit,
    [total, variables.limit],
  );

  const setNewOffset = (offset: number) =>
    setVars((vars) => ({ ...vars, offset }));

  const handleScheduleAnAppointment = () => {
    navigate(MY_APPOINTMENTS_SCHEDULE_AN_APPOINTMENT);
  };

  if (loading || localeLoading) {
    return <Loader />;
  }

  if (!upcomingAppointments?.length) {
    return (
      <div className="p-8 bg-white rounded-xl flex flex-col gap-8">
        <div className="flex items-center gap-4">
          <h3 className="text-h3 text-neutral-800 font-semibold">
            {locale.upcomingAppointmentsCardTitle}
          </h3>
          <span className="text-neutral-800 text-sm font-semibold rounded-md px-2 py-1 bg-[rgba(0,0,0,.05)]">
            {total}
          </span>
        </div>
        <div className="px-7 flex grow justify-center items-center">
          <EmptyStateComponent
            ctaButton={
              <ButtonComponent
                className="!text-sm rounded mt-4 desktop:mt-8"
                onClick={handleScheduleAnAppointment}
              >
                {locale.scheduleAnAppointment}
              </ButtonComponent>
            }
            titleClassName="font-medium text-charcoal-gray"
            title={locale.noResultsTitle}
            text={locale.noResults}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white rounded-xl p-8 gap-8">
      <div className="flex items-center gap-4">
        <h3 className="text-h3 text-neutral-800 font-semibold">
          {locale.upcomingAppointmentsCardTitle}
        </h3>
        <span className="text-neutral-800 text-sm font-semibold rounded-md px-2 py-1 bg-[rgba(0,0,0,.05)]">
          {total}
        </span>
      </div>

      <div className="flex flex-col mt-4 gap-4">
        {upcomingAppointments.map((appointment) => (
          <UpcomingAppointmentCard
            appointment={appointment}
            locale={locale}
            key={appointment.appointmentId}
          />
        ))}
      </div>

      {hasShowPaginate && (
        <div className="flex justify-between items-center mt-2">
          <p className="hidden desktop:block text-sm text-med-gray-3">
            {paginateSubtitle}
          </p>
          <PaginateBlock
            itemsPerPage={variables.limit}
            totalItems={total}
            setNewOffset={setNewOffset}
            offsetParams="upcomingAppointmentsOffset"
          />
        </div>
      )}

      <CancelAppointmentModal />
    </div>
  );
};

export default UpcomingAppointmentsWrapper;
