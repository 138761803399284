import React from 'react';
import { ReactComponent as Done } from 'assets/icons/ball-check-icon.svg';
import { useGetPage } from 'hooks/useGetPage';
import { pageIds } from 'utilities/constants';
import Loader from 'components/loaderComponent';

const UploadPhotoSuccess = () => {
  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.PHOTO_SUBMISSION,
  });

  if (!locale || loading) return <Loader />;

  return (
    <div className="w-full h-[100vh] p-6 flex flex-col items-center justify-center gap-4">
      <Done />
      <p className="text-neutral-800 text-center text-h4 font-semibold">
        {locale?.addedToAppointment ||
          'Your photo and symptom details have been added to your appointment'}
      </p>
      <p className="text-med-gray-3 text-center text-base font-medium">
        {locale?.pleaseReturn ||
          'Please return to your desktop view of your schedule appointment form to complete your appointment scheduling'}
      </p>
    </div>
  );
};

export default UploadPhotoSuccess;
