import React, { useEffect, useMemo, useState } from 'react';
import { ReactComponent as Done } from 'assets/icons/ball-check-icon.svg';
import Loader from 'components/loaderComponent';
import {
  convertToFullInfoFormat,
  getTimeDifferenceInHours,
} from '../../../../dashboard/components/upcomingAppointments/functions';
import ButtonComponent from 'components/button/buttonComponent';
import CancelAppointmentModal from '../../modalContent/CancelAppointmentModal';
import {
  createSearchParams,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { useGetComponent } from 'hooks/useGetComponent';
import { componentIds, pageIds } from 'utilities/constants';
import {
  AppointmentDetails,
  useGetAppointments,
} from '../../../pages/payments/hook';
import { DASHBOARD } from 'utilities/routes';
import {
  LAST_START_TIME,
  MIN_RESCHEDULE_TIME,
} from '../../../const/appointment';
import { CANCEL_APPOINTMENT_ID } from '../../upcoming-appointments/helper/const';
import { useGetPage } from 'hooks/useGetPage';

const RescheduleSuccess = () => {
  const navigate = useNavigate();
  const [url] = useSearchParams();
  const { appointmentId } = useParams();
  const lastStartTime = url.get(LAST_START_TIME);

  const [appointment, setAppointment] = useState<AppointmentDetails>();

  const { data: locale, loading: localeLoading } = useGetComponent({
    locale: 'en',
    componentId: componentIds.APPOINTMENT_PREWORK,
  });

  const { data: localePage, loading: localeLoadingPage } = useGetPage({
    pageId: pageIds.MY_APPOINTMENTS,
    locale: 'en',
  });

  const getData = useGetAppointments(String(appointmentId));

  const handleGoHome = () => navigate(DASHBOARD);

  const handleCancel = () =>
    navigate({
      search: createSearchParams({
        [CANCEL_APPOINTMENT_ID]: String(appointmentId),
      }).toString(),
    });

  const isReschedulePosiblelity = useMemo(
    () =>
      getTimeDifferenceInHours(
        String(appointment?.start || new Date().toISOString()),
        new Date().toISOString(),
      ) >= MIN_RESCHEDULE_TIME,
    [appointment],
  );

  const type = useMemo(() => {
    const typeObj = appointment?.appointmentType?.toLowerCase();
    if (!typeObj || !localePage) {
      return '';
    }
    return localePage[typeObj]?.deets;
  }, [appointment?.appointmentType, localePage]);

  useEffect(() => {
    getData()
      .then((data) => setAppointment(data))
      .catch((error) => console.error(error));
  }, [getData]);

  if (
    !locale ||
    localeLoading ||
    !localePage ||
    localeLoadingPage ||
    !appointment
  ) {
    return <Loader />;
  }

  return (
    <div className="flex items-center justify-center flex-col w-full py-20 px-16 gap-8 bg-white rounded-lg">
      <Done />
      <h4 className="text-h1 font-medium text-center max-w-xl text-charcoal-gray">
        {locale?.payments?.appointmentRescheduleSuccessfully}
      </h4>

      <div className="p-8 rounded-lg flex items-center justify-center flex-col max-w-xl bg-neutral-100">
        <p className="text-base font-medium text-neutral-800">{`${type} with ${appointment?.providerFirstName} ${appointment?.providerLastName} on`}</p>
        <p className="font-semibold text-med-gray-3 line-through text-h3 mt-2 text-center">
          {convertToFullInfoFormat(
            String(lastStartTime || new Date().toISOString()),
          )}
        </p>
        <p className="font-semibold text-charcoal-gray text-h3 mt-2 mb-6 text-center">
          {convertToFullInfoFormat(String(appointment?.start))}
        </p>

        <ButtonComponent onClick={handleGoHome}>
          {locale?.payments?.toHomePage}
        </ButtonComponent>

        {isReschedulePosiblelity ? (
          <>
            <div className="flex items-center justify-around w-full mt-8">
              <ButtonComponent type="underline" onClick={handleCancel}>
                {locale?.payments?.cancel}
              </ButtonComponent>
            </div>
            <p className="text-sm text-med-gray-3 font-medium text-center mt-4">
              {locale?.payments?.rescheduleDescriptionSuccess}
            </p>
          </>
        ) : (
          <p className="text-sm text-med-gray-3 font-medium text-center mt-8">
            {locale?.payments?.rescheduleDescriptionDecline}
          </p>
        )}
      </div>

      <CancelAppointmentModal callback={handleGoHome} />
    </div>
  );
};

export default RescheduleSuccess;
