import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import InputComponent from '../../../components/inputComponent';
import Modal from '../../../components/modal/modalComponent';
import { useGetPage } from '../../../hooks/useGetPage';
import { useGetGenericActions } from '../../../hooks/useGetGenericActions';
import { useGetFhirPatientMediaQuery } from '../../../graphql/generated/remote-schema-hasura';
import MediaLibraryCarousel from './MediaLibraryCarousel';

import { genericActionsIds, pageIds } from '../../../utilities/constants';
import { parseMediaToSharedMedia } from '../../../utilities/functions';
import { SharedMedia } from '../../my-patients/interfaces/interfaces';

interface IModalPhotoLibrary {
  isOpen: boolean;
  onClose: () => void;
  bodySymptom: string;
  bodyDescriptions?: string;
  onConfirm: (url: string, id: string, description?: string) => void;
}

const PHOTOS_PER_SLIDE = 12;

const groupMediaForSlider = (mediaArray: SharedMedia[], groupSize: number) => {
  return mediaArray.reduce(
    (result: SharedMedia[][], item: SharedMedia, index: number) => {
      const groupIndex = Math.floor(index / groupSize);
      if (!result[groupIndex]) {
        result[groupIndex] = [];
      }
      result[groupIndex].push(item);
      return result;
    },
    [],
  );
};

const ModalPhotoLibrary: FC<IModalPhotoLibrary> = ({
  isOpen,
  onClose,
  bodySymptom,
  onConfirm,
  bodyDescriptions,
}) => {
  const { data: locale, loading: localeLoading } = useGetPage({
    pageId: pageIds.APPOINTMENT_PREWORK,
    locale: 'en',
  });

  const { data: genericActions, loading: genericActionsLoading } =
    useGetGenericActions({
      locale: 'en',
      genericActionId: [genericActionsIds.CANCEL, genericActionsIds.CONFIRM],
    });

  const [mediaData, setMediaData] = useState<SharedMedia[]>([]);
  const [selectedMedia, setSelectedMedia] = useState<string>('');
  const [description, setDescription] = useState<string>(
    () => bodyDescriptions || '',
  );

  const onUploadPhoto = useCallback(() => {
    const selected = mediaData.find((item) => item.id === selectedMedia);

    if (!selected) {
      return;
    }

    onConfirm(selected.img || '', selectedMedia, description);
  }, [description, mediaData, onConfirm, selectedMedia]);

  const buttons = useMemo(
    () => [
      {
        label: genericActions?.[genericActionsIds.CONFIRM].confirm,
        disabled: !selectedMedia,
        onClick: onUploadPhoto,
        type: 'filled',
      },
      {
        label: genericActions?.[genericActionsIds.CANCEL].cancel,
        disabled: false,
        onClick: onClose,
        type: 'underline',
      },
    ],
    [genericActions, onClose, onUploadPhoto, selectedMedia],
  );

  const groupedSlides = groupMediaForSlider(mediaData, PHOTOS_PER_SLIDE);

  const { loading: mediaLoading } = useGetFhirPatientMediaQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const media = parseMediaToSharedMedia(
        data.getFHIRMediaByRequestPatientCodexID.media,
      );

      setMediaData(media);
    },
  });

  const handleOnTextAreaChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(event.target.value);
  };

  useEffect(() => {
    if (!isOpen) {
      setSelectedMedia('');
    }
  }, [isOpen]);

  if (!locale || localeLoading || genericActionsLoading || mediaLoading) {
    return null;
  }

  return (
    <Modal
      isOpen={isOpen}
      title={locale?.selectPhotoFromLibraryTitle}
      onClose={onClose}
      buttons={buttons}
      modalDesktopWidth="desktop:max-w-[1140px]"
      titleCustomClass="normal-case desktop:text-2xl"
    >
      <div className="flex flex-col justify-center">
        <h2 className="text-h1 text-neutral-800 font-medium">{bodySymptom}</h2>
        <div className="text-base font-medium text-med-gray-3">
          <p>{locale?.chooseSymptomPhotoTitle}</p>
        </div>

        <MediaLibraryCarousel
          media={groupedSlides}
          selectedMedia={selectedMedia}
          setSelectedMedia={setSelectedMedia}
        />

        <label className="text-neutral-800 font-semibold text-base">
          {locale?.describeIssueYourBody}
        </label>
        <InputComponent
          type="text-area"
          maxLengthValue={200}
          value={description}
          placeholder={locale?.enterIssue}
          customInputClassname="placeholder:text-med-gray-3 placeholder:font-medium"
          textAreaProps={{
            height: 'h-32',
            containerHeight: 'h-full',
            name: 'photoDescription',
            onTextAreaChange: handleOnTextAreaChange,
          }}
        />
      </div>
    </Modal>
  );
};

export default ModalPhotoLibrary;
