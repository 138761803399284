import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { ReactComponent as CloseIcon } from 'assets/icons/iconClose.svg';
import { ReactComponent as FlashIcon } from 'assets/icons/flash.svg';
import { ReactComponent as TakePhotoIcon } from 'assets/icons/takePhoto.svg';
import { ReactComponent as RotateIcon } from 'assets/icons/rotate.svg';
import { ReactComponent as RotateDeviceIcon } from 'assets/icons/rotate-device.svg';
import StorageService from 'utilities/storageService';
import {
  genericActionsIds,
  LOCAL_STORAGE_USER_FROM_QR,
  pageIds,
} from 'utilities/constants';
import { useGetPage } from 'hooks/useGetPage';
import { useGetGenericActions } from 'hooks/useGetGenericActions';
import { useInitializeCamera } from 'hooks/useInitializeCamera';
import { ModalButtonProps } from 'components/modal/modal.interfaces';
import Modal from 'components/modal/modalComponent';
import Alert from 'components/alertComponent';
import Loader from 'components/loaderComponent';
import {
  APPOINTMENT_SUBMIT_PHOTO,
  FACE_PHOTO,
} from '../../../../utilities/routes';

const MobileUploadPhoto = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const bodySite = queryParams.get('bodySite');

  const token = location.state?.token || '';
  const qrStorage = new StorageService(
    LOCAL_STORAGE_USER_FROM_QR,
    localStorage,
    'true',
  );
  const navigateBack = () => navigate(-1);

  const userFromQrCode = qrStorage.getData() === 'true';

  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.CAMERA,
  });
  const { data: genericAction, loading: genericActionLoading } =
    useGetGenericActions({
      locale: 'en',
      genericActionId: [genericActionsIds.TRYAGAIN],
    });

  const {
    videoRef,
    canvasRef,
    cameraFacingMode,
    switchCameraFacingMode,
    isTorchSupported,
    supportsFacingMode,
    screenOrientation,
    toggleFlash,
    initializeCamera,
    handleCaptureImage,
    isLoading: isLoadingCamera,
    error,
  } = useInitializeCamera({
    submitPhotoUrl: `${FACE_PHOTO}${APPOINTMENT_SUBMIT_PHOTO}${location.search}`,
    token,
  });

  if (loading || !locale || genericActionLoading || !genericAction) return null;

  const ErrorButtons: ModalButtonProps[] = [
    {
      label: genericAction?.[genericActionsIds.TRYAGAIN].tryAgain,
      onClick: () => initializeCamera(),
    },
  ];

  return (
    <>
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
      </Helmet>
      {screenOrientation === 'portrait' ? (
        <>
          <div
            className={`${error === 'fatal' ? 'flex flex-col' : 'hidden'} z-50`}
          >
            <Modal
              isOpen={true}
              title={locale?.cameraError}
              buttons={ErrorButtons}
              disableMobileClose={true}
            >
              <Alert
                type="warning"
                text={locale?.errorWhenInitializingCamera}
              />
            </Modal>
          </div>

          <div
            className={`${
              isLoadingCamera ? 'flex flex-col' : 'hidden'
            } fixed top-0 left-0 w-full h-full items-center justify-center bg-base-content gap-10 z-100`}
          >
            <Loader />

            <p className="text-h3 font-semibold text-dark-gray">
              {locale?.loading}
            </p>
          </div>

          <div
            className={`${isLoadingCamera || error ? 'hidden' : 'flex-col'}`}
          >
            <div className="fixed top-0 left-0 w-screen h-screen bg-camera-blur">
              <video
                className="w-full h-full object-cover"
                ref={videoRef}
                autoPlay={true}
              />
              <canvas className="w-full h-full opacity-0" ref={canvasRef} />
            </div>

            <div className="absolute w-full bg-camera-blur top-0 left-0 h-[6rem] py-2">
              <div className="flex flex-col w-full items-center p-0 absolute left-0 top-2.5 px-[38px] z-50">
                <div className="flex flex-row w-full self-start justify-between">
                  <div className="flex flex-col gap-3">
                    <p className="text-base font-bold self-start text-white mt-2">
                      {'Take a photo of the problem area'}
                    </p>
                    <p className="text-h3 font-semibold text-white pb-2.5">
                      {bodySite}
                    </p>
                  </div>
                  {!userFromQrCode && (
                    <CloseIcon
                      className="w-8 h-full stroke-white pt-2.5"
                      onClick={() => navigateBack()}
                    />
                  )}
                </div>
              </div>
            </div>

            <div className="absolute flex flex-row justify-between items-center w-full bg-camera-blur bottom-0 left-0 h-[113px] px-7 py-[21px] z-50">
              <div className="w-9">
                {cameraFacingMode === 'environment' && isTorchSupported() && (
                  <FlashIcon onClick={toggleFlash} />
                )}
              </div>
              <TakePhotoIcon onClick={handleCaptureImage} />
              <div className="w-9">
                {supportsFacingMode() && (
                  <RotateIcon onClick={switchCameraFacingMode} />
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="flex flex-col bg-base-content justify-center items-center h-screen w-full gap-5 z-50">
          <RotateDeviceIcon className="w-16 h-16 fill-dark-gray" />
          <p className="text-h3 font-semibold text-dark-gray">
            {locale?.rotateDevice}
          </p>
        </div>
      )}
    </>
  );
};

export default MobileUploadPhoto;
