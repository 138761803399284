import React, { useCallback, useEffect, useState } from 'react';
import Collapse from '../components/Collapse';
import {
  useGetAvailableAppointmentTypesLazyQuery,
  useGetProviderAvailabilityMonthDatesLazyQuery,
  useGetProviderAvailabilityServiceTypesLazyQuery,
  useGetProviderAvailabilityTimeSlotsLazyQuery,
} from 'graphql/generated/hasura';
import CustomMonthDatepicker from 'components/react-datapicker/CustomMonthDatepicker';
import StorageService from 'utilities/storageService';
import { APPOINTMENT_STORAGE } from '../routePath';
import { selectedDateFormat } from 'utilities/functions';
import CarouselSelectDays from '../components/CarouselSelectDays';
import TimeSlotsContainer from '../components/TimeSlotsContainer';
import AppointmentTypeSelectWrapper from '../components/AppointmentTypeSelectWrapper';
import { IAppointmentDetailsData, IServiceTypes } from '../interface';
import { useGetComponent } from 'hooks/useGetComponent';
import { componentIds } from 'utilities/constants';

interface IAppointmentDetails {
  handleNext: (form: IAppointmentDetailsData) => void;
}
const appointmentStorage = new StorageService(
  APPOINTMENT_STORAGE,
  sessionStorage,
);

const collapsesIdsArray = ['type', 'day', 'slot'];
const AppointmentDetails: React.FC<IAppointmentDetails> = ({ handleNext }) => {
  const form = appointmentStorage.getData();

  const { data: locale } = useGetComponent({
    componentId: componentIds.APPOINTMENT_PREWORK,
    locale: 'en',
  });

  const [currentCollapsesOpen, setCurrentCollapsesOpen] = useState(
    collapsesIdsArray[0],
  );

  const [days, setDays] = useState<Date[]>([]);
  const [types, setTypes] = useState<IServiceTypes[]>([]);
  const [slots, setSlots] = useState<string[]>([]);

  const [monthDate, setMonths] = useState<Date | null>(new Date());

  const [selectedType, setSelectedType] = useState<IServiceTypes | null>(
    form?.selectedAppointment?.type || null,
  );
  const [selectedDay, setSelectedDay] = useState<Date | null>(
    form?.selectedAppointment?.date || null,
  );
  const [selectedTime, setSelectedTime] = useState<string>(
    form?.selectedAppointment?.timeSlot || '',
  );

  const [getTypes] = useGetAvailableAppointmentTypesLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const serviceTypes =
        data?.GetAvailableAppointmentTypes?.appointmentTypes || [];
      const providerTypes: IServiceTypes[] = serviceTypes.map(
        (serviceTypes) =>
          ({
            id: serviceTypes.id,
            price: serviceTypes.price,
            duration: serviceTypes.duration,
            active: serviceTypes.active,
            name: serviceTypes.name,
            description: serviceTypes?.description,
            paddingAfter: serviceTypes.paddingAfter,
          } as IServiceTypes),
      );
      setTypes(providerTypes);
    },
  });

  const [getProviderTypes] = useGetProviderAvailabilityServiceTypesLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const serviceTypes =
        data?.GetProviderAvailabilityServiceTypes?.serviceTypes || [];
      const providerTypes: IServiceTypes[] = serviceTypes.map(
        (serviceTypes) =>
          ({
            id: serviceTypes.id,
            price: serviceTypes.price,
            duration: serviceTypes.duration,
            active: serviceTypes.active,
            name: serviceTypes.name,
            description: serviceTypes?.description,
            paddingAfter: serviceTypes.paddingAfter,
          } as IServiceTypes),
      );
      setTypes(providerTypes);
    },
  });

  const [getAcuityProviderMonthDates] =
    useGetProviderAvailabilityMonthDatesLazyQuery({
      fetchPolicy: 'network-only',
    });

  const [getAcuityProviderTimeSlots] =
    useGetProviderAvailabilityTimeSlotsLazyQuery({
      fetchPolicy: 'network-only',
      onCompleted(data) {
        if (!data?.GetProviderAvailabilityTimeSlots?.timeSlots) {
          return setSlots([]);
        }
        const times = data.GetProviderAvailabilityTimeSlots.timeSlots.map(
          ({ time }) => time,
        );
        return setSlots(times);
      },
    });

  const getAppointmentTypes = useCallback(() => {
    if (form?.selectedProvider?.calendarId) {
      return getProviderTypes({
        variables: {
          providerCalendarId: form?.selectedProvider?.calendarId,
        },
      });
    }
    return getTypes();
  }, [form?.selectedProvider?.calendarId, getProviderTypes, getTypes]);

  const handleContinue = (id: string) => {
    const index = collapsesIdsArray.indexOf(id);
    if (index < 0) {
      return;
    }
    if (index === collapsesIdsArray.length - 1 && selectedType && selectedDay) {
      return handleNext({
        type: selectedType,
        date: selectedDay,
        timeSlot: selectedTime,
      });
    }
    setCurrentCollapsesOpen(collapsesIdsArray[index + 1]);
  };

  const handleEdit = (id: string) => {
    const index = collapsesIdsArray.indexOf(id);
    if (index < 0) {
      return;
    }
    setCurrentCollapsesOpen(collapsesIdsArray[index]);
  };

  const handleSelectType = async (type: IServiceTypes) => {
    const date = new Date();
    const month = date.toLocaleDateString('en-US', {
      month: 'short',
      year: 'numeric',
    });
    setSelectedTime('');
    setSelectedDay(null);
    setDays([]);
    setSelectedType(type);
    setSelectedDay(null);
    const { data } = await getAcuityProviderMonthDates({
      variables: {
        appointmentTypeId: String(type?.id),
        providerCalendarId: form?.selectedProvider?.calendarId,
        month,
      },
    });
    const dates = data?.GetProviderAvailabilityMonthDates.dates || [];
    setDays(dates.map((day) => new Date(day)));
  };

  const onMonthChange = async (date: Date | null) => {
    if (!date) {
      return null;
    }
    const month = date.toLocaleDateString('en-US', {
      month: 'short',
      year: 'numeric',
    });

    setSelectedTime('');
    setSelectedDay(null);
    const { data } = await getAcuityProviderMonthDates({
      variables: {
        appointmentTypeId: String(selectedType?.id),
        providerCalendarId: form?.selectedProvider?.calendarId,
        month,
      },
    });
    const dates = data?.GetProviderAvailabilityMonthDates.dates || [];
    setMonths(date);
    setDays(dates.map((day) => new Date(day)));
    return date;
  };

  const onSelectDay = (day: Date) => {
    setSelectedTime('');
    setSelectedDay(day);

    const date = day.toLocaleDateString('en-US', {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
    });

    getAcuityProviderTimeSlots({
      variables: {
        appointmentTypeId: String(selectedType?.id),
        date,
        providerCalendarId: form?.selectedProvider?.calendarId,
      },
    });
  };

  const onSelectedTime = (time: string) => {
    setSelectedTime(time);
  };

  useEffect(() => {
    getAppointmentTypes();
  }, [getAppointmentTypes]);

  return (
    <div className="px-[60px] py-[30px] bg-white rounded-md">
      <div className="flex flex-row w-full desktop:w-auto justify-between items-center">
        <h4 className="text-left text-h4 text-charcoal-gray font-semibold">
          {locale?.appointmentDetailsPage}
        </h4>
        <div className="text-sm font-semibold text-alert-negative">
          *Required
        </div>
      </div>

      <p className="text-base font-medium text-med-gray-3 my-4">
        {locale?.appointmentDetailsDescriptions}
      </p>

      <hr />

      <div className="flex flex-col gap-4 mt-6">
        <Collapse
          handleContinue={handleContinue}
          handleEdit={handleEdit}
          currentCollapsesOpen={currentCollapsesOpen}
          id={collapsesIdsArray[0]}
          disabled={!selectedType}
          savedData={
            selectedType &&
            `${selectedType.name} (${selectedType.duration} ${locale?.appointmentDetails?.minutes}) ($${selectedType.price})`
          }
          title={locale?.appointmentDetails?.appointmentType}
        >
          <AppointmentTypeSelectWrapper
            onSelect={handleSelectType}
            selectedType={selectedType}
            types={types}
          />
        </Collapse>
        <Collapse
          handleContinue={handleContinue}
          handleEdit={handleEdit}
          currentCollapsesOpen={currentCollapsesOpen}
          id={collapsesIdsArray[1]}
          title={locale?.appointmentDetails?.appointmentDate}
          disabled={!selectedDay}
          savedData={selectedDay && selectedDateFormat(selectedDay)}
        >
          <CustomMonthDatepicker
            testID="month-datepicker"
            onChange={onMonthChange}
            startDate={monthDate}
            className="w-full desktop:w-64 mb-2"
          />
          {!!days.length && (
            <CarouselSelectDays
              days={days}
              onSelect={onSelectDay}
              onMonthChange={onMonthChange}
              selectedDay={selectedDay}
            />
          )}
        </Collapse>
        <Collapse
          handleContinue={handleContinue}
          handleEdit={handleEdit}
          currentCollapsesOpen={currentCollapsesOpen}
          id={collapsesIdsArray[2]}
          title={locale?.appointmentDetails?.appointmentTime}
          disabled={!selectedTime}
          savedData={null}
        >
          <TimeSlotsContainer
            times={slots}
            date={selectedDay}
            onSelect={onSelectedTime}
            selectedTime={selectedTime}
          />
        </Collapse>
      </div>
    </div>
  );
};

export default AppointmentDetails;
